<template>
  <div>
    <div class="text-grey q-mt-sm">Select language for translation:</div>
    <div class="row items-center q-mt-sm">
      <q-select
        class="translation-select"
        v-model="sourceLanguage"
        label="Source"
        filled
        dense
        :options="appLanguages"
      />
      <q-icon name="arrow_right_alt" class="q-mx-md" />
      <q-select
        class="translation-select"
        v-model="targetLanguage"
        label="Target"
        filled
        dense
        :disable="!sourceLanguage?.value"
        :options="targetLanguages"
      />

      <q-btn class="q-ml-auto" flat icon="g_translate" label="Auto translate" @click="autoTranslate" />

      <q-btn class="q-ml-sm" flat icon="save" color="positive" label="Save" @click="saveAll" />
    </div>

    <q-markup-table class="q-mt-md" dense bordered>
      <thead>
      <tr>
        <th class="text-left">Alias</th>
        <th v-text="sourceLanguage?.label || '-'" />
        <th></th>
        <th v-text="targetLanguage?.label || '-'" />
      </tr>
      </thead>
      <tbody>
      <tr v-for="localization in localizations" :key="localization.id">
        <td class="text-no-wrap">
          <q-btn
            class="q-mr-sm"
            color="deep-orange"
            size="xs"
            dense
            icon="delete"
            @click="deleteLocalization(localization)"
          />

          {{ localization.alias }}
        </td>
        <td>
          <template v-if="!sourceLanguage?.value">-</template>
          <div v-else class="row no-wrap items-center">
            <localization-editor-field
              v-model="localizationsModel[localization.id][sourceLanguage.value].message"
              :current-value="currentModel[localization.id][sourceLanguage.value].message"
              :type="localization.type"
            />
            <q-btn
              class="q-ml-sm"
              :color="localizationsModel[localization.id][sourceLanguage.value].message === currentModel[localization.id][sourceLanguage.value].message ? 'white' : 'positive'"
              :text-color="localizationsModel[localization.id][sourceLanguage.value].message === currentModel[localization.id][sourceLanguage.value].message ? 'grey' : null"
              dense
              icon="save"
              :disable="localizationsModel[localization.id][sourceLanguage.value].message === currentModel[localization.id][sourceLanguage.value].message"
              @click="saveMessage(localizationsModel[localization.id][sourceLanguage.value])"
            />
          </div>
        </td>
        <td style="width: 0%;"><q-icon name="arrow_right_alt" /></td>
        <td>
          <template v-if="!targetLanguage?.value">-</template>
          <div v-else class="row no-wrap items-center">
            <localization-editor-field
              v-model="localizationsModel[localization.id][targetLanguage.value].message"
              :current-value="currentModel[localization.id][targetLanguage.value].message"
              :type="localization.type"
            />
            <q-btn
              class="q-ml-sm"
              :color="localizationsModel[localization.id][targetLanguage.value].message === currentModel[localization.id][targetLanguage.value].message ? 'white' : 'positive'"
              :text-color="localizationsModel[localization.id][targetLanguage.value].message === currentModel[localization.id][targetLanguage.value].message ? 'grey' : null"
              dense
              icon="save"
              :disable="localizationsModel[localization.id][targetLanguage.value].message === currentModel[localization.id][targetLanguage.value].message"
              @click="saveMessage(localizationsModel[localization.id][targetLanguage.value])"
            />
            <q-btn
              class="q-ml-sm"
              color="white"
              text-color="primary"
              dense
              icon="g_translate"
              @click="phraseTranslate(localization.id)"
            />
          </div>
        </td>
      </tr>
      </tbody>
    </q-markup-table>
  </div>
</template>

<script>
import {Localization} from '@/../../common/db/Localization';
import {LocalizationMessage} from '@/../../common/db/LocalizationMessage';
import {AppIntegration} from '@/../../common/db/AppIntegration';
import LocalizationEditorField from '@/components/Localizations/LocalizationEditorField.vue';

export default {
  name: 'TranslationsTable',
  components: {LocalizationEditorField},

  inject: ['module_id'],

  data() {
    return {
      properties: {},
      sourceLanguage: null,
      targetLanguage: null,
      additionalLanguages: [],
      localizationsModel: {},
      currentModel: {},
    };
  },

  computed: {
    /**
     * Returns a list of languages that are available in the current application.
     *
     * This method performs the following steps:
     * - It retrieves the list of languages from the global options.
     * - It filters the list of languages to only include those that are either the main locale or included in the `additionalLocales` of the current application.
     *
     * @returns {Array} An array of languages that are available in the current application.
     */
    appLanguages() {
      return this.globals.options.languages.filter(
        (v) => v.value === this.properties?.mainLocale
          || (this.properties?.additionalLocales || []).includes(v.value)
      );
    },

    /**
     * Returns a list of languages that are available in the current application excluding the source language.
     *
     * This method performs the following steps:
     * - It retrieves the list of languages from the `appLanguages` computed property.
     * - It filters the list of languages to only include those that are not the source language.
     *
     * @returns {Array} An array of languages that are available in the current application excluding the source language.
     */
    targetLanguages() {
      return this.appLanguages.filter((v) => v.value !== this.sourceLanguage?.value);
    },

    /**
     * Returns a list of localizations for the current module.
     *
     * This method performs the following steps:
     * - It queries the `Localization` model for localizations where the `module_id` matches the current `module_id`.
     *
     * @returns {Promise<Array>} A promise that resolves to an array of localizations for the current module.
     */
    localizations() {
      return this.wait("localizations", Localization.query().where({module_id: this.module_id}).get(), []);
    },
  },

  methods: {
    /**
     * Saves a localization message to the remote server and updates the local model.
     *
     * This method performs the following steps:
     * - It sends a save request to the remote server with the provided message as the payload.
     * - It waits for the server to respond with the saved message.
     * - It updates the local model with the saved message. The message is stored under the localization id and locale of the saved message.
     *
     * @param {Object} message - The localization message to save. This object should contain the message text, the locale, and the localization id.
     * @returns {Promise<void>} A promise that resolves when the message has been saved and the local model has been updated.
     */
    async saveMessage(message) {
      const result = await LocalizationMessage.remote().save(message);

      this.localizationsModel[result.localization_id][result.locale] = result;
      this.currentModel[result.localization_id][result.locale] = JSON.parse(JSON.stringify(result));
    },

    /**
     * Asynchronously saves all localization messages that have been modified.
     *
     * This method iterates over all localizations and their respective messages.
     * If a message has been modified (i.e., the current message in the localizations model
     * does not match the original message in the current model), it saves the updated message
     * by calling the `saveMessage` method.
     *
     * @returns {Promise<void>} A promise that resolves when all modified messages have been saved.
     */
    async saveAll() {
      try {
        // Iterate over all localizations
        for (const localization of Object.values(this.localizationsModel)) {
          // Iterate over all messages of the current localization
          for (const message of Object.values(localization)) {
            // If the current message matches the original message, skip to the next message
            if (this.localizationsModel[message.localization_id][message.locale]?.message === this.currentModel[message.localization_id][message.locale]?.message) {
              continue;
            }

            // Save the modified message
            await this.saveMessage(message);
          }
        }

        this.$q.notify('Messages saved', 'positive');
      } catch (e) {
        console.error('Error saving messages', e);

        this.$q.notify('Error saving messages', 'error');
      }
    },

    /**
     * Deletes a localization and its associated messages.
     *
     * This method opens a confirmation dialog asking the user if they are sure they want to delete the localization.
     * If the user confirms, it deletes all messages associated with the localization and then deletes the localization itself.
     *
     * @param {Object} localization - The localization to delete. This object should contain the id of the localization and its alias.
     * @returns {Promise<void>} A promise that resolves when the localization and its messages have been deleted.
     */
    async deleteLocalization(localization) {
      // Open a confirmation dialog
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${localization.alias}?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        try {
          // Get all messages associated with the localization
          const messages = Object.values(this.localizationsModel[localization.id] || {});

          // If there are any messages, delete them
          if (messages.length) {
            for (const message of messages) {
              if (!message.id) {
                continue;
              }

              // Delete the message
              await LocalizationMessage.remote().delete(message.id);
            }
          }

          // Delete the localization
          await Localization.remote().delete(localization.id);
        } catch (e) {
          console.error('Error deleting localization', e);
        }
      })
    },

    /**
     * Initializes the localizations model.
     *
     * This method performs the following steps:
     * - It subscribes to the "module-localizations" and "module-localization-messages" remote services, passing the current module id.
     * - It retrieves the localizations and messages from the remote services.
     * - It initializes the localizations model as an object where each key is a localization id and each value is an object where each key is a language value and each value is an object representing a localization message.
     * - It sets the messages in the localizations model.
     *
     * @returns {Promise<void>} A promise that resolves when the localizations model has been initialized.
     */
    async initModels() {
      // Subscribe to the localizations and localization messages
      const localizations = await Localization.remote()
        .subscribe("module-localizations", {module_id: this.module_id});

      const messages = await LocalizationMessage.remote()
        .subscribe("module-localization-messages", {module_id: this.module_id});

      // Set the localizations model
      for (const localization of localizations) {
        this.localizationsModel[localization.id] = {};
        this.currentModel[localization.id] = {};

        for (const language of this.appLanguages) {
          this.localizationsModel[localization.id][language.value] = {
            message: '',
            locale: language.value,
            localization_id: localization.id,
          };

          this.currentModel[localization.id][language.value] = {
            message: '',
            locale: language.value,
            localization_id: localization.id,
          };
        }
      }

      // Set the messages in the localizations model
      for (const message of messages) {
        this.localizationsModel[message.localization_id][message.locale] = message;
        this.currentModel[message.localization_id][message.locale] = JSON.parse(JSON.stringify(message));
      }
    },

    /**
     * Initiates the process of automatically translating all localizations.
     *
     * This method performs the following steps:
     * - It opens a confirmation dialog asking the user if they want to auto translate all localizations.
     * - If the user confirms, it shows a loading indicator and initiates the translation process.
     * - The translation process involves making a remote call to the "translateAll" method of the "localizations" service, passing the current module id.
     * - After the translation process is complete, it re-initializes the localizations model to reflect the new translations.
     * - It then notifies the user that the localizations have been auto translated.
     * - If an error occurs at any point during this process, it notifies the user of the error.
     * - Finally, it hides the loading indicator.
     */
    autoTranslate() {
      this.$q.dialog({
        title: 'Auto translate',
        message: 'Are you sure you want to auto translate all localizations?',
        cancel: true,
        persistent: true,
      }).onOk(async () => {
        try {
          this.$q.loading.show();

          // Translate all localizations
          await this.app.client.getAxios().post(`localizations/translateAll`, [this.module_id]);

          // Re-initialize the localizations model
          await this.initModels();

          this.$q.notify('Localizations auto translated', 'positive');
        } catch (e) {
          this.$q.notify(e.message, 'error');
        } finally {
          this.$q.loading.hide();
        }
      });
    },

    /**
     * Initiates the process of translating a specific phrase to a target language.
     *
     * This method performs the following steps:
     * - It opens a confirmation dialog asking the user if they want to translate the phrase to the target language.
     * - If the user confirms, it shows a loading indicator and initiates the translation process.
     * - The translation process involves making a remote call to the "translatePhraseToLang" method of the "localizations" service, passing the current module id, the locale id, the source language, and the target language.
     * - After the translation process is complete, it re-initializes the localizations model to reflect the new translation.
     * - It then notifies the user that the phrase has been translated.
     * - If an error occurs at any point during this process, it notifies the user of the error.
     * - Finally, it hides the loading indicator.
     *
     * @param {string} localeId - The id of the locale that the phrase belongs to.
     */
    phraseTranslate(localeId) {
      this.$q.dialog({
        title: 'Translate phrase',
        message: `Are you sure you want to translate phrase to ${this.targetLanguage.label}?`,
        cancel: true,
        persistent: true,
      }).onOk(async () => {
        try {
          this.$q.loading.show();

          // Translate the phrase
          await LocalizationMessage.remote().call(
            "localizations",
            "translatePhraseToLang",
            this.module_id,
            localeId,
            this.sourceLanguage.value,
            this.targetLanguage.value,
          );

          // Re-initialize the localizations model
          await this.initModels();

          this.$q.notify('Phrase translated', 'positive');
        } catch (e) {
          this.$q.notify(e.message, 'error');
        } finally {
          this.$q.loading.hide();
        }
      });
    },
  },

  watch: {
    /**
     * Watches for changes in the source language and sets the target language to null if it's the same as the source language.
     *
     * This method performs the following steps:
     * - It checks if the value of the source language is the same as the value of the target language.
     * - If they are the same, it sets the target language to null.
     */
    sourceLanguage() {
      if (this.sourceLanguage.value === this.targetLanguage?.value) {
        this.targetLanguage = null;
      }
    },
  },

  async created() {
    // Subscribe to the localizations integration
    await AppIntegration.remote().subscribe('app-integration-by-name', {
      module_id: this.module_id,
      name: 'localizations',
    });

    // Query the localizations integration
    this.propertiesObj = await AppIntegration.query().where({module_id: this.module_id, name: 'localizations'}).first();

    // Set the properties from the integration
    this.properties = this.propertiesObj?.props || {};

    // Initialize the localizations model
    await this.initModels();
  },

  async beforeUnmount() {
    // Unsubscribe from the localizations and localization messages
    await Localization.remote().unsubscribe("module-localizations");
    await LocalizationMessage.remote().unsubscribe("module-localization-messages");
    await AppIntegration.remote().unsubscribe("app-integration-by-name");
  },
}
</script>
