<template>

  <div>
    <ui-prop-select
      name="provider"
      title="Auth provider"
      :block="block"
      :options="providers"
      default-value="default"
    />

    <template v-if="provider === 'default'">
      <ui-prop-value-selector :block="block" title="User email" name="email" :parent-diagram-id="diagram_id"/>
      <ui-prop-value-selector :block="block" title="User password" name="password" :parent-diagram-id="diagram_id"/>
    </template>

    <template v-else-if="provider === 'promo-code'">
      <ui-prop-value-selector :block="block" title="User email" name="email" :parent-diagram-id="diagram_id"/>

      <ui-prop-value-selector
        :block="block"
        title="Variable to save promo code"
        name="variableToSavePromoCode"
        value-type="setter"
        :parent-diagram-id="diagram_id"
      />
    </template>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropSelect, UiPropValueSelector},
  props: ['block'],
  name: "SignUpPropsCmp",

  data() {
    return {
      providers: [
        {value: 'default', label: 'Default'},
        {value: 'facebook', label: 'Facebook'},
        {value: 'google', label: 'Google'},
        {value: 'apple', label: 'Apple'},
        {value: 'promo-code', label: 'Promo code'},
      ],
    };
  },

  computed: {
    provider() {
      return this.block?.properties?.provider || 'default';
    },
  },
}

</script>
