<template>

  <ab-flow-base-cmp :draggable="false" class="content-swiper-stack-cmp" :block="block" :is_container="true" :class="classesString" :style="stylesString">

    <TransitionGroup name="card">
      <div v-for="(item, idx) in items" :key="idx" class="content-swiper-stack-cmp__card">
        <data-provider :storage-key="block?.title" :data="{item:item}">
          <ab-flow-components-renderer :items="block.children"/>
        </data-provider>
      </div>
    </TransitionGroup>

    <div class="results">
      <ab-flow-link-connector :block="block" type="output" event="selected"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import DataProvider from "@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue";
import {StorageNode} from "@/../../common/db/StorageNode.js"
import {DbTableField} from "@/../../common/db/DbTableField.js"
import {DbTable} from "../../../../../../../../../common/db/DbTable";

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowLinkConnector, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  props: ['block'],
  name: "ContentSwiperStackEditorCmp",

  computed: {
    items() {
      return this.wait("items", async () => {

        // Init items
        let items = [];

        // Load repeater storage node
        const node = await StorageNode.find(this.block?.properties?.dataSource?.nodeId);

        // Check for db record
        if(node?.type === "db-record") {
          const item = {};

          // Load table
          const table = await DbTable.find(node.db_table)

          // check if table has data
          if(table?.table_data?.length) {
            return (table.table_data || []).slice(0, 3);
          } else if (node?.value && node?.is_test_value) {
            return (this.getValue(this.block?.properties?.dataSource) || node?.value || [{}]).slice(0, 3);
          }

          // Load table fields
          for(const fld of await DbTableField.query().where("table_id", node.db_table).get()) {
            item[fld.name] = fld.description || `{db:${fld.name}}`;
          }

          // Add to items
          items.push(item);

        } else {

          // get items from node
          items = this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
        }

        // Convert item elements to object
        items = items.map(el => {
          return typeof el === 'object' ? el : {value: el}
        })

        // Return list
        return items.length ? items.slice(0, 3) : [{}];
      }, [])
    }
  },
}

</script>


<style lang="scss">

.content-swiper-stack-cmp {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;

  &__card {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 60px);
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &:first-child {
      transform: scale(1) translateY(0px);
      opacity: 1;
      z-index: 2;
    }

    &:nth-child(2) {
      transform: scale(0.95) translateY(-30px);
      opacity: 0.7;
      z-index: 1;
    }

    &:nth-child(3) {
      transform: scale(0.9) translateY(-60px);
      opacity: 0.4;
      z-index: 0;
    }

    &:not(:first-child) {
      transform-origin: top center;
    }
  }

  .results {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
}

</style>
