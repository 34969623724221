import {AbOrm} from "ab-orm/src/AbOrm.js";

/**
 * UI template model
 */
export class UiTemplate extends AbOrm {
    static entity = 'ui_templates';

    static primaryKey = ['id'];

    static fields = {
        id: "int",
        fragment_id: "string",
        section: "string", // partition
        name: "string",
        source: "json", // schema
    };


    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        return this.app().auth().hasRole("user") || this.app().auth().hasRole("system") ? row : false
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'ui-templates': {
                subscribe: () => 1,
                init: async () => UiTemplate.getList(),
            },
        }
    }

    /**
     * Get list
     */
    static async getList() {
        return this.query().get()
    }
}
