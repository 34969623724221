<template>
  <ab-flow-base-cmp class="chat-wrapper relative-position" :block="block" :class="classesString" :style="stylesString">
    <q-card flat class="chat-card column no-wrap">
      <q-card-section style="flex-basis: 100%;" class="q-pa-none">
        <q-scroll-area ref="scrollArea" class="full-height" scroll-position="end">
          <div class="q-px-sm">
            <q-chat-message
                class="chat-message"
                :name="userName"
                :text="['hey, how are you?']"
                stamp="7 minutes ago"
                :size="messageSize"
                sent
                :style="outgoingStyles"
            />
            <q-chat-message
                class="chat-message"
                :name="botName"
                :text="[
                'doing fine, how r you?',
                'I just feel like typing a really, really, REALLY long message to annoy you...'
              ]"
                :size="messageSize"
                stamp="4 minutes ago"
                :style="incomingStyles"
            />
            <q-chat-message
                class="chat-message"
                :name="botName"
                :text="['Did it work?']"
                stamp="1 minutes ago"
                :size="messageSize"
                :style="incomingStyles"
            />
          </div>
        </q-scroll-area>
      </q-card-section>

      <q-card-section v-if="!readonly" class="q-pa-none">
        <q-input
            class="chat-field"
            filled
            autogrow
            dense
            disable
            model-value="Type your message here..."
            :style="fieldStyles"
        >
          <template #append>
            <q-btn round dense flat :class="btnClass" icon="send" />
          </template>
        </q-input>
      </q-card-section>
    </q-card>

    <div class="flex connector-center">
      <ab-flow-link-connector :block="block" type="input" event="clear-messages"/>
      <ab-flow-link-connector :block="block" type="input" event="callback"/>
    </div>

    <div class="flex connector-center results">
      <div>
        <div>
          Chat events:
          <ab-flow-link-connector :block="block" type="output" event="message-sent"/>
          <ab-flow-link-connector :block="block" type="output" event="content-click"/>
        </div>
        <div v-if="outUIEvents?.length">
          Bot UI events:
          <ab-flow-link-connector v-for="(e, k) of outUIEvents" class="q-mt-sm" :block="block" type="output" :event="e.name" :key="k"/>
        </div>
      </div>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  inject: ['canvas'],
  props: ['block'],
  name: "ChatEditorCmp",
  computed: {

    /**
     * Outgoing events
     * @return {*|*[]}
     */
    outUIEvents() {
      return this.block.properties?.['ui-events'] || []
    },


    /**
     * message
     * @return {string}
     */
    message() {
      return this.interpretString(this.block.properties?.message) || '';
    },

    /**
     * Options list
     * @return {{name: string, event: string}[]}
     */
    options() {
      return this.block?.properties?.options?.items || [];
    },

    /**
     * Events list
     * @return {string[]}
     */
    outEvents() {
      return this.options.map((option) => option.event).filter((v) => !!v);
    },

    // Returns the styles for the outgoing messages
    outgoingStyles() {
      return {
        '--message-text-color': `var(--foreground-color-${this.block?.properties?.outgoingTextColor || 'light'})`,
        '--message-bg-color': `var(--background-color-${this.block?.properties?.outgoingBgColor || 'info'})`,
      };
    },

    // Returns the styles for the incoming messages
    incomingStyles() {
      return {
        '--message-text-color': `var(--foreground-color-${this.block?.properties?.incomingTextColor || 'light'})`,
        '--message-bg-color': `var(--background-color-${this.block?.properties?.incomingBgColor || 'success'})`,
      };
    },

    // Returns the styles for the field
    fieldStyles() {
      return {
        '--chat-control-text-color': `var(--foreground-color-${this.block?.properties?.fieldTextColor || 'dark'})`,
        '--chat-control-bg-color': `var(--background-color-${this.block?.properties?.fieldBgColor || 'tertiary'})`,
      };
    },

    // Returns the class for the button
    btnClass() {
      return `dg-foreground-${this.block?.properties?.btnTextColor || 'dark'}`;
    },

    // Returns the bot name
    botName() {
      return this.interpretString(this.block?.properties?.botName) || 'bot';
    },

    // Returns the user name
    userName() {
      return this.interpretString(this.block?.properties?.userName) || 'me';
    },

    // Read-only flag
    readonly() {
      return this.getValue(this.block?.properties?.readonly) || false;
    },

    // Message size
    messageSize() {
      return String(this.block?.properties?.messageSize || 9);
    },
  },

  mounted() {
    this.$refs?.scrollArea?.setScrollPercentage('vertical', 100);
  },
}

</script>

<style lang="scss">
.chat-wrapper {
  .connector-center {
    transform: translate(-50%, -50%);
    column-gap: .4rem;
  }

  .results {
    transform: translate(-50%, 30px);
  }
}
.chat-card {
  width: 100%;
  background: transparent !important;
}
.chat-message {
  .q-message-text {
    background: var(--message-bg-color);
    color: var(--message-bg-color);

    .q-message-text-content {
      color: var(--message-text-color);
    }
  }

  .q-message-container {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &.reverse > div {
      align-items: flex-end;
    }
  }
}
.chat-field {
  .q-field__control {
    align-items: flex-end;
    background: var(--chat-control-bg-color) !important;

    &:after {
      display: none !important;
    }

    .q-field__native {
      max-height: 180px;
    }

    .q-field__native, .q-field__label {
      color: var(--chat-control-text-color) !important;
    }
  }
}
</style>
