<template>
  <div class="q-gutter-y-md">

    <div v-for="(feature, key) of featuresList" :key="key" class="row">
      <q-input v-model="featuresList[key]" class="col">
        <template v-slot:prepend>
          <div class="row">
            {{key + 1}}
            <q-separator vertical spaced/>
          </div>
        </template>

        <template v-slot:append>
          <q-btn icon="delete" flat @click="deleteFeature(key)"/>
        </template>
      </q-input>
    </div>

    <div class="row">
      <q-btn color="primary" icon="add" flat @click="addFeature">Add feature</q-btn>
      <q-space/>
      <q-btn color="primary" @click="next">Next</q-btn>
    </div>
  </div>

</template>

<script>

export default {
  name: "WizardProjectFeatures",
  props: ["features"],
  emits: ["next"],
  data: () => ({
    featuresList: ""
  }),
  created() {

    // Initial features list
    this.featuresList = this.features

    // Add watcher to the features list
    this.$watch("featuresList", () => {
      this.$emit("update:features", this.featuresList)
    })
  },
  methods: {

    /**
     * Add new feature to the list
     */
    addFeature() {

      // Ask user for feature name and add it to the list
      this.$q.dialog({
        title: "Add feature",
        message: "Enter feature name",
        prompt: {
          model: "",
          type: "text"
        },
        cancel: true,
        persistent: true
      }).onOk((data) => {
        this.featuresList.push(data)
      })

    },

    /**
     * Delete feature from the list
     * @param index
     */
    deleteFeature(index) {

      // Ask delete confirmation
      this.$q.dialog({
        title: "Delete feature",
        message: "Are you sure you want to delete this feature?",
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.featuresList.splice(index, 1)
      })
    },

    /**
     * Go to next step
     */
    next() {
      this.$emit("next", this.featuresList)
    }
  }
}

</script>
