<template>

  <ui-prop-string name="label" title="Label" :block="block"/>
  <ui-prop-select title="Label position" name="labelPosition" :block="block" :options="globals.options.labelPosition" default-value="right"/>
  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
  <ui-prop-value-selector :block="block" title="Checked value" name="checkedValue" :parent-diagram-id="diagram_id"/>
  <ui-prop-select :block="block" title="Type" name="type" :options="globals.options.checkbox_types" default-value="regular"/>
  <ui-prop-select :block="block" title="Size" name="size" :options="globals.options.sizes"/>

  <template v-if="block?.properties?.type === 'regular'">
    <ui-prop-icon-selector :block="block" title="Checked icon" name="checkedIcon"/>
    <ui-prop-icon-selector :block="block" title="Unchecked icon" name="uncheckedIcon"/>
  </template>
  <template v-else>
    <ui-prop-media-picker :block="block" title="Checked image" name="checkedImage"/>
    <ui-prop-media-picker :block="block" title="Unchecked image" name="uncheckedImage"/>
  </template>


  <ui-dynamic-props :block="block"/>
</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropIconSelector from "@/components/DiagramDesigner/Editor/properties/UiPropIconSelector.vue";
import UiPropMediaPicker from "@/components/DiagramDesigner/Editor/properties/UiPropMediaPicker.vue";

export default {
  mixins: [propertiesMixins],
  name: "CheckBoxPropsCmp",
  props: ['block'],
  components: {UiPropMediaPicker, UiPropIconSelector, UiPropValueSelector, UiDynamicProps, UiPropSelect, UiPropString},
}

</script>

