<template>

  <div>
    <ui-prop-select :block="block" name="alarmType" title="Notification type" :options="alarmTypes" default-value="alarm"/>

    <ui-prop-dynamic-string :block="block" title="Title" name="title"/>
    <ui-prop-dynamic-string :block="block" title="Sub title" name="subTitle"/>
    <ui-prop-dynamic-string :block="block" title="Event to be run on alarm" name="startEvent" default-value="start" untranslatable />

    <template v-if="block?.properties?.alarmType === 'alarm'">
      <ui-prop-select :block="block" name="foregroundColor" title="Foreground color" :options="moduleColors"/>

      <div class="row q-gutter-x-sm">
        <ui-prop-select class="col-5" :block="block" name="backgroundMode" title="Background mode" :options="backgroundModes" default-value="color"/>
        <ui-prop-select v-if="block.properties?.backgroundMode === 'color'" class="col" :block="block" name="backgroundColor" title="Background color" :options="moduleColors"/>
        <ui-prop-media-picker v-else name="backgroundImage" class="col" media-type="image" :block="block" />
      </div>

      <q-card class="q-my-sm">
        <q-card-section class="bg-primary text-white">Open</q-card-section>
        <q-card-section>
          <div class="row q-gutter-x-sm">
            <ui-prop-select class="col-4" :block="block" name="openImageMode" title="Open image mode" :options="imageModes" default-value="image"/>
            <ui-prop-media-picker v-if="block.properties?.openImageMode === 'image'" class="col" name="openImage" media-type="image" :block="block" />
            <ui-prop-media-picker v-else name="openLottie" class="col" media-type="lottie" :block="block" />
          </div>

          <ui-prop-dynamic-string :block="block" title="Text" name="openText"/>
        </q-card-section>
      </q-card>

      <q-card class="q-my-sm">
        <q-card-section class="bg-primary text-white">Dismiss</q-card-section>
        <q-card-section>
          <div class="row q-gutter-x-sm">
            <ui-prop-select class="col-4" :block="block" name="dismissImageMode" title="Dismiss image mode" :options="imageModes" default-value="image"/>
            <ui-prop-media-picker v-if="block.properties?.dismissImageMode === 'image'" class="col" name="dismissImage" media-type="image" :block="block" />
            <ui-prop-media-picker v-else name="dismissLottie" class="col" media-type="lottie" :block="block" />
          </div>

          <ui-prop-dynamic-string :block="block" title="Text" name="dismissText"/>
        </q-card-section>
      </q-card>
    </template>

    <ui-prop-checkbox name="vibrate" title="Vibrate on alarm" :block="block" />

    <!--ui-prop-media-picker name="alarmSound" media-type="sound" title="Alarm sound" :block="block" /!-->
    <ui-prop-value-selector :block="block" name="alarmSound" title="Alarm sound" only-var-type="sound" :parent-diagram-id="diagram_id"/>

    <ui-prop-string :block="block" title="Number of repetitions of sound" name="repetitions" default-value="0"/>

    <ui-data-props
      class="q-mt-md"
      name="alarms"
      :title="block?.properties?.alarmType === 'push' ? 'Notifications' : 'Alarms'"
      :default-schema="defaultSchema"
      :block="block"
    />

    <template v-if="block?.properties?.alarmType === 'alarm'">
      <ui-prop-dynamic-string :block="block" title="Postpone title" name="postponeTitle"/>
      <ui-prop-color :block="block" title="Postpone button background color" name="postponeBgColor"/>
    </template>

    <q-card class="q-mt-md">
      <q-card-section class="bg-primary text-white">Volume ramp settings</q-card-section>

      <q-card-section>
        <ui-prop-string :block="block" title="Increasing time" name="increasingTime" default-value="0"/>

        <template v-if="block?.properties?.increasingTime && block?.properties?.increasingTime > 0">
          <ui-prop-string :block="block" title="Min volume" name="minVolume" default-value="10"/>
          <ui-prop-string :block="block" title="Max volume" name="maxVolume" default-value="100"/>
        </template>
      </q-card-section>
    </q-card>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropMediaPicker from "@/components/DiagramDesigner/Editor/properties/UiPropMediaPicker.vue";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropColor from "@/components/DiagramDesigner/Editor/properties/UiPropColor.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";

export default {
  mixins: [propertiesMixins],
  components: {
    UiPropValueSelector,
    UiPropColor,
    UiPropString, UiPropCheckbox, UiDataProps, UiPropMediaPicker, UiPropSelect, UiPropDynamicString},
  props: ['block'],
  name: "OpenEmailPropsCmp",
  data: () => (
      {
        backgroundModes: [{value: "color", label: "Color"}, {value: "image", label: "Image"}],
        imageModes: [{value: "image", label: "Image"}, {value: "animation", label: "Lottie animation"}],
        defaultSchema: [
          {
            name: "hour",
            type: "string"
          },
          {
            name: "minute",
            type: "string"
          },
          {
            name: "dayOfWeek",
            type: "string"
          },
        ],
        alarmTypes: [
          {value: 'alarm', label: 'Alarm'},
          {value: 'push', label: 'Push notification'},
        ],
      }
  ),
  computed: {
  }
}

</script>
