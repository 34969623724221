<template>

  <q-card class="full-width">
    <q-card-section>
      <div class="text-h6">Edit db field</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="dbFieldForm" @submit="onSaveDbField">

        <div class="row">
        <q-input
            class="col"
            v-model="mutate(editField).name"
            label="Field name *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <q-checkbox v-model="mutate(editField).key_field" :true-value="1" :false-value="0" label="Key field"/>
        </div>

        <q-select
            v-model="mutate(editField).type"
            label="Field type *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
            map-options
            emit-value
            :options="globals.options.db_data_types"
        />

        <db-permission-settings v-model="mutate(editField).permissions" :permission-list="['read', 'write']"/>

        <q-separator spaced/>

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {DbTableField} from "@/../../common/db/DbTableField";
import {nanoid} from "nanoid";
import DbPermissionSettings from "@/pages/workspace/dbs/DbPermissionSettings.vue";

export default {
  name: "TableFieldForm",
  components: {DbPermissionSettings},
  props: ['editField'],
  emits: ['saved'],
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveDbField() {

      if(!this.editField.id) {
        this.mutate(this.editField).unique_id = nanoid(10);
      }

      // Update
      await DbTableField.remote().save(this.editField)

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
