<template>
  <q-list dense bordered padding class="rounded-borders">
    <q-item-label header>Diagram invocation list</q-item-label>

    <template v-if="diagramsList.length">
      <q-item v-for="diagram in diagramsList" :key="diagram.id" v-ripple :to="{params: {id: getIds(diagram.id)}}">
        <q-item-section>
          {{ diagram.title }}
        </q-item-section>
      </q-item>
    </template>
    <q-item v-else>
      <q-item-label caption>
        No diagrams found
      </q-item-label>
    </q-item>
  </q-list>
</template>

<script>
import {Diagram} from '../../../../../../../../common/db/Diagram';

export default {
  name: 'DiagramInvocationList',

  props: ['appId', 'diagramId'],

  data() {
    return {};
  },

  computed: {
    /**
     * Computed property that returns a list of active diagrams associated with the current app, excluding the current diagram.
     * It uses the 'wait' function to handle asynchronous operations.
     *
     * It first queries the 'Diagram' model where the 'app_id' matches the current 'appId' and the 'status' is 'active'.
     *
     * It then filters the returned diagrams to exclude the current diagram (where the 'id' matches 'diagramId').
     *
     * Furthermore, it filters the diagrams to only include those where at least one child of the 'source' object is of type 'DiagramComponent'
     * and the 'diagramComponentId' of the 'properties' object matches the current 'diagramId'.
     *
     * @returns {Array} An array of diagrams that match the specified conditions.
     */
    diagramsList() {
      return this.wait("diagramsList", async () => {
        return (await Diagram.query().where("app_id", this.appId).where("status", "active").get())
          .filter(diagram => diagram.id !== this.diagramId && (diagram?.source?.children || []).some(
            (child) => child?.type === 'DiagramComponent' && child?.properties?.diagramComponentId === this.diagramId
          ));
      }, []);
    },
  },

  methods: {
    /**
     * This method is used to generate a string of IDs for routing purposes.
     * It takes the current route's 'id' parameter, splits it by comma into an array, and adds the provided 'id' to the array.
     * Finally, it joins the array back into a string with commas separating the IDs.
     *
     * @param {string} id - The ID to be added to the current route's 'id' parameter.
     * @returns {string} A string of IDs separated by commas.
     */
    getIds(id) {
      return [...this.$route.params.id?.split(','), ...[id]].join(",")
    },
  }
}
</script>

<style scoped lang="scss">

</style>
