<template>

  <ui-prop-value-selector :block="block" name="dataSource" title="Data source" :is-array="true" :only-var-type="['object']" :parent-diagram-id="diagram_id"/>
  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  name: "TreeViewPropsCmp",
  props: ['block'],
  components: {UiPropValueSelector, UiDynamicProps},
}

</script>

