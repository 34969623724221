<template>
  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>

  <ui-prop-select :block="block" title="Selected date color" name="selectedDateColor" :options="moduleColors" default-value="light"/>
  <ui-prop-select :block="block" title="Selected date background" name="selectedDateBgColor" :options="moduleColors" default-value="info"/>
  <ui-prop-select :block="block" title="Unselected date color" name="unselectedDateColor" :options="moduleColors" default-value="dark"/>

  <ui-dynamic-props :block="block"/>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  name: "DateWeeklyPickerPropsCmp",
  mixins: [propertiesMixins],
  props: ['block'],
  components: {UiDynamicProps, UiPropValueSelector, UiPropSelect}
}

</script>

