<template>
  <q-page class="q-pa-md">
    <q-dialog ref="editDbTable">
      <db-table-form :edit-dbTable="editDbTable" :app-id="app_id" @saved="$refs.editDbTable.hide()"/>
    </q-dialog>

    <q-breadcrumbs>
      <q-breadcrumbs-el label="DB" :to="{name: 'dbs', params: {app_id: $route.params.app_id, module_id: $route.params.module_id}}" />
      <q-breadcrumbs-el label="Tables" />
    </q-breadcrumbs>

    <q-separator class="q-my-md"/>

    <div class="row q-gutter-md">

      <template v-for="(dbTable, k) of dbTables" :key="k">

        <q-card class="col-md-3 col-xs-102">

          <q-card-section class="bg-blue-1">
            <div class="row">
              <div class="text-subtitle1 no-wrap ellipsis row">{{ dbTable.name }}</div>
              <q-space/>
              <q-btn flat icon="menu" size="sm" round @click.stop.prevent>
                <q-menu>
                  <q-list>
                    <q-item clickable v-close-popup @click="editDbTable = dbTable.data();$refs.editDbTable.show()">
                      <q-item-section>
                        <q-item-label>{{ $t('actions.edit') }}</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="$router.push({name: 'db-table-content', params: {table_id: dbTable.id}})">
                      <q-item-section>
                        <q-item-label>Content</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="$router.push({name: 'db-table-content', params: {table_id: dbTable.id, test: 'test'}})">
                      <q-item-section>
                        <q-item-label>Test data</q-item-label>
                      </q-item-section>
                    </q-item>
                    <q-item clickable v-close-popup @click="copyDbTable(dbTable)">
                      <q-item-label>Copy</q-item-label>
                    </q-item>
                    <q-item clickable v-close-popup @click="deleteDbTable(dbTable)" class="text-red">
                      <q-item-section>
                        <q-item-label>{{ $t('actions.delete') }}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
          </q-card-section>

          <q-card-section v-if="dbTable.description">
            <div>{{ dbTable.description }}</div>
          </q-card-section>

          <q-card-section>
            <table-fields-list :table-id="dbTable.id"/>
          </q-card-section>

          <q-card-section>
            <table-channels-list :table-id="dbTable.id"/>
          </q-card-section>
        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab fab icon="add" color="primary" direction="left">
        <q-fab-action
          v-if="dbTableIdToPaste"
          icon="content_paste"
          label="Paste table"
          color="secondary"
          @click="pasteDbTable"
        />

        <q-fab-action
          icon="add"
          label="Add new"
          color="accent"
          @click="editDbTable={db_id, remote_module_id: 0, is_static_data: 1};$refs.editDbTable.show()"
        />
      </q-fab>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>
import DbTableForm from "./DbTableForm";
import {DbTable} from "@/../../common/db/DbTable";
import TableFieldsList from "@/pages/workspace/dbs/TableFieldsList.vue";
import TableChannelsList from "@/pages/workspace/dbs/TableChannelsList.vue";
import TabCommunicationMixin from '@/mixins/TabCommunicationMixin';

export default {
  name: 'DbTablesList',
  components: {TableChannelsList, TableFieldsList, DbTableForm },

  mixins: [TabCommunicationMixin],

  data: () => ({
    editDbTable: false,
    db_id: false
  }),

  async created() {
    // Get db id from route
    this.app_id = this.$route.params.app_id
    this.db_id = this.$route.params.db_id

    // Subscribe to dbTables
    await DbTable.remote().subscribe("db-tables",{db_id: this.db_id});
  },

  computed: {
    // Return all dbTables from the store
    dbTables() {
      return this.wait("app-dbTables", DbTable.query().where({db_id: this.db_id}).get(), [])
    },

    /**
     * Return DB table id from the local storage
     * @returns {string}
     */
    dbTableIdToPaste() {
      return this.app.client.config.get('copy_db_table', false)
    },
  },

  methods: {
    /**
     * Copy DB table
     * @param dbTable
     */
    copyDbTable(dbTable) {
      // store DB table id in the local storage
      this.app.client.config.set('copy_db_table', dbTable.id)

      // show toast
      this.$q.notify({
        message: 'Table copied',
        color: 'positive',
        icon: 'check',
        position: 'top'
      });

      this.sendMessageToTab('copy-table', dbTable.id);
    },

    /**
     * Paste DB table
     */
    pasteDbTable() {
      this.$q.dialog({
        title: 'Paste confirmation',
        message: `Are you sure want to paste table?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        // Duplicate DB table
        await DbTable.remote().call("app", "duplicateDBTable", this.dbTableIdToPaste, this.db_id);

        // show toast
        this.$q.notify({
          message: 'Table pasted',
          color: 'positive',
          icon: 'check',
          position: 'top'
        });

        // remove DB table id from the local storage
        this.app.client.config.set('copy_db_table', false);

        this.sendMessageToTab('paste-table');
      });
    },

    /**
     * Delete dbTable
     * @param dbTable
     * @return {Promise<void>}
     */
    async deleteDbTable(dbTable) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${dbTable.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        DbTable.remote().delete(dbTable.id)
      })
    },

    /**
     * Handle messages received from other tabs.
     * @param {Event} e - The event object containing the message data.
     */
    handleTabMessage(e) {
      const {type, content} = e.data;

      switch (type) {
        case 'copy-table':
          this.app.client.config.set('copy_db_table', content);
          break;
        case 'paste-table':
          this.app.client.config.set('copy_db_table', false);
          break;
      }
    },
  },
}
</script>
