<template>
  <div class="q-gutter-y-sm">

    <ui-prop-value-selector :block="block" title="Options list" name="options" only-var-type="options" :is-array="true" :parent-diagram-id="diagram_id"/>

    <ui-prop-select name="manualOptionsState" title="Manual or extra options" :block="block" :options="manualSelectOptions" default-value="none"/>
    <ui-data-props v-if="this.block?.properties?.manualOptionsState !== 'none'" title="Manual or extra options" name="manualOptions" :default-schema="defaultSchema" :block="block"/>

    <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
    <ui-prop-select name="optionsColor" title="Options color" :block="block" :options="moduleColors"/>
    <ui-prop-select name="selectionColor" title="Selection color" :block="block" :options="moduleColors"/>
    <ui-prop-select name="stripesColor" title="Border lines color" :block="block" :options="moduleColors"/>

    <ui-dynamic-props :default-value="{}" :block="block" />
  </div>
</template>

<script>

import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiDataProps from '@/components/DiagramDesigner/Editor/properties/UiDataProps.vue';

export default {
  mixins: [propertiesMixins],
  name: "RouletteSelectorPropsCmp",
  props: ['block'],
  components: {UiDataProps, UiPropSelect, UiPropValueSelector, UiDynamicProps},
  data() {
    return {
      tab: "data",

      defaultSchema: [
        {
          name: "value",
          type: "string"
        },
        {
          name: "label",
          type: "string"
        },
      ],

      manualSelectOptions: [
        {label: "None", value: "none"},
        {label: "Before the main options", value: "before"},
        {label: "After the main options", value: "after"},
      ],
    };
  },
}

</script>

