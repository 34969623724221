import {CacheManager} from './CacheManager.js';
import {UserApp} from '../db/UserApp.js';
import {DbModule} from '../db/DbModule.js';
import {DbTable} from '../db/DbTable.js';
import {AppModule} from '../db/AppModule.js';
import {Localization} from '../db/Localization.js';

/**
 * Class representing an Access Manager.
 * This class is responsible for managing access to various resources in the application.
 */
export class AccessManager {
  /**
   * Create an Access Manager.
   * @param {Object} context - The context object.
   */
  constructor(context) {
    this.context = context;
    this.cache = new CacheManager();
    this.ttl = parseInt(process.env.ACCESS_MANAGER_CACHE_TTL) || 5 * 60;
    this.userId = null;

    if (typeof context?.app()?.auth()?.getUserId === 'function') {
      this.userId = context?.app()?.auth()?.getUserId() || null;
    }
  }

  /**
   * Check basic access.
   * @return {boolean} True if the user has basic access, false otherwise.
   */
  checkBasicAccess() {
    return this.context?.app()?.auth()?.hasRole("system")
      || this.context?.app()?.auth()?.hasRole("admin")
      || this.context?.isClient();
  }

  /**
   * Check application access.
   * @param {number} appId - The ID of the application.
   * @return {Promise<boolean>} True if the user has access to the application, false otherwise.
   */
  async checkAppAccess(appId) {
    if (this.checkBasicAccess()) {
      return true;
    }

    if (!this.userId) {
      return false;
    }

    const key = `app_access_${this.userId}_${appId}`;

    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    const userApp = await UserApp.query().where({
      app_id: appId,
      user_id: this.userId,
    }).firstRaw();

    const access = Boolean(userApp?.app_id);

    this.cache.set(key, access, this.ttl);

    return access;
  }

  /**
   * Check database table access.
   * @param {number} dbId - The ID of the database.
   * @param {number} tableId - The ID of the table.
   * @return {Promise<boolean>} True if the user has access to the database table, false otherwise.
   */
  async checkDbTableAccess(dbId, tableId) {
    if (this.checkBasicAccess()) {
      return true;
    }

    if (!this.userId) {
      return false;
    }

    const key = `db_tbl_access_${this.userId}_${tableId}`;

    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    const db = await DbModule.query(undefined, true).where({ id: dbId }).firstRaw();

    if (!Boolean(db?.id)) {
      this.cache.set(key, false, this.ttl);

      return false;
    }

    return await this.checkAppAccess(db.app_id);
  }

  /**
   * Check database table child access.
   * @param {string} key - The cache key.
   * @param {number} tableId - The ID of the table.
   * @return {Promise<boolean>} True if the user has access to the database table child, false otherwise.
   */
  async _checkDbTableChildAccess(key, tableId) {
    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    const table = await DbTable.query(undefined, true).where({ id: tableId }).firstRaw();

    if (!Boolean(table?.id)) {
      this.cache.set(key, false, this.ttl);

      return false;
    }

    return await this.checkDbTableAccess(table.db_id, tableId);
  }

  /**
   * Check database table channel access.
   * @param {number} tableId - The ID of the table.
   * @param {number} channelId - The ID of the channel.
   * @return {Promise<boolean>} True if the user has access to the database table channel, false otherwise.
   */
  async checkDbTableChannelAccess(tableId, channelId) {
    if (this.checkBasicAccess()) {
      return true;
    }

    if (!this.userId) {
      return false;
    }

    const key = `db_tbl_ch_access_${this.userId}_${channelId}`;

    return this._checkDbTableChildAccess(key, tableId);
  }

  /**
   * Check database table field access.
   * @param {number} tableId - The ID of the table.
   * @param {number} fieldId - The ID of the field.
   * @return {Promise<boolean>} True if the user has access to the database table field, false otherwise.
   */
  async checkDbTableFieldAccess(tableId, fieldId) {
    if (this.checkBasicAccess()) {
      return true;
    }

    if (!this.userId) {
      return false;
    }

    const key = `db_tbl_fld_access_${this.userId}_${fieldId}`;

    return this._checkDbTableChildAccess(key, tableId);
  }

  /**
   * Check localization access.
   * @param {number} moduleId - The ID of the module.
   * @param {number} localizationId - The ID of the localization.
   * @return {Promise<boolean>} True if the user has access to the localization, false otherwise.
   */
  async checkLocalizationAccess(moduleId, localizationId) {
    if (this.checkBasicAccess()) {
      return true;
    }

    if (!this.userId) {
      return false;
    }

    const key = `lng_access_${this.userId}_${localizationId}`;

    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    const module = await AppModule.query(undefined, true).where({ id: moduleId }).firstRaw();

    if (!Boolean(module?.id)) {
      this.cache.set(key, false, this.ttl);

      return false;
    }

    return await this.checkAppAccess(module.app_id);
  }

  /**
   * Check localization message access.
   * @param {number} localizationId - The ID of the localization.
   * @param {number} messageId - The ID of the message.
   * @return {Promise<boolean>} True if the user has access to the localization message, false otherwise.
   */
  async checkLocalizationMessageAccess(localizationId, messageId) {
    if (this.checkBasicAccess()) {
      return true;
    }

    if (!this.userId) {
      return false;
    }

    const key = `lng_msg_access_${this.userId}_${messageId}`;

    if (this.cache.has(key)) {
      return this.cache.get(key);
    }

    const localization = await Localization.query(undefined, true).where({ id: localizationId }).firstRaw();

    if (!Boolean(localization?.id)) {
      this.cache.set(key, false, this.ttl);

      return false;
    }

    return await this.checkLocalizationAccess(localization.module_id, localizationId);
  }
}
