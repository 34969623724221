<template>
  <ui-prop-dynamic-string :multiple="true" :block="block" title="Text content" name="content"/>
  <ui-prop-checkbox title="Allow html" name="allowHtml" :true-value="1" :false-value="0" :block="block"/>
  <ui-dynamic-props :default-value="{}" :block="block" />
</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";

export default {
  mixins: [propertiesMixins],
  name: "TextPropsCmp",
  props: ['block'],
  components: {UiPropCheckbox, UiPropDynamicString, UiDynamicProps},
}

</script>

