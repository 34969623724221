<template>
  <div class="q-gutter-y-md">

    <q-input type="textarea" v-model="description" label="Project description" hint="Describe your project"/>

    <q-btn color="primary" @click="next">Next</q-btn>
  </div>

</template>

<script>

export default {
  name: "WizardProjectDescription",
  emits: ["next"],
  data: () => ({
    description: ""
  }),
  methods: {
    /**
     * Go to next step
     */
    next() {
      this.$emit("next", this.description)
    }
  }
}

</script>
