<template>
  <q-page class="q-pa-sm analytics-ret">
    <div class="text-h6">Analytics</div>
    <analytics-filter
      :module-id="moduleId"
      :show-revenue="true"
      v-model:required-events="requiredEvents"
      v-model:codeVersion="codeVersion"
      v-model:appPlatform="appPlatform"
      v-model:schemaVersion="schemaVersion"
      v-model:date-from="dateFrom"
      v-model:date-to="dateTo"
      v-model:countries="countries"
      v-model:bigQuery="bigQuery"
      v-model:revenue="revenue"
    />
    <div class="col ret-diagram">

      <div class="row">

        <div class="header cell">
          <div class="date">
            Date
          </div>
          <div class="count">Users</div>
        </div>

        <div v-for="(d, k) of days" :key="k" class="cell col">
          D{{k}}
        </div>
      </div>

      <div class="row average-row">
        <div class="cell header">
          <div class="date">Average</div>
          <div
            class="count"
            v-text="averageStats.users ? `${averageStats.users.toFixed(0)}${averageStats.revenue ? ` (${averageStats.revenue.toFixed(2)}$)` : ''}` : null"
          />
        </div>

        <div class="flex no-wrap justify-center cell col" v-for="(percent, ik) of averageStats.percents" :key="ik">
          <span
            v-if="percent !== null"
            class="percent"
            v-text="`${percent.toFixed(0)}%${averageStats.revenues[ik] ? ` (${averageStats.revenues[ik].toFixed(2)}$)` : ''}`"
          />
          <div v-else>-</div>
          <sup
            v-if="percent !== null"
            class="users-removed q-ml-xs"
            v-text="averageStats.removes[ik] ? `-${averageStats.removes[ik].toFixed(averageStats.removes[ik] < 1 ? 1 : 0)}%` : ''"
          />
        </div>
      </div>

      <div v-for="(item, k) of stats" :key="k" class="row">
        <div class="cell header">
          <div class="date" v-text="item.day" />
          <div class="count" v-text="item.users ? `${item.users}${item.revenue ? ` (${item.revenue.toFixed(2)}$)` : ''}` : null" />
        </div>
        <div class="flex no-wrap justify-center cell col" v-for="(percent, ik) of item.percents" :key="ik" :style="{'background-color': percent.color}">
          <span
            v-if="percent.percent !== null"
            class="percent"
            v-text="`${percent.percent}%${percent.revenue ? ` (${percent.revenue.toFixed(2)}$)` : ''}`"
          />
          <div v-else>-</div>
          <sup
            v-if="percent.percent !== null"
            class="users-removed q-ml-xs"
            v-text="percent.removes ? `-${percent.removes}%` : ''"
          />
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>

import AnalyticsFilter from "@/pages/workspace/analytics/AnalyticsFilter.vue";
import moment from "moment";
import _ from "lodash";

/**
 * Calculates the sum of all numbers in an array.
 *
 * @param {number[]} arr - The array of numbers to sum.
 * @returns {number} The sum of all numbers in the array.
 */
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0);

/**
 * Calculates the average of all numbers in an array.
 *
 * @param {number[]} arr - The array of numbers to calculate the average from.
 * @returns {number|null} The average of all numbers in the array, rounded to the nearest whole number. If the array is empty, returns null.
 */
const arrAverage = (arr) => arr.length ? (arrSum(arr) / arr.length) : null;

export default {
  name: "AnalyticsRetention",
  components: {AnalyticsFilter},
  data: () => ({
    moduleId: false,
    codeVersion: null,
    requiredEvents: [],
    countries: [],
    bigQuery: 1,
    revenue: '',
    dateFrom: null,
    dateTo: null,
    versionSource: null,
    appPlatform: null,
    schemaVersion: null,
    isReady: false,
    retStat: {},
    retRemoves: {},
    retRevenue: {},
  }),
  async created() {

    // Set module id
    this.moduleId = this.$route.params.module_id

    // Watch params
    this.$watch("codeVersion", () => this.updateStatistics())
    this.$watch("schemaVersion", () => this.updateStatistics())
    this.$watch("dateFrom", () => this.updateStatistics())
    this.$watch("dateTo", () => this.updateStatistics())
    this.$watch("requiredEvents", () => this.updateStatistics())
    this.$watch("countries", () => this.updateStatistics())
    this.$watch("bigQuery", () => this.updateStatistics())
    this.$watch("adRevenue", () => this.updateStatistics())
    this.$watch("revenue", () => this.updateStatistics())
    this.$watch("appPlatform", () => this.updateStatistics())

  },

  computed: {
    /**
     * Get days
     * @return {string[]}
     */
    days() {
      // List of days between dateFrom and dateTo
      const days = [];
      const day = moment(this.dateFrom);
      while(day.isSameOrBefore(this.dateTo)) {
        days.push(day.format("YYYY-MM-DD"));
        day.add(1, "day");
      }

      return days;
    },

    /**
     * Computes the statistics for each day.
     *
     * This method maps over the array of days and for each day, it returns an object containing:
     * - The day itself.
     * - The number of users for that day, retrieved from the `retStat` object using the key `${day}-0`.
     * - An array of percentages for each day. For each day, it calculates the percentage of users for that day
     *   compared to the total number of users. The percentage is calculated using the `calcPercent` method.
     *   If the count or total is not available, it returns null. The color for each percentage is calculated
     *   using the `getCellColor` method.
     *
     * @returns {Object[]} An array of objects, each representing the statistics for a particular day.
     */
    stats() {
      return this.days.map((day) => ({
        day,
        users: this.retStat[`${day}-0`] || null,
        revenue: this.days.reduce((total, value, idx) => total + (this.retRevenue[`${day}-${idx}`] || 0), 0),
        percents: this.days.map((d, i) => {
          const count = this.retStat[`${day}-${i}`];
          const total = this.retStat[`${day}-0`];
          const removes = this.retRemoves[`${day}-${i}`];

          return {
            color: this.getCellColor(count, total),
            percent: count && total ? this.calcPercent(count, total) : null,
            revenue: this.retRevenue[`${day}-${i}`] || null,
            removes: removes && total ? this.calcPercent(removes, total) : null,
          };
        }),
      }));
    },

    /**
     * Computes the average statistics.
     *
     * This method reduces over the `stats` array and for each item, it does the following:
     * - Pushes the number of users for that item to the `users` array in the result object.
     * - Iterates over the `percents` array for that item. For each percentage, it checks if an array already exists
     *   for that index in the `percents` object in the result. If not, it creates one. If the percentage is not null,
     *   it parses it as an integer and pushes it to the corresponding array in the `percents` object.
     *
     * After reducing the `stats` array, it returns an object containing:
     * - The average number of users, calculated using the `arrAverage` method on the `users` array in the result.
     * - An array of average percentages. For each array in the `percents` object in the result, it calculates the
     *   average using the `arrAverage` method and adds it to the array.
     *
     * @returns {Object} An object containing the average number of users and an array of average percentages.
     */
    averageStats() {
      const combinedStats = this.stats.reduce((res, item) => {
        res.users.push(item.users);
        res.revenue.push(item.revenue || 0);

        item.percents.forEach((percent, idx) => {
          if (!res.percents[idx]) {
            res.percents[idx] = [];
          }

          if (percent.percent !== null) {
            res.percents[idx].push(parseInt(percent.percent));
          }
        });

        item.percents.forEach((percent, idx) => {
          if (!res.revenues[idx]) {
            res.revenues[idx] = [];
          }

          if (percent.revenue) {
            res.revenues[idx].push(parseFloat(percent.revenue));
          }
        });

        item.percents.forEach((percent, idx) => {
          if (!res.removes[idx]) {
            res.removes[idx] = [];
          }

          if (percent.removes) {
            res.removes[idx].push(parseFloat(percent.removes));
          }
        });

        return res;
      }, { users: [], revenue: [], percents: {}, revenues: {}, removes: {} });

      return {
        users: arrAverage(combinedStats.users),
        revenue: arrAverage(combinedStats.revenue),
        percents: Object.values(combinedStats.percents)
          .map((percents) => arrAverage(percents)),
        revenues: Object.values(combinedStats.revenues)
          .map((revenues) => arrAverage(revenues)),
        removes: Object.values(combinedStats.removes)
          .map((removes) => arrAverage(removes)),
      };
    },
  },

  methods: {

    /**
     * Get cell color
     * @param count
     * @param total
     */
    getCellColor(count, total) {
      const pc = parseInt(count)
      const pt = parseInt(total)
      return pc && pt ? `rgba(0, 200, 0, ${Math.min(1, Math.max(0,pc / pt))})` : '';
    },

    /**
     * Update source
     */
    updateStatistics: _.debounce(async function() {
      try {
        if(!(this.dateFrom && this.dateTo && this.moduleId)) return
        this.$q.loading.show({message: "Updating statistics..."});
        const res = await this.app.client.call("app-statistics", "getRetention", this.appPlatform, this.dateFrom, this.dateTo, this.moduleId, this.codeVersion, this.schemaVersion, this.requiredEvents, this.countries, this.bigQuery, this.revenue);
        this.retStat = {};
        this.retRevenue = {};
        this.retRemoves = {};
        for(const r of res) {
          this.retStat[`${r.install_date}-${r.day}`] = r.users
          this.retRevenue[`${r.install_date}-${r.day}`] = r.revenue
          this.retRemoves[`${r.install_date}-${r.day}`] = r.users_removed
        }
      } finally {
        this.$q.loading.hide()
      }
    }, 300),

    /**
     * Calculates the percentage of a count from the total.
     *
     * This method takes a count and a total as arguments, both of which are parsed as integers.
     * It then calculates the percentage of the count from the total, rounds it to the nearest whole number,
     * and returns it as a string.
     *
     * @param {number} count - The count to calculate the percentage from.
     * @param {number} total - The total to calculate the percentage of the count from.
     * @returns {string} The percentage of the count from the total, rounded to the nearest whole number.
     */
    calcPercent(count, total) {
      const result = (100 * parseInt(count) / parseInt(total));

      return result.toFixed(result < 1 ? 1 : 0);
    }
  },
}

</script>

<style lang="scss">

.analytics-ret {
  .ret-diagram {
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;

    .cell {
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      padding: 3px;
      text-align: center;

      .percent {
        color: black;
        flex-basis: 100%;
      }
    }


    .header {
      width: 200px;
      display: flex;
      flex-direction: row;

      .date {
        width: 100px;
      }

      .count {
        flex-grow: 1;
        border-left: 1px solid #ddd;
      }
    }

    .average-row {
      background: #f5f5f5;
    }

    .users-removed {
      min-width: 25px;
    }
  }
}

</style>
