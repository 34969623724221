<template>

  <ui-prop-value-selector :block="block" only-var-type="icon" name="iconName" title="Icon name" :parent-diagram-id="diagram_id"/>
  <ui-prop-select name="iconSize" :block="block" title="Icon size" :options="globals.options.sizes" default-value="md"/>
  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";

export default {
  inject: {
    product_id: {
      default: 0
    }
  },
  mixins: [propertiesMixins],
  name: "IconPropsCmp",
  props: ['block'],
  components: {UiPropValueSelector, UiPropSelect, UiDynamicProps},
  methods: {
    /**
     * Show list of icons
     */
    showList() {
      window.open('https://fonts.google.com/icons', '_blank')
    }
  }
}

</script>

