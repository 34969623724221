<template>
  <q-page class="q-pa-sm analytics-page dg-media">
    <div class="text-h6">Analytics</div>
    <analytics-filter
      ref="filter"
      :module-id="moduleId"
      v-model:appPlatform="appPlatform"
      v-model:codeVersion="codeVersion"
      v-model:requiredEvents="requiredEvents"
      v-model:schemaVersion="schemaVersion"
      v-model:date-from="dateFrom"
      v-model:date-to="dateTo"
      v-model:countries="countries"
      v-model:bigQuery="bigQuery"
      v-model:appRemove="appRemove"
      :show-app-remove="true"
      @apply-filter="updateSource"
    />

    <div class="bg-primary text-white q-pa-sm path">
      <template  v-for="(l, k) of path" :key="k">
        <router-link :to="{name: 'analytics-paths', params: {id: l.ids}}">{{l.title}}</router-link> ::
      </template>
    </div>
    <div class="col" ref="diagramPreview">
      <analytics-page-preview
        v-if="currentDiagramSource?.id"
        :app-platform="appPlatform"
        :key="`${currentDiagramId}-${codeVersion}-${schemaVersion}-${dateFrom}-${dateTo}-${JSON.stringify(requiredEvents)}-${JSON.stringify(countries)}-${this.bigQuery}-${this.appRemove}`"
        :code-version="codeVersion"
        :schema-version="schemaVersion"
        :schema-source="versionSource"
        :current-diagram-source="currentDiagramSource"
        :diagram-id="currentDiagramId"
        :required-events="requiredEvents"
        :countries="countries"
        :bigQuery="bigQuery"
        :appRemove="appRemove"
        :date-from="dateFrom"
        :date-to="dateTo"
        :module-id="moduleId"
        :app-id="appId"
      />
    </div>
  </q-page>
</template>

<script>

import AnalyticsFilter from "@/pages/workspace/analytics/AnalyticsFilter.vue";
import {SchemaReleaseHistory} from "../../../../../common/db/SchemaReleaseHistory";
import AnalyticsPagePreview from "@/pages/workspace/analytics/AnalyticsPagePreview.vue";
import {AppStyle} from "../../../../../common/db/AppStyle";

export default {
  name: "AnalyticsPage",
  components: {AnalyticsPagePreview, AnalyticsFilter},
  provide() {
    return {
      designer: {}
    }
  },
  data: () => ({
    dateFrom: false,
    dateTo: false,
    requiredEvents: [],
    countries: [],
    bigQuery: 0,
    appRemove: 0,
    moduleId: false,
    appPlatform: null,
    appId: false,
    codeVersion: null,
    versionSource: false,
    schemaVersion: null,
    currentDiagramId: false,
    idsList: [],
    isReady: false,
  }),
  created() {

    // Set module id
    this.moduleId = this.$route.params.module_id
    this.appId = this.$route.params.app_id

    // Watch params
    this.$watch("$route.params.id", () => {
      const segs = this.$route.params.id?.split(",") || [];
      if(segs?.length) this.currentDiagramId = segs[segs?.length-1] ? parseInt(segs[segs?.length-1]) : 0;
    }, {immediate: true})

    // Watch params
    //this.$watch("codeVersion", () => this.updateSource())
    //this.$watch("schemaVersion", () => this.updateSource())

  },

  mounted() {
    // Checks if the `forceFilterApply` query parameter is set.
    if (this.$route.query.forceFilterApply) {
      // If the `forceFilterApply` query parameter is set, it applies the filter by calling the `applyFilter` method on the `filter` ref.
      this.$refs.filter.applyFilter();

      // After applying the filter, it removes the `forceFilterApply` query parameter from the URL.
      this.$router.replace({query: null});
    }
  },

  computed: {

    /**
     * Get path title
     */
    path() {

      // List
      const list = [];
      let ids = []

      // Load diagram titles
      for(const dgId of this.$route.params.id?.split(",") || []) {
        const dg = this.versionSource?.diagrams?.find(e => e.id === parseInt(dgId));
        if(dg) {
          ids.push(dgId)
          list.push({
            ids: ids.join(","),
            title: dg.title
          });
        }
      }

      // Items list
      return list
    },

    /**
     * Get current diagram source
     * @return {*}
     */
    currentDiagramSource() {
      console.error(this.versionSource, this.currentDiagramId, this.versionSource?.diagrams?.find(e => e.id === this.currentDiagramId)?.source)
      return this.versionSource?.diagrams?.find(e => e.id === this.currentDiagramId)?.source
    },
  },

  methods: {

    /**
     * Update source
     */
    async updateSource() {

      // Load source
      if( (this.versionSource = (await SchemaReleaseHistory.remote().subscribe("schema-release-history", {module_id: this.moduleId, code_version: this.codeVersion, schema_version: this.schemaVersion}))?.[0]?.schema_source) ) {

        // Create styles element
        const style = new AppStyle()
        style.source = this.versionSource?.styles
        const st = document.createElement("style");
        st.innerHTML = style.getStyles();
        this.$refs.diagramPreview?.appendChild(st)

        // Get start diagram
        if(!this.currentDiagramId) {
          this.currentDiagramId = this.versionSource?.startDiagramId
          this.$router.push({name: "analytics-paths", params: {id: this.currentDiagramId}})
        }
      }
    }
  },
}

</script>

<style lang="scss">

.analytics-page {
  .path {
    a {
      color: white;
    }
  }

  .diagram-preview {
    height: calc(100vh - 280px) !important;
  }

  svg {
    overflow: visible;
  }

  .editor-cmp {
    min-height: 1em;
    min-width: 1em;

    outline: 1px dotted transparent;
    border: 1px dashed transparent;

    .mover {
      background: #666;
    }
  }

  .editor-cmp.selected {
    outline: 1px dotted red;
    border: 1px dashed #eee;
  }

  .editor-cmp.hovered {
    border: 1px dashed #0a0 !important;
  }

  .canvas-links {
    overflow: visible;
  }

  .connector-left {
    position: absolute;
    top: 50%;
    left: -15px;
  }

  .connector-right {
    position: absolute;
    top: 50%;
    right: -15px;
  }

  .connector-top {
    position: absolute;
    top: -15px;
    left: 50%;
  }

  .connector-bottom {
    position: absolute;
    bottom: -15px;
    left: 50%;
  }

  .connector-center {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .hide-child-connectors {
    pointer-events: none;
    .link-connector {
      display: none;
    }
  }

}

</style>
