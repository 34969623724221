<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="db-save-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>

      <q-card-section class="bg-blue-5 text-white">
        {{title}}
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="result"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "DbSaveEditorCmp",
  computed: {

    /**
     * Return name
     * @return {{}}
     */
    title() {
      return this.block.properties?.title || "NoName"
    },

  }
}

</script>

<style lang="scss">

.db-save-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
