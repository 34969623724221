<template>

  <ab-flow-base-cmp :block="block" class="date-editor-cmp" :class="classesString" :style="stylesString">
    <q-input class="full-width" v-model="date" mask="date" :rules="['date']">
      <template v-slot:append>
        <q-icon name="event" class="date-pick">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date v-model="date" :title="title" :color="color">
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";

import { ref } from 'vue'

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "DatePickerEditorCmp",
  data: () => ({
    date: ref('2023/01/01')
  }),
  computed: {
    /**
     * Title
     * @return {string}
     */
     title() {
      return this.block?.properties?.title || "";
    },
    /**
     * Color
     * @return {string}
     */
     color() {
      return this.block?.properties?.color || "";
    },
  }
}

</script>


<style lang="scss">

.date-editor-cmp {
  position: relative;
  .links-wrapper {
    right: 0;
    left: auto;
  }

  .date-pick {
    color: var(--control-text-color);
  }

  .q-field {
    .q-field__messages {
      color: var(--control-text-color);
    }

    .q-field__control {
      color: var(--control-focus-color);
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }
    }
  }
}


</style>
