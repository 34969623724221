<template>
  <div>
    <q-editor
        ref="editor"
        v-model="currentValue.value"
        @update:model-value="updateBindings"
        :dense="$q.screen.lt.md"
        class="dg-text-regular"
        :toolbar="[
        [
            'style', 'color', 'left', 'center', 'right', 'justify', 'bold', 'italic', 'strike', 'underline', 'subscript', 'superscript',
            'hr', 'link',
            'quote', 'unordered', 'ordered', 'outdent', 'indent', 'removeFormat', 'localization'
        ],

        //['undo', 'redo'],
        //['viewsource']

        /*
                      'h1',
                'h2',
                'h3',
                'h4',
                'title',
                'sub-title',
                'regular',
                'small',

         */

      ]"
    >

      <template #localization>
        <q-checkbox
          class="q-mr-sm"
          v-model="currentValue.isLocalizable"
          size="xs"
          :false-value="false"
          true-value="html"
          color="positive"
          checked-icon="public"
          unchecked-icon="public_off"
          title="Translatable"
        />

        <localization-editor
          v-if="currentValue.isLocalizable"
          v-model="currentValue.value"
          :alias="currentValue.localeAlias"
          type="html"
        >
          <template #activator="{ show }">
            <q-btn icon="translate" square dense size="sm" flat @click="show"/>
          </template>
        </localization-editor>
      </template>

      <template v-slot:style>
        <q-btn-dropdown
            dense no-caps
            ref="styleRef"
            no-wrap
            unelevated
            label="Style"
            size="sm"
        >
          <q-list dense>
            <q-item v-for="(st) of globals.options.textStyles" tag="label" clickable @click="setStyle('dg-text-'+st.value, 'style')" :key="st.value">
              <q-item-section>{{st.label}}</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </template>

      <template v-slot:color>
        <q-btn-dropdown
            dense no-caps
            ref="colorRef"
            no-wrap
            unelevated
            label="Color"
            size="sm"
        >
          <q-list dense>
            <q-item v-for="(st) of moduleColors" tag="label" clickable @click="setStyle('dg-foreground-' + st.value, 'color')" :key="st.value">
              <q-item-section>{{st.label}}</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </template>

    </q-editor>
    <template v-for="(bindName, ) in Object.keys(currentValue?.bindings || [])" :key="bindName">
      <q-card>
        <q-card-section class="bg-primary text-white">
          {{bindName}}
        </q-card-section>
        <q-card-section>
          <value-selector v-if="bindName" :app-id="product_id" :module-id="module_id" :block-id="blockId" :parent-diagram-id="diagram_id" v-model="currentValue.bindings[bindName]"/>
        </q-card-section>
      </q-card>
    </template>
  </div>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import LocalizationEditor from '@/components/Localizations/LocalizationEditor.vue';
import {nanoid} from 'nanoid';
import ValueSelector from '@/components/ValueSelector/ValueSelector.vue';

export default {
  components: {ValueSelector, LocalizationEditor},
  mixins: [propertiesMixins],
  name: "UiPropReachText",
  mounted() {
    if (this.block.properties?.html && typeof this.block.properties?.html !== 'object') {
      this.block.properties.html = {
        isLocalizable: false,
        value: this.block.properties?.html || '',
        localeAlias: nanoid(10),
        bindings: {},
      };
    }

    this.currentValue = this.block.properties?.html || {
      isLocalizable: false,
      value: '',
      localeAlias: nanoid(10),
      bindings: {},
    };

    // Update bindings
    this.updateBindings(this.currentValue.value)
  },
  methods: {
    /**
     * Add class to selection
     * @param style
     * @param type
     */
    setStyle(style, type = 'style') {
      const edit = this.$refs.editor;
      (type === 'style' ? this.$refs.styleRef : this.$refs.colorRef).hide()
      edit.caret.restore()

      const cl = (edit.caret.parent.className.split(" ") || []).filter(c => {
        if (type === 'style') {
          return c.indexOf('dg-text-') === -1
        } else {
          return c.indexOf('dg-foreground-') === -1
        }
      })

      // Add class
      cl.push(style)

      //console.log(edit.caret.parent.textContent,edit.caret.range.toString(), edit.caret.range)

      // Set class
      //edit.caret.parent.className = cl.join(" ")
      if (edit.caret.parent.textContent !== edit.caret.range.toString()) {
        edit.runCmd('insertHTML', `<span class="${style}">${edit.caret.range.toString()}</span>`)
      } else {
        edit.caret.parent.className = cl.join(" ")
      }
    },

    /**
     * Update bindings
     */
    updateBindings(val) {

      // check if val contains substring of format {...} and extract it and add it to bindings
      let regex = /{([^}{]+)}/g
      let match = regex.exec(val)
      let bindings = {}
      while (match != null) {
        bindings[match[1]] = ""
        match = regex.exec(val)
      }

      if(!this.currentValue?.bindings) this.currentValue.bindings = {};

      // Remove bindings that are not in the string anymore
      for (let key in this.currentValue.bindings) {
        if (bindings[key] === undefined) {
          delete this.currentValue.bindings[key]
        }
      }

      // Add bindings that are not in the string yet
      for (let key in bindings) {
        if (this.currentValue?.bindings[key] === undefined) {
          this.currentValue.bindings[key] = ""
        }
      }
    },
  }
}

</script>
