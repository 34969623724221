<template>
  <div class="q-pa-md">

    <q-dialog ref="editFunc">
      <q-card class="ab-w-7">
        <q-card-section class="bg-primary text-white row">
          <div class="text-h6">Edit function</div>
          <q-space/>
          <q-btn flat icon="close" @click="$refs.editFunc.hide()">Close</q-btn>
        </q-card-section>
        <q-card-section>
          <function-form :edit-func="editFunc" @saved="$refs.editFunc.hide()"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="row q-gutter-md">
      <q-select class="col" label="Package" hint="Choose a package" emit-value map-options @update:model-value="resetFunction"
                v-model="currentPackage" :options="packages"/>
      <q-select v-if="currentPackage" class="col" label="Function" hint="Choose a function" emit-value map-options
                v-model="currentFunction" :options="packageFunctions"/>
    </div>

    <function-form v-if="editFunction" :edit-func="editFunction.builtIn ? editFunction : editFunction.data()"
                   class="q-mt-md" :key="editFunction.id" @deleted="functionDeleted"/>


    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab fab icon="add" color="primary" direction="left">
        <q-fab-action
          icon="add"
          label="Source code"
          color="primary"
          @click="addFunction('source_code')"
        />
        <q-fab-action
          v-if="currentModule?.type === 'server'"
          icon="add"
          label="Diagram"
          color="accent"
          @click="addFunction('diagram')"
        />
      </q-fab>
    </q-page-sticky>

  </div>
</template>

<style>
</style>

<script>

import {CodeFunction} from "@/../../common/db/CodeFunction.js"
import {AppModule} from "@/../../common/db/AppModule.js"
import FunctionForm from "@/pages/workspace/diagrams/code/FunctionForm.vue";
const buildInCodeFunctions = {}
export default {
  name: 'FunctionsList',
  components: {FunctionForm},
  funcs: {},
  data: () => ({
    editFunc: false,
    app_id: false,
    module_id: false,
    currentFunction: "",
    currentPackage: "",
  }),

  async created() {

    // Store app id
    this.app_id = this.$route.params.app_id
    this.module_id = this.$route.params.module_id

  },

  computed: {

    /**
     * Edit function
     * @returns {*|boolean}
     */
    editFunction() {
      return this.currentFunction ? this.functions.find(c => c.id === this.currentFunction) : false
    },

    /**
     * Modules list
     * @return {*}
     */
    modulesList() {
      return this.wait("modulesList", async () => {
        return (await AppModule.query().where("module_id", this.module_id).get()).map(m => ({value: m.id, label: m.name}))
      }, []);
    },

    /**
     * Get functions list
     * @returns {*}
     */
    functions() {

      // Return functions list
      return this.wait("functions", async () => {
        // Built-in functions list
        const builtIn = []

        // Add build in functions
        for (const pack of Object.keys(buildInCodeFunctions)) {
          // Add functions of package to the list
          for (const func of Object.keys(buildInCodeFunctions[pack])) {
            builtIn.push({
              id: pack + "." + name,
              name: func,
              package: pack,
              source: buildInCodeFunctions[pack][func].toString(),
              app_id: this.app_id,
              builtIn: true
            })
          }
        }

        console.log("Built in functions", builtIn)

        return [...builtIn, ...await CodeFunction.query().where({"module_id": this.module_id}).get()]
      }, [])
    },

    /**
     * Package functions list
     * @returns {*}
     */
    packageFunctions() {
      return this.functions.filter(f => f.package === this.currentPackage).map(c => ({
        label: c.name,
        value: c.id
      }))
    },

    /**
     * Packages list
     * @returns {*}
     */
    packages() {
      const pkg = {}
      this.functions.map(c => pkg[c.package] = {label: c.package, id: c.package})
      return Object.keys(pkg)
    },

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.module_id), {})
    },
  },

  methods: {

    /**
     * When function deleted
     */
    functionDeleted() {
      this.editFunc = false
      this.resetFunction();
      this.$refs.editFunc.hide()
    },

    /**
     * Reset function on change module
     */
    resetFunction() {
      this.currentPackage = ""
      this.currentFunction = ""
    },

    /**
     * Method to add a new function.
     * It sets the initial values for the function to be added and opens the function form dialog.
     *
     * @param {string} type - The type of the function to be added. Defaults to 'source_code'.
     */
    addFunction(type = 'source_code') {
      this.editFunc = {
        app_id: this.app_id,
        module_id: this.module_id,
        type,
        diagram_id: null,
      };

      this.$refs.editFunc.show()
    },

    /**
     * Delete func
     * @param func
     * @return {Promise<void>}
     */
    async deleteFunc(func) {
      this.$q.dialog({
        title: 'Delete function',
        message: `Are you sure want to delete ${func.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        CodeFunction.remote().delete(func.id)
      })
    },
  }
}
</script>
