<template>

  <ui-prop-string name="title" title="Component title" :block="block"/>
  <ui-prop-checkbox name="isEmbedded" title="Embedded diagram" :block="block"/>
  <ui-prop-checkbox name="isModalDialog" title="Modal dialog" :block="block"/>

  <ui-modal-props v-if="block?.properties?.isModalDialog === 1" :block="block" />

  <ui-prop-select :block="block" name="diagramComponentId" title="Diagram component" :options="componentsList"/>
  <q-btn label="New component" icon="add" color="primary" class="q-mt-md" size="sm" @click="addComponent"></q-btn>
  <ui-prop-arguments v-if="targetDiagramId" :block="block" name="arguments" title="Diagram arguments"
                     :target-block-id="block.id" :diagram-only="true"/>

</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import {Diagram} from "@/../../common/db/Diagram.js"
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import {nanoid} from "nanoid";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiModalProps from '@/components/DiagramDesigner/Editor/properties/UiModalProps.vue';

export default {
  inject: ['canvas', 'product_id', 'module_id'],
  components: {UiModalProps, UiPropCheckbox, UiPropArguments, UiPropString, UiPropSelect},
  props: ['block'],
  name: "DiagramComponentPropsCmp",
  data: () => ({
    tab: "props"
  }),
  computed: {

    /**
     * Return diagram id
     * @return {Document.diagramComponentId}
     */
    targetDiagramId() {
      return this.block.properties?.diagramComponentId;
    },

    // Return all components from the store
    componentsList() {
      return this.wait("components", async () => {
        return (await Diagram.query().where("app_id", this.canvas.product_id).where("module_id", this.module_id).where("status", "active").get()).filter(e => e.id !== this.diagram_id).map(el => ({
          label: el.title,
          value: el.id
        }))
      }, [])
    },
  },

  methods: {
    /**
     * Add new component
     */
    addComponent() {
      this.$q.dialog({
        message: "Please type the component name",
        prompt: {
          model: '',
          type: 'text',
        }
      }).onOk(async val => {
        await Diagram.remote().save({
          app_id: this.product_id,
          module_id: this.module_id,
          unique_id: nanoid(10),
          diagram_type: 'component',
          title: val,
          version: 0,
          status: 'active',
        })
      })
    }
  }
}

</script>
