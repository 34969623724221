/**
 * DB table model
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';

export class DbTableField extends AccessModel {
    static entity = 'table_fields'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        table_id: "int",
        name: "string",
        type: "string",
        key_field: "int",
        default_value: "string",
        description: "string",
        unique_id: "string",
        permissions: "json",
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkDbTableFieldAccess(await this.getAccessId(row, 'table_id'), row?.id) ? row : false
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'table-fields': {
                subscribe: ({table_id}) => table_id,
                init: async ({table_id}) => this.getList(table_id),
            },
            'app-table-fields': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => DbTableField.getVersionFields(app_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(table_id) {
        return this.query().where({table_id}).get()
    }


    /**
     * Get version list
     */
    static getVersionFields(app_id) {
        return DbTableField.query().
        join("database_tables").on("database_tables.id", "table_id").
        join("app_databases").on("app_databases.id", "db_id").
        where("app_databases.app_id", app_id).get()
    }

    /**
     * Get module fields
     */
    static getModuleFields(module_id) {
        return DbTableField.query(['table_fields.*'])
          .join('database_tables').on('database_tables.id', 'table_fields.table_id')
          .join('app_databases').on('app_databases.id', 'database_tables.db_id')
          .where('app_databases.module_id', module_id)
          .get()
    }
}
