/**
 * DB table channel
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';

export class DbTableChannel extends AccessModel {
    static entity = 'table_channels'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        table_id: "int",
        title: "string",
        description: "string",
        channel_name: "string",
        channel_query: "string",
        permissions: "json",
        unique_id: "string"
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkDbTableChannelAccess(await this.getAccessId(row, 'table_id'), row?.id) ? row : false
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'table-channels': {
                subscribe: ({table_id}) => table_id,
                init: async ({table_id}) => this.getList(table_id),
            },
            'app-table-channels': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => this.getVersionChannels(app_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(table_id) {
        return this.query().where({table_id}).get()
    }


    /**
     * Get version list
     */
    getVersionChannels(app_id) {
        return this.query().
        join("database_tables").on("database_tables.id", "table_id").
        join("app_databases").on("app_databases.id", "db_id").
        where("app_databases.app_id", app_id).get()
    }

    /**
     * Get module channels
     */
    static getModuleChannels(module_id) {
        return DbTableChannel.query(['table_channels.*'])
          .join('database_tables').on('database_tables.id', 'table_channels.table_id')
          .join('app_databases').on('app_databases.id', 'database_tables.db_id')
          .where('app_databases.module_id', module_id)
          .get()
    }
}
