import {treeHelper} from "../utils/treeHelper.js";
import {nanoid} from "nanoid";
import {StorageNode} from "./StorageNode.js";
import {dataHelper} from "../utils/dataHelper.js";
import {Localization} from './Localization.js';
import {LocalizationMessage} from './LocalizationMessage.js';
import {AccessModel} from './AccessModel.js';

/**
 * Diagram model
 */
export class Diagram extends AccessModel {
    static entity = 'diagrams'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        diagram_type: "string",
        status: "string",
        title: "string",
        alias: "string",
        description: "string",
        source: "json",
        version: "int",
        module_id: "int",
        unique_id: "string"
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'app-diagrams': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => Diagram.query().where({app_id}).get(),
            },
            'app-module-diagrams': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => Diagram.getList(module_id),
            },
            'diagram': {
                subscribe: ({id}) => id,
                init: async ({id}) => Diagram.find(id),
            }
        }
    }

    /**
     * Get list
     */
    static async getList(module_id) {
        return this.query().where({module_id}).get()
    }

    /**
     * Duplicate diagram
     * @param diagram_id
     * @param app_id
     * @param module_id
     * @return {Promise<boolean>}
     */
    static async duplicate(diagram_id, app_id, module_id) {

        // get component from the local storage
        let component = await Diagram.find(diagram_id)

        // Copy source
        const newSource = JSON.parse(JSON.stringify(component.source))

        // Links migration map
        const linksMap = {}, nodesToCopyStorage = []

        // Regenerate ids for source
        treeHelper.traverseTree(newSource, el => {

            // Generate new id
            const newId = nanoid(10);

            // Duplicate storage for Fragment
            if(el.type === 'Fragment') nodesToCopyStorage.push({fromId: el.id, toId: newId})

            // Add to links map
            linksMap[el.id] = newId
            el.id = newId
        });

        // Copy storages
        for(const {fromId, toId} of nodesToCopyStorage) {
            await StorageNode.duplicate(component.module_id, app_id, module_id, fromId, toId)
        }

        // Patch links
        for(const link of newSource.children?.filter(l => l.type === 'link')) {
            link.properties.connection.source.id = linksMap[link.properties.connection.source.id]
            link.properties.connection.target.id = linksMap[link.properties.connection.target.id]
            link.id = Diagram.generateLinkId(link)
        }

        // create new component
        const newDiagram =await Diagram.remote().save({
            title: component.title + " copy",
            diagram_type: component.diagram_type,
            app_id: app_id,
            module_id: module_id,
            unique_id: component.unique_id,
            status: "active",
        });

        // Assign the ID of the newly created Diagram to the newSource object
        newSource.id = newDiagram.id;

        /**
         * Patch localization aliases
         * @param tree
         */
        async function patchLocalizationAliases(tree) {
            // Check if the block is marked as localizable and if it has a localeAlias
            if (tree?.isLocalizable && tree?.localeAlias) {
                // Find the localization record
                const localization = await Localization.query().where({module_id: module_id, alias: tree.localeAlias}).first();

                // If the localization record is found, duplicate it
                if (localization) {
                    // Duplicate the localization record
                    const newLocalization = await Localization.remote().save({
                        ...localization,
                        id: null,
                        alias: nanoid(10),
                    });

                    // Duplicate the localization messages
                    await LocalizationMessage.duplicate(localization.id, newLocalization.id);

                    // Update the localeAlias
                    tree.localeAlias = newLocalization.alias;
                }
            }

            // Go deeper
            for (const prop of Object.values(tree || {})) {
                if (typeof prop === 'object') {
                    await patchLocalizationAliases(prop);
                }
            }
        }

        // Patch source
        await patchLocalizationAliases(newSource);

        // Save the updated source object to the newly created Diagram.
        await Diagram.remote().save({
            id: newDiagram.id,
            source: newSource
        });

        // Copy diagram storage
        await StorageNode.duplicate(component.module_id, app_id, module_id, `diagram-${diagram_id}`, `diagram-${newDiagram.id}`);

        // Saved
        return newDiagram;
    }

    /**
     * Generate link id
     * @param link
     */
    static generateLinkId(link) {

        // Construct id
        const id = link.properties.connection.source.id + '-' + link.properties.connection.source.event + '-' + link.properties.connection.source.unique + '->' +
        link.properties.connection.target.id + '-' + link.properties.connection.target.event + '-' + link.properties.connection.target.unique;

        // Create hash
        return 'l'+dataHelper.stringHash(id);
    }
}
