<template>
  <q-splitter class="full-height" v-model="splitter">
    <template #before>
      <div class="q-pa-sm">
        <div class="text-subtitle1">Choose the relevant section</div>

        <div class="column q-gutter-md q-mt-sm">
          <q-btn
            v-for="section in sections"
            :key="section"
            @click="selectSection(section)"
            :label="section"
          />
        </div>
      </div>
    </template>

    <template #after>
      <div class="q-px-md q-pt-sm">
        <div class="text-subtitle1">Choose the desired fragment (click to paste)</div>

        <div class="row q-gutter-x-md q-gutter-y-lg q-mt-sm">
          <div
            v-for="fragment in fragments"
            :key="fragment"
            class="ui-template-preview dg-media cursor-pointer"
            @click="pasteBlock(fragment)"
          >
            <ab-flow-components-renderer
              :items="[fragment.source]"
              preview-mode
            />
          </div>
        </div>
      </div>
    </template>
  </q-splitter>
</template>

<script>
import AbFlowComponentsRenderer from 'ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer';
import {UiTemplate} from '../../../../../common/db/UiTemplate';

export default {
  name: 'DiagramUiTemplatesList',

  components: {AbFlowComponentsRenderer},

  emits: ['paste-ui-template'],

  data() {
    return {
      splitter: 20,
      selectedSection: null,
    };
  },

  computed: {
    /**
     * Templates list
     * @return {*}
     */
    templates() {
      return this.wait("templates", UiTemplate.getList(), []);
    },

    /**
     * Diagrams map
     * key: section name
     * value: list of templates
     *
     * @return {Map<any, any>}
     */
    diagrams() {
      return this.templates.reduce((result, template) => {
        const section = template.section;

        const group = result.get(section) || [];

        group.push(template);

        result.set(section, group);

        return result;
      }, new Map);
    },

    /**
     * Sections list
     * @return {any[]}
     */
    sections() {
      return Array.from(this.diagrams.keys());
    },

    /**
     * Fragments list
     * @return {any[]}
     */
    fragments() {
      return this.diagrams.get(this.selectedSection) || [];
    },
  },

  methods: {
    /**
     * Select section
     * @param section
     */
    selectSection(section) {
      this.selectedSection = section;
    },

    /**
     * Paste UI template
     * @param fragment
     */
    pasteBlock(fragment) {
      this.$emit('paste-ui-template', fragment.source);
    },
  },

  created() {
    // Subscribe to UI templates
    UiTemplate.remote().subscribe('ui-templates');
  },

  beforeUnmount() {
    // Unsubscribe from UI templates
    UiTemplate.remote().unsubscribe('ui-templates');
  },
}
</script>

<style scoped lang="scss">
.ui-template-preview {
  &:deep(.fragment-editor-cmp) {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;

    // Firefox not working: https://caniuse.com/css-zoom
    zoom: 0.7;
  }
}
</style>
