<template>

  <ui-prop-dynamic-string :block="block" title="Label" name="label"/>
  <ui-prop-select title="Size" name="btnSize" :block="block" :options="globals.options.sizes"/>

  <ui-prop-select title="Type" name="type" default-value="regular" :block="block" :options="globals.options.button_types"/>
  <ui-prop-checkbox title="No caps" name="noCaps" :block="block"/>
  <br>
  <ui-prop-checkbox v-if="hasParentForm" title="Submit button" name="submitButton" :block="block"/>

  <ui-prop-select title="Style" name="style" default-value="default" :block="block" :options="styles"/>

  <q-card class="q-my-sm">
    <q-card-section class="bg-primary text-white">
      Icon settings
    </q-card-section>

    <q-card-section class="q-pt-none">
      <ui-prop-select name="iconType" title="Icon type" default-value="icon" :block="block" :options="iconTypes"/>

      <ui-prop-media-picker v-if="block?.properties?.iconType === 'image'" :block="block" title="Icon image" name="iconImage"/>
      <ui-prop-icon-selector v-else title="Icon" name="icon" :block="block"/>

      <ui-prop-select title="Icon position" name="iconPosition" default-value="left" :block="block" :options="globals.options.sides"/>
    </q-card-section>
  </q-card>

  <ui-prop-value-selector
    :block="block"
    title="In progress variable"
    name="progressState"
    :parent-diagram-id="diagram_id"
  />

  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropIconSelector from "@/components/DiagramDesigner/Editor/properties/UiPropIconSelector.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import {propertiesMixins} from '@/components/DiagramDesigner/Editor/properties/propertiesMixins';
import UiPropMediaPicker from '@/components/DiagramDesigner/Editor/properties/UiPropMediaPicker.vue';

export default {
  name: "ButtonPropsCmp",
  mixins: [propertiesMixins],
  props: ['block'],
  components: {
    UiPropMediaPicker,
    UiPropValueSelector,
    UiPropDynamicString, UiPropIconSelector, UiDynamicProps, UiPropSelect, UiPropCheckbox},

  data() {
    return {
      iconTypes: [
        {value: "icon", label: "Icon"},
        {value: "image", label: "Image"},
      ],
      styles: [
        {value: "default", label: "Default"},
        {value: "square", label: "Square"},
        {value: "round", label: "Round"},
        {value: "rounded", label: "Rounded"},
      ],
    };
  },
  computed: {
    /**
     * Check if the block has stack parent container
     * @return {*}
     */
    hasParentForm() {
      // Get all parents
      const parents = this.canvas.getNodeParentsById(this.block.id);
      // Check if parent is Form
      return parents.some(parent => parent?.type === 'Form')
    }
  }
}

</script>

