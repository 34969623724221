<template>
  <q-page>
    <q-stepper
        v-model="step"
        header-nav
        ref="stepper"
        color="primary"
        flat
        animated
    >
      <q-step
          :name="1"
          title="Project description"
          icon="settings"
          :done="step > 1"
          :header-nav="step > 1">

        <wizard-project-description @next="generateProjectFeatures($event)"/>

      </q-step>

      <q-step
          :name="2"
          title="Create an ad group"
          caption="Optional"
          icon="create_new_folder"
          :done="step > 2"
          :header-nav="step > 2"
      >
        <wizard-project-features :features="projectFeatures" @next="setProjectFeatures($event)"/>

      </q-step>

      <q-step
          :name="3"
          title="Create an ad"
          icon="add_comment"
          :header-nav="step > 3"
      >
        Try out different ad text to see what brings in the most customers, and learn how to
        enhance your ads using features like ad extensions. If you run into any problems with
        your ads, find out how to tell if they're running and how to resolve approval issues.

        <q-stepper-navigation>
          <q-btn color="primary" @click="done3 = true" label="Finish" />
          <q-btn flat @click="step = 2" color="primary" label="Back" class="q-ml-sm" />
        </q-stepper-navigation>
      </q-step>
    </q-stepper>
  </q-page>
</template>

<script>

import WizardProjectDescription from "@/pages/workspace/wizard/WizardProjectDescription.vue";
import WizardProjectFeatures from "@/pages/workspace/wizard/WizardProjectFeatures.vue";

export default {
  name: 'AiWizard',
  components: {WizardProjectFeatures, WizardProjectDescription},
  data: () => ({
    step: 2,
    projectDescription: "",
    projectFeatures: [
      "User-friendly interface",
      "Daily reminders for workouts",
      "Personalized workout plans based on user's health status and goals",
      "Progress tracking and reporting functionality",
      "Instructions and advice on correct Kegel exercise methods",
      "Integration with health apps to track overall wellness",
      "Streaks or badges to motivate consistency",
      "In-app reminders to drink water and stretch",
      "Option to set reminders based on user's schedule",
      "Information and tips about men's health and wellness",
      "Secure user data privacy and protection",
      "Offline working capability",
      "Instant support and feedback options",
      "Community forums for discussion and shared experiences",
      "Multi-language support",
      "Compatibility with multiple devices and platforms",
      "Vibration or haptic feedback for better exercise experience",
      "Voice-guided exercises for easy use",
      "Video tutorials for better understanding"
    ]
  }),

  methods: {
    /**
     * Generate project features
     */
    async generateProjectFeatures(description) {
      this.$q.loading.show();
      // Get project features
      const featuresList = await this.app.client.call('ai-designer', 'productFeatures', description)
      this.$q.loading.hide();
      console.log(featuresList)
    },

    /**
     * Set project features
     */
    async setProjectFeatures() {
      this.$q.loading.show();
      // Get project features
      const featuresList = await this.app.client.call('ai-designer', 'productPages', this.projectDescription, this.projectFeatures)
      this.$q.loading.hide();
      console.log(featuresList)

    }
  }
}

</script>
