/**
 * User purchase model
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';

export class LocalizationMessage extends AccessModel {
    static entity = 'localization_messages'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        localization_id: "int",
        locale: "string",
        message: "string",
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkLocalizationMessageAccess(await this.getAccessId(row, 'localization_id'), row?.id) ? row : false;
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            // Messages for module
            'module-localization-messages': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => LocalizationMessage.getList(module_id),
            },
            // All messages
            'localization-messages': {
                subscribe: () => 1,
                init: async () => this.query().get(),
            }
        }
    }

    /**
     * Retrieves a list of localization messages for a given module.
     *
     * @param {number} module_id - The ID of the module for which to retrieve localization messages.
     * @returns {Promise<Array>} A promise that resolves to an array of localization messages for the given module.
     */
    static async getList(module_id) {
        return this.query(['localization_messages.*'])
          .join('localizations').on('localizations.id', 'localization_messages.localization_id')
          .where({'localizations.module_id': module_id})
          .get();
    }

    /**
     * Duplicates localization messages from one localization to another.
     *
     * This method performs the following steps:
     * - It retrieves all messages for the source localization.
     * - It iterates over each message.
     * - It creates a new message in the target localization with the same properties as the source message, except for the ID and the localization_id.
     *
     * @param {number} from_localization_id - The ID of the source localization.
     * @param {number} to_localization_id - The ID of the target localization.
     * @returns {Promise<void>} A promise that resolves when all messages have been duplicated.
     */
    static async duplicate(from_localization_id, to_localization_id) {
        const messages = await this.query().where({localization_id: from_localization_id}).get();

        for(const message of messages) {
            await LocalizationMessage.remote().save({
                ...message,
                localization_id: to_localization_id,
                id: undefined,
            });
        }
    }
}
