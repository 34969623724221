<template>
  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
  <ui-prop-string name="min" title="Min" :block="block" :default-value="0"/>
  <ui-prop-string name="max" title="Max" :block="block" :default-value="100"/>
  <ui-prop-select name="color" title="Color" :block="block" :options="moduleColors"/>
  <ui-dynamic-props :block="block"/>
</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
export default {
  mixins: [propertiesMixins],
  name: "SliderPropsCmp",
  props: ['block'],
  components: {UiPropValueSelector, UiDynamicProps, UiPropSelect, UiPropString}
}

</script>

