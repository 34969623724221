<template>
  <q-page>


    <q-dialog ref="editModule">
      <q-card class="full-width">
        <q-card-section class="bg-primary text-white">
          Add new module
        </q-card-section>
        <q-card-section>
          <module-form :edit-module="editModule" @saved="$refs.editModule.hide()"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog ref="buildApp">
      <q-card class="full-width">
        <q-card-section class="row bg-primary text-white">
          <div class="text-subtitle1">Build app</div>
          <q-space/>
          <q-btn flat icon="close" @click="$refs.buildApp.hide();"/>
        </q-card-section>
        <q-card-section>
          <build-app :mode="buildMode" :module-id="currentModule?.id" @close="$refs.buildApp.hide();"/>
        </q-card-section>

      </q-card>
    </q-dialog>

    <div class="bg-blue-8 q-pa-sm">
      <q-btn class="text-white" flat icon="arrow_back" @click="$router.replace({name: 'workspace'})">Back to apps list</q-btn>
    </div>


    <div class="row q-gutter-md q-pa-md">

      <template v-for="(module, k) of modules" :key="k">

        <q-card class="col-md-2 col-xs-12">

          <q-card-section class="bg-blue-1 row no-wrap items-center">
            <div class="text-subtitle1 cursor-pointer" @click="$router.push({name: 'components', params:{module_id: module.id}})">{{ module.name }}, #{{module.id}}</div>
            <q-space/>

            <q-btn flat icon="menu" size="sm" round @click.stop.prevent>
              <q-menu>
                <q-list>
                  <q-item clickable v-close-popup @click="copyModule(module)">
                    <q-item-section>Copy</q-item-section>
                  </q-item>

                  <q-item clickable v-close-popup @click="deleteModule(module)">
                    <q-item-section>Delete</q-item-section>
                  </q-item>

                  <!--template v-for="(mode) of ['debug', 'stage', 'release']" :key="mode">
                    <q-item clickable v-close-popup @click="currentModule=module;buildMode=mode;$refs.buildApp.show();" v-if="module.type !== 'server' || mode !== 'debug'">
                      <q-item-section>
                        <q-item-label>Build {{mode}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </template-->
                </q-list>
              </q-menu>
            </q-btn>

          </q-card-section>

          <q-card-section>
            <div class="ellipsis-3-lines">{{ module.description || 'No description' }}</div>
            <div>Type: {{ module.type || 'Undefined' }}</div>
            <div>Language: {{ module.language || 'n/a' }}</div>
          </q-card-section>

        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab fab icon="add" color="primary" direction="left">
        <q-fab-action
          v-if="moduleIdToPaste"
          icon="content_paste"
          label="Paste module"
          color="secondary"
          @click="pasteModule"
        />

        <q-fab-action
          icon="add"
          label="Add new"
          color="primary"
          @click="editModule={app_id, app_id, unique_id: nanoid(10)};$refs.editModule.show()"
        />
      </q-fab>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>
import {AppModule} from "@/../../common/db/AppModule";
import {nanoid} from "nanoid";
import ModuleForm from "@/pages/workspace/modules/ModuleForm.vue";
import BuildApp from "@/components/DiagramDesigner/Editor/builder/BuildApp.vue";
import TabCommunicationMixin from '@/mixins/TabCommunicationMixin';

export default {
  name: 'ModulesList',
  components: { BuildApp, ModuleForm},

  mixins: [TabCommunicationMixin],

  data: () => ({
    editModule: false,
    app_id: false,
    buildMode: "",
    currentModule: false
  }),

  async created() {
    // Get app id from route
    this.app_id = this.$route.params.app_id

    // Subscribe to modules
    await AppModule.remote().subscribe("app-modules",{app_id: this.app_id})
  },

  computed: {
    // Return all modules from the store
    modules() {
      return this.wait("app-modules", AppModule.query().where({app_id: this.app_id}).get(), [])
    },

    /**
     * Return copied module id from the local storage
     * @returns {string}
     */
    moduleIdToPaste() {
      return this.app.client.config.get('copy_module', false)
    },
  },

  methods: {
    nanoid,

    /**
     * Delete module
     * @param module
     * @return {Promise<void>}
     */
    async deleteModule(module) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${module.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        // Duplicate module
        await AppModule.remote().call("app", "deleteModule", module.id);

        // show toast
        this.$q.notify({
          message: `Module ${module.name} deleted`,
          color: 'positive',
          icon: 'check',
          position: 'top'
        });
      })
    },

    /**
     * Copy module
     * @param module
     */
    copyModule(module) {
      // store module id in the local storage
      this.app.client.config.set('copy_module', module.id)

      // show toast
      this.$q.notify({
        message: 'Module copied',
        color: 'positive',
        icon: 'check',
        position: 'top'
      });

      this.sendMessageToTab('copy-module', module.id);
    },

    /**
     * Paste module
     */
    pasteModule() {
      this.$q.dialog({
        title: 'Paste confirmation',
        message: `Are you sure want to paste module?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        // Duplicate module
        await AppModule.remote().call("app", "duplicateModule", this.moduleIdToPaste, this.app_id);

        // show toast
        this.$q.notify({
          message: 'Module pasted',
          color: 'positive',
          icon: 'check',
          position: 'top'
        });

        // remove module id from the local storage
        this.app.client.config.set('copy_module', false);

        this.sendMessageToTab('paste-module');
      });
    },

    /**
     * Handle messages received from other tabs.
     * @param {Event} e - The event object containing the message data.
     */
    handleTabMessage(e) {
      const {type, content} = e.data;

      switch (type) {
        case 'copy-module':
          this.app.client.config.set('copy_module', content);
          break;
        case 'paste-module':
          this.app.client.config.set('copy_module', false);
          break;
      }
    }
  }
}
</script>
