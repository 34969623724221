<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="system-alarm-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>
      <q-card-section class="bg-info text-white">
        <div class="text-no-wrap" v-text="`Chat assistant: ${title}`" />
      </q-card-section>
    </q-card>

    <div class="absolute-center">
      <ab-flow-link-connector v-for="event in functionEvents" :key="event" :block="block" type="output" :event="event"/>
    </div>

    <div class="results">
      <ab-flow-link-connector v-for="event in outgoingEvents" :key="event" :block="block" type="output" :event="event"/>
      <ab-flow-link-connector v-if="!outgoingEvents?.length" class="q-mt-sm" :block="block" type="output" event="success"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  inject: ['canvas'],
  props: ['block'],
  name: "ChatAssistantEditorCmp",
  computed: {
    title() {
      return this.getValue(this.block?.properties?.title) || 'n/a';
    },
    /**
     * Extracts the event names from the block's outgoing events properties.
     *
     * @returns {Array<string>} An array of event names.
     */
    outgoingEvents() {
      return (this.block?.properties?.outgoingEvents?.items || []).map((e) => e.event);
    },

    /**
     * Extracts the event names from the block's functions properties.
     * @return {any[]}
     */
    functionEvents() {
      return (this.block?.properties?.functions?.items || []).map((e) => e.event);
    }
  }
}

</script>
