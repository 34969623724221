<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="in-app-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>

      <q-card-section class="bg-blue-4 text-black">
        In app purchase
      </q-card-section>
      <q-card-section>
        {{product}}
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="success"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="cancel"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="error"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "InAppPurchaseEditorCmp",
  data: () => ({
  }),
  computed: {

    /**
     * Return name
     * @return {{}}
     */
    product() {
      return this.interpretString(this.block.properties?.product) || "n/a"
    },
  }
}

</script>

<style lang="scss">

.in-app-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
