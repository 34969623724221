<template>
  <ab-flow-base-cmp :block="block" class="lottie-editor-cmp" :class="classesString" :style="stylesString">
    <lottie-animation
      v-if="lottie_source"
      :key="keyNumber"
      :animation-link="lottie_source"
      :loop="isLottieLoop"
    />
  </ab-flow-base-cmp>
</template>

<script>

import LottieAnimation from  "@/components/Lottie/LottieAnimation.vue";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {pathHelper} from "@/utils/pathHelper";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp, LottieAnimation},
  props: ['block'],
  name: "LottieAnimationEditorCmp",
  data: ()=>({
    keyNumber: 0,
  }),

  computed: {
    /**
     * lottie source
     * @return {string}
     */
    lottie_source() {
      // Get path
      return pathHelper.assetPath(this.getValue(this.block?.properties?.lottie)?.source_url)
    },

    /**
     * Is lottie loop
     * @return {boolean}
     */
     isLottieLoop() {
      return this.block.properties?.loop === 1 || false;
    },
  },
  watch: {
    lottie_source(value) {
      value && this.incrementKeyNumber();
    },
    isLottieLoop() {
      this.incrementKeyNumber();
    }
  },
  methods: {
    incrementKeyNumber() {
      this.keyNumber +=1;
    }
  }
}

</script>

<style lang="scss">

.lottie-editor-cmp {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 0;
}

</style>
