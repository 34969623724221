/**
 * User purchase model
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';

export class Localization extends AccessModel {
    static entity = 'localizations'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        module_id: "int",
        alias: "string",
        type: "string",
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkLocalizationAccess(await this.getAccessId(row, 'module_id'), row?.id) ? row : false;
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            // Localizations for module
            'module-localizations': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => this.query().where({module_id}).get(),
            },
            // All localizations
            'localizations': {
                subscribe: () => 1,
                init: async () => this.query().get(),
            }
        }
    }
}
