<template>
  <ui-prop-string name="width" title="Width" :block="block"/>
  <ui-prop-string name="height" title="Height" :block="block"/>
  <ui-prop-checkbox name="hasArrows" title="Has arrows" :block="block"/>
  <ui-prop-checkbox name="hasNavigation" title="Has navigation" :block="block"/>

  <div class="row">
  <ui-prop-checkbox name="isAutoSlide" title="Auto sliding" :block="block" :true-value="1" :false-value="0" :default-value="0"/>
  <ui-prop-string class="q-ml-md" v-if="block.properties?.isAutoSlide" name="autoSlideInterval" title="Slide interval, sec" :block="block"/>
  </div>

  <ui-dynamic-props :default-value="{}" :block="block"/>
</template>

<script>


import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";

export default {
    components: {UiPropCheckbox, UiPropString, UiDynamicProps},
    props: ['block'],
    name: "CarouselPropsCmp",
    computed: {}
}

</script>
