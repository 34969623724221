<template>
  <div class="ui-prop-section">
    <div class="title text-subtitle1 q-pa-sm bg-primary text-white">{{title}}</div>
    <div class="content q-pa-sm"><slot/></div>
  </div>
</template>

<script>

export default {
  name: "UiPropSection",

  components: {},

  props: {
    title: {
      type: String,
      default: ""
    },
    hint: {
      type: String,
      default: ""
    },
  },
  data: () => ({
  }),
}

</script>

<style lang="scss">
.ui-prop-section {
  border: 1px solid #e0e0e0;
}

</style>
