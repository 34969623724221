<template>

  <div>
    <q-select class="col" v-model="selectedFunction.moduleInfo" label="Module" hint="Choose the module" emit-value map-options :options="modules"/>
    <q-select v-if="selectedFunction.moduleInfo" class="col" v-model="selectedFunction.package" label="Package" hint="Choose the package" emit-value map-options :options="packages"/>
    <q-select v-if="selectedFunction?.package" class="col" v-model="selectedFunction.function" label="Function" emit-value map-options hint="Choose a function" :options="packageFunctions"/>
    <q-card class="args-props">
      <q-card-section class="bg-primary text-white q-mt-sm">
        Function arguments
      </q-card-section>
      <template v-for="(p, k) of targetBlockVars" :key="k">
        <q-card-section>
          <value-selector
            :only-var-type="p.type"
            :title="`${p.is_reference ? '➤ ' : ''}${p.name} (${p.title || p.description})`"
            v-model="selectedFunction.arguments[p.name]"
            :block-id="blockId"
            :app-id="appId"
            :module-id="moduleId"
            :is-reference="p.is_reference"
            :parent-diagram-id="parentDiagramId"
          />
        </q-card-section>
        <q-separator spaced/>
      </template>
    </q-card>

  </div>

</template>

<script>

import {CodeFunction} from "@/../../common/db/CodeFunction.js";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import {StorageNode} from "../../../../common/db/StorageNode";
import {systemFunctionList} from "@/components/FunctionSelector/systemFunctionList";
import _ from "lodash";
import {AppModule} from "../../../../common/db/AppModule";
import {Diagram} from '../../../../common/db/Diagram';

export default {
  components: {ValueSelector},
  props: ['blockId', 'modelValue', 'moduleId', 'appId', 'parentDiagramId'],
  name: "FunctionSelector",
  data: () => ({
    selectedFunction: {
      arguments: {}
    },
  }),

  computed: {

    /**
     * Get target block vars
     */
    targetBlockVars() {
      return this.wait("targetBlockVars", async () => {
        // Get function meta
        const fnMeta = this.functions.find((f) => f.id === this.selectedFunction.function);

        // Check if function is diagram
        const isDiagramFunction = fnMeta?.type === 'diagram' && !!fnMeta?.diagram_id;
        // Get module id
        const moduleId = this.diagrams[fnMeta?.diagram_id]?.module_id || this.selectedFunction.moduleInfo;
        // Get block id
        let blockId = `func-args-${this.selectedFunction.function}`;

        // Check if function is diagram
        if (isDiagramFunction) {
          // Set block id
          blockId = `diagram-${fnMeta.diagram_id}`;
        }

        // Check if selected function is system
        const sf = _.get(systemFunctionList, this.selectedFunction.function);
        if(sf) {
          return Object.entries(sf?.arguments || {})?.map(([k, v]) => ({
            name: k,
            type: v.type,
            description: v.description,
          }))
        }

        // Load child diagram
        return await StorageNode.getArguments(
          moduleId,
          blockId,
          (node) => (node.parent_id === 0 && (!isDiagramFunction || (isDiagramFunction && node.is_argument === 1))),
        );
      }, []);
    },


    /**
     * Get functions list
     * @returns {*}
     */
    functions() {

      // Return functions list
      return this.wait("functions", async () => {
        // Get a function list
        const fl = (await CodeFunction.query().where({"module_id": this.selectedFunction.moduleInfo}).get())?.map(f => ({
          id: f.id,
          name: f.name,
          package: f.package,
          type: f.type,
          diagram_id: f.diagram_id,
          description: f.description,
        }))

        // Add system functions
        if(this.selectedFunction.moduleInfo == this.moduleId) for(const [pack, list] of Object.entries(systemFunctionList)) {
          for(const [func, props] of Object.entries(list)) {
            fl.push({
              id: `${pack}.${func}`,
              name: func,
              description: props.description,
              package: pack,
              type: 'source_code',
              diagram_id: null,
            })
          }
        }

        // Return list
        return fl
      }, [])
    },

    /**
     * Package functions list
     * @returns {*}
     */
    packageFunctions() {
      return this.functions.filter(f => f.package === this.selectedFunction?.package).map(c => ({
        label: c.name + ` (${c.description})`,
        value: c.id
      }))
    },

    /**
     * Packages list
     * @returns {*}
     */
    packages() {
      const pkg = {}
      this.functions.map(c => pkg[c.package] = {label: c.package, id: c.package})
      return Object.keys(pkg)
    },

    /**
     * Module list
     * @returns {*}
     */
    modules() {
      return this.wait("modules", async () => {
        return (await AppModule.query().where({app_id: this.appId}).get())
          .filter((m) => parseInt(m.id) === parseInt(this.moduleId) || m?.type === "server")
          .map(m => ({value: m.id, label: m.name}))
      }, [])
    },

    /**
     * Computed property that fetches all diagrams of type 'function' for the current app.
     * It waits for the diagrams to be fetched from the database using the app_id and diagram_type.
     *
     * @returns {Array} An array of diagrams if they exist, an empty array otherwise.
     */
    rawDiagrams() {
      return this.wait("diagrams", async () => {
        return await Diagram.query().where({app_id: this.appId, diagram_type: 'function'}).get();
      }, [])
    },

    /**
     * Computed property that transforms the raw diagrams into an object.
     * The object keys are the diagram ids and the values are the diagram objects.
     *
     * @returns {Object} An object of diagrams if they exist, an empty object otherwise.
     */
    diagrams() {
      return this.rawDiagrams.reduce((res, row) => {
        res[row.id] = row;

        return res;
      }, {});
    },
  },

  methods: {
  },

  async created() {
    // Subscribe to diagrams
    await Diagram.remote().subscribe("app-diagrams", {app_id: this.appId});
  },

  mounted() {
    // Init value
    this.selectedFunction = this.modelValue || {}

    // check for params
    if(!this.selectedFunction.arguments) this.selectedFunction.arguments = {}

    // Watch package
    this.$watch("selectedFunction.package", () => {
      this.selectedFunction.function = ""
    })

    // Watch for changes
    this.$watch("selectedFunction", () => {
      this.$emit('update:modelValue', this.selectedFunction)
    }, {deep: true});
  },

  async beforeUnmount() {
    // Unsubscribe from diagrams
    await Diagram.remote().unsubscribe('app-diagrams');
  },
}

</script>
