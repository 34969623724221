<template>
  <ab-flow-base-cmp :block="block" class="carousel-editor-cmp relative-position" :class="classesString" :style="stylesString">
    <q-carousel
        v-model="currentSlide"
        animated
        :arrows="hasArrows"
        :navigation="hasNavigation"
        :autoplay="slideInterval"
        infinite
        class="full-width full-height"
    >
      <q-carousel-slide v-for="(s, k) of slides" :name="k" class="column col no-pointer-events" :key="k">
        <ab-flow-components-renderer v-if="s" :items="s.children"/>
      </q-carousel-slide>

    </q-carousel>

    <ab-flow-link-connector :block="block" type="output" class="connector-center" event="children"/>
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  data: () => ({
    currentSlide: 0
  }),
  props: ['block'],
  name: "CarouselEditorCmp",
  computed: {

    /**
     * Slide interval
     * @return {number|number}
     */
    slideInterval() {
      return this.block?.properties?.isAutoSlide === 1 ? this.block?.properties?.autoSlideInterval * 1000 : 0
    },

    /**
     * Has arrows
     * @return {boolean}
     */
    hasArrows() {
      return this.block?.properties?.hasArrows === 1 || false
    },

    /**
     * Has navigation
     * @return {boolean}
     */
    hasNavigation() {
      return this.block?.properties?.hasNavigation === 1 || false
    },

    /**
     * Returns the current widget. If the parent widget is not defined, it returns the designer.
     * @return {Object} The parent widget or the designer.
     */
    currentWidget() {
      return this.parentWidget || this.designer;
    },

    /**
     * Returns an array of links that are children of the current widget and have a source id equal to the block id.
     * If the current widget or its children are not defined, it returns an empty array.
     * @return {Array} An array of link objects.
     */
    currentLinks() {
      return (this.currentWidget?.source?.children || []).filter(
        (v) => v.type === 'link' && v.properties?.connection?.source?.id === this.block.id
      ) || [];
    },

    /**
     * Returns an array of blocks that are children of the current widget and are not of type 'link'.
     * If the current widget or its children are not defined, it returns an empty array.
     * @return {Array} An array of block objects.
     */
    currentBlocks() {
      return (this.currentWidget?.source?.children || []).filter((v) => v.type !== 'link');
    },

    /**
     * Get slides list
     * @return {*}
     */
    slides() {
      // Fragments
      const frs = []

      // Get slides list
      const slides = this.currentLinks?.map(l => l.properties?.connection?.target?.id)

      // Get slides
      for(const sl of slides) {
        const fr = this.currentBlocks.find(bl => bl.id === sl)
        if(fr) frs.push(fr)
      }

      // Get parent widget
      return frs
    }
  }
}

</script>

<style lang="scss">

.carousel-editor-cmp {
  .q-carousel__slide {
    padding: 0;
  }

  .q-carousel__navigation-icon, .q-carousel__arrow {
    color: var(--foreground-color-primary);
  }
}

</style>
