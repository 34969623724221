<template>
  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
  <ui-prop-select title="Icon size" name="iconSize" :block="block" :options="globals.options.sizes" default-value="md"/>
  <ui-dynamic-props :block="block"/>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";

export default {
  mixins: [propertiesMixins],
  name: "RatingPropsCmp",
  props: ['block'],
  components: {UiPropValueSelector, UiPropSelect, UiDynamicProps}
}

</script>

