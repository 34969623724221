<template>
  <q-page class="q-ma-md" ref="stylesManager">

    <q-select :options="styleTitles" map-options emit-value v-model="currentTheme" label="Current theme">
      <template v-slot:append>
        <q-btn icon="add" label="new theme" flat @click="addNewTheme"></q-btn>
      </template>
    </q-select>

    <q-dialog ref="colorDlg">
      <q-card>
        <q-card-section class="bg-primary text-white">
          Set color for: {{setType}}
        </q-card-section>
        <q-card-section>
          <q-color v-model="setColor"/>
        </q-card-section>
        <q-card-actions class="items-center justify-center">
          <q-btn flat color="primary" @click="saveColor('colors', setStyle, setType, setColor).then(() => this.$refs.colorDlg.hide())">Set</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog ref="stylesLibrary" full-width>
      <q-card>
        <q-card-section class="bg-primary text-white">
          Styles library
        </q-card-section>
        <q-card-section>
          <template v-if="!stylesLibrary.length">
            No styles found
          </template>

          <q-splitter v-else v-model="stylesLibrarySplitter">

            <template #before>
              <div class="q-pr-sm">
                <q-list bordered padding class="no-border text-primary">
                  <q-item
                    v-for="(item, idx) in stylesLibrary"
                    :key="idx"
                    clickable
                    v-ripple
                    :active="stylesLibraryTheme === idx"
                    @click="stylesLibraryTheme = idx"
                    active-class="bg-primary text-white"
                  >
                    <q-item-section>{{item.name}}</q-item-section>
                  </q-item>
                </q-list>
              </div>
            </template>

            <template #after>
              <div class="q-pl-sm" :key="stylesLibraryTheme">
                <template v-if="!selectedStylesLibraryColors">
                  Please select theme
                </template>
                <template v-else>
                  <div class="bg-grey-4 text-black q-pa-sm q-mt-sm q-mb-sm">Background colors</div>

                  <div class="row wrap colors-list">
                    <template v-for="[k, c] in selectedStylesLibraryColors" :key="k">
                      <div class="color-item relative-position" :style="{backgroundColor: c.background}">
                        <span class="bg-white q-px-sm rounded-borders shadow-1">{{colorLabels[k] || k}}</span>
                      </div>
                    </template>
                  </div>


                  <div class="bg-grey-4 text-black q-pa-sm q-mt-md q-mb-sm">Foreground colors</div>

                  <div class="row wrap colors-list">
                    <template v-for="[k, c] in selectedStylesLibraryColors" :key="k">
                      <div class="color-item relative-position" :style="{backgroundColor: c.foreground}">
                        <span class="bg-white q-px-sm rounded-borders shadow-1">{{colorLabels[k] || k}}</span>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </template>

          </q-splitter>
        </q-card-section>

        <q-card-actions class="justify-end">
          <q-btn flat @click="$refs.stylesLibrary.hide()">Cancel</q-btn>
          <q-btn flat color="primary" @click="applyStylesLibrary">Apply style</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-tabs class="bg-primary text-white" v-model="tab">
      <q-tab name="colors">Colors</q-tab>
      <q-tab name="texts">Text styles</q-tab>
      <q-tab name="panels">Panel styles</q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab">
      <q-tab-panel name="colors">
        <div class="q-mb-md text-right row">
          <q-btn flat dense @click="$refs.stylesLibrary.show()" color="primary">Styles library</q-btn>
          <q-btn class="q-ml-xs" flat dense @click="downloadStylesLibraryCsvExample" size="sm">CSV Example</q-btn>

          <q-space />

          <q-btn flat dense @click="addNewColor">Add color</q-btn>
        </div>

        <div class="bg-grey-4 text-black q-pa-sm q-mt-sm">Background colors</div>
        <div class="row wrap colors-list q-ma-sm">

          <template v-for="[k, c] of currentColors" :key="k">
            <div class="color-item relative-position" :style="{backgroundColor: c.background}">
              <span class="bg-white q-mb-sm q-px-sm rounded-borders shadow-1">{{colorLabels[k] || k}}</span>

              <div class="bg-white q-px-xs rounded-borders shadow-2 absolute-bottom-right q-mr-xs q-mb-xs flex">
                <q-btn size="12px" flat dense round icon="edit" @click="setStyleColor(k, 'background', c.background)">
                  <q-tooltip>Change</q-tooltip>
                </q-btn>

                <q-separator vertical spaced />

                <q-btn size="12px" flat dense round icon="delete" @click.stop="deleteColor(k)">
                  <q-tooltip>Delete</q-tooltip>
                </q-btn>
              </div>
            </div>
          </template>
        </div>

        <div class="bg-grey-4 text-black q-pa-sm q-mt-sm">Foreground colors</div>
        <div class="row wrap colors-list q-ma-sm">

          <template v-for="[k, c] of currentColors" :key="k">
            <div class="color-item relative-position" :style="{backgroundColor: c.foreground}">
              <span class="bg-white q-mb-sm q-px-sm rounded-borders shadow-1">{{colorLabels[k] || k}}</span>

              <div class="bg-white q-px-xs rounded-borders shadow-2 absolute-bottom-right q-mr-xs q-mb-xs flex">
                <q-btn size="12px" flat dense round icon="edit" @click="setStyleColor(k, 'foreground', c.foreground)">
                  <q-tooltip>Change</q-tooltip>
                </q-btn>

                <q-separator vertical spaced />

                <q-btn size="12px" flat dense round icon="delete" @click="deleteColor(k)">
                  <q-tooltip>Delete</q-tooltip>
                </q-btn>
              </div>
            </div>
          </template>
        </div>
      </q-tab-panel>

      <q-tab-panel name="texts" >
        <div class="bg-primary text-white q-pa-sm q-mt-sm">Text styles</div>
        <div class="texts-list q-ma-sm">

          <template v-for="[k, c] of currentTextStyles" :key="k">
            <div class="text-item row col q-mt-sm">
              <div class="preview col column justify-center q-pl-sm" :style="{fontSize: c.fontSize+'em', fontWeight: c.fontWeight, fontFamily: c.fontFamily.replaceAll('+', ' ')}">Text style example of: {{k}}</div>
              <q-input class="text-item__field q-ml-sm" label="Font size" v-model="c.fontSize" />
              <q-select
                class="text-item__field q-ml-sm"
                v-model="c.fontFamily"
                :options="fontFamilies"
                map-options
                emit-value
                @update:model-value="fixFontWeights"
                label="Font family"
              />
              <q-select
                class="text-item__field q-ml-sm"
                v-model="c.fontWeight"
                :options="getFontWeights(c.fontFamily)"
                map-options
                emit-value
                label="Font weight"
              />
            </div>
          </template>
        </div>
      </q-tab-panel>

      <q-tab-panel name="panels">
        <panel-styles :styles="currentThemeSource"/>
      </q-tab-panel>

    </q-tab-panels>

  </q-page>
</template>

<script>

import {AppStyle} from "@/../../common/db/AppStyle";
import {nanoid} from "nanoid";
import PanelStyles from "@/pages/workspace/modules/styles/panel/PanelStyles.vue";
import {AppModule} from '../../../../../../common/db/AppModule';
import stylesLibrary from '@/assets/colors.json';

export default {
  name: "StylesManager",
  components: {PanelStyles},

  inject: ['currentModule'],

  data: () => ({
    app_id: false,
    module_id: false,
    setColor: "#000000",
    setStyle: "",
    tab: 'colors', // "panels",
    setType: "",
    currentTheme: "default",
    currentThemeSource: {},
    stylesLibrarySplitter: 20,
    stylesLibrary: stylesLibrary,
    stylesLibraryTheme: 0,
  }),
  async created() {

    // Get app id from the route
    this.module_id = this.$route.params.module_id
    this.app_id = this.$route.params.app_id

    // Subscribe to styles
    const styles = await AppStyle.remote().subscribe("module-styles", {module_id: this.module_id})

    // Get first style id to set as current
    this.currentTheme = styles?.[0]?.id
    this.currentThemeSource = styles?.[0]?.source || {}

    // Check if not styles - init default styles
    if(!styles?.length) {
      this.currentTheme = (await this.addTheme('Default'))?.id
    }

    // Watch for current theme and update source
    this.$watch("currentTheme", () => {
      this.currentThemeSource = this.currentStyle?.source || {}
      this.applyStyles();
    })

    // Watch for current theme source and save to db
    this.$watch("currentThemeSource", () => {
      AppStyle.remote().save(Object.assign({id: this.currentTheme}, this.currentStyle, {source: this.currentThemeSource}))
    }, {deep: true})

    // Apply styles
    this.$watch("currentStyle", () => {
      this.applyStyles();
    });

    await this.checkDefaultSchema();
  },

  computed: {

    /**
     * Return all styles of app
     * @return {*}
     */
    styles() {
      return this.wait("styles", AppStyle.query().where("module_id", this.module_id).get(), [])
    },

    /**
     * Return all style titles
     * @return {*}
     */
    styleTitles() {
      return this.styles.map(el => ({value: el.id, label: el.title}))
    },

    /**
     * Return current style
     * @return {*}
     */
    currentStyle() {
      return this.styles.find(el => el.id === this.currentTheme)
    },

    /**
     * Computed property that returns the settings of the current module.
     * It uses optional chaining to safely access nested properties.
     * If the settings property in the current module is undefined, it will return an empty object.
     *
     * @returns {Object} - An object containing the settings of the current module or an empty object if not defined.
     */
    moduleSettings() {
      return this.currentModule?.currentModule?.settings || {};
    },

    /**
     * Computed property that returns the colors from the current module's settings styles.
     * It uses optional chaining to safely access nested properties.
     * If any of the properties in the chain is undefined, it will return an empty array.
     *
     * @returns {Array} - An array of colors or an empty array if not defined.
     */
    moduleColors() {
      return this.moduleSettings?.styles?.colors || [];
    },


    /**
     * Return current style
     * @return {*}
     */
    currentColors() {
      // Get text styles
      const ts = this.currentThemeSource?.colors || {};

      // Return styles
      return this.moduleColors.map((color) => {
        return [
          color.value,
          {
            foreground: ts[color.value]?.foreground || '#fff',
            background: ts[color.value]?.background || '#000',
          },
        ];
      });
    },

    /**
     * Computed property that generates a mapping of color labels.
     * It uses the reduce function to iterate over the moduleColors array and create a new object.
     * The new object has the color value as the key and the color label as the value.
     *
     * @returns {Object} - An object mapping color values to their respective labels.
     */
    colorLabels() {
      return this.moduleColors.reduce((res, v) => {
        res[v.value] = v.label;

        return res;
      }, {});
    },

    /**
     * Return current texts
     */
    currentTextStyles() {

      // Get text styles
      const ts = this.currentThemeSource?.textStyles || {}

      // Apply default styles if not set
      for(const t of this.globals.options.textStyles.filter(s => s.value)) {
        if(!ts[t.value]) ts[t.value] = {
          fontSize: t.fontSize,
          fontWeight: t.fontWeight,
          fontFamily: t.fontFamily,
        }
      }

      // Set text styles
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.currentThemeSource.textStyles = ts

      // Return styles
      return Object.entries(this.currentThemeSource.textStyles) || []
    },

    /**
     * Return all font families
     */
    fontFamilies() {
      return this.globals.options.fontFamilies;
    },

    /**
     * Return all font weights
     */
    fontWeights() {
      return this.globals.options.fontWeights;
    },

    selectedStylesLibraryColors() {
      if (!this.stylesLibrary[this.stylesLibraryTheme] || !this.stylesLibrary[this.stylesLibraryTheme]?.colors) {
        return null;
      }

      return Object.entries(this.stylesLibrary[this.stylesLibraryTheme].colors);
    },
  },

  methods: {
    /**
     * This method is used to get the font weights for a given font family.
     *
     * @param {string} fontFamily - The name of the font family.
     * @returns {Array} An array of font weights.
     */
    getFontWeights(fontFamily) {
      // Find the font family from the list of font families
      const family = this.fontFamilies.find(f => f.value === fontFamily);

      // If the font family is not found, return all the font weights
      if (!family) {
        return this.fontWeights;
      }

      // If the font family is found, filter the font weights based on the weights included in the font family
      return this.fontWeights.filter((w) => family.weights.includes(w.value));
    },

    /**
     * This method is used to fix the font weights for a given font family.
     *
     * @param {string} fontFamily - The name of the font family.
     */
    fixFontWeights(fontFamily) {
      // Get the font weights for the given font family
      const weights = this.getFontWeights(fontFamily).map((w) => w.value);

      // Iterate over the current text styles
      for (const [, styles] of this.currentTextStyles) {
        // If the font family of the current text style matches the given font family
        // and its font weight is not included in the font weights
        if (styles.fontFamily === fontFamily && !weights.includes(styles.fontWeight)) {
          // Set the font weight to '400' if '400' is included in the font weights,
          // otherwise set the font weight to the first font weight
          styles.fontWeight = weights.includes('400') ? '400' : weights[0];
        }
      }
    },

    /**
     * Apply styles
     */
    applyStyles() {

      // No styles
      if(!this.currentStyle) return;

      // Create styles element
      const st = document.createElement("style");
      st.innerHTML = this.currentStyle.getStyles();
      this.$refs.stylesManager.$el.appendChild(st)
    },

    /**
     * Add new empty theme
     */
    addNewTheme() {

      // Show dialog
      this.$q.dialog({
        title: 'Add new theme',
        message: 'Enter theme title',
        prompt: {
          model: '',
          type: 'text'
        },
        cancel: true,
        persistent: true
      }).onOk(async title => {
        await this.addTheme(title)
      })
    },

    /**
     * Save color
     */
    async saveColor(type, subtype, option, value) {

      // Set color
      if(!this.currentThemeSource[type]) this.currentThemeSource[type] = {}
      if(!this.currentThemeSource[type][subtype]) this.currentThemeSource[type][subtype] = {}
      if(!this.currentThemeSource[type][subtype][option]) this.currentThemeSource[type][subtype][option] = {}
      this.currentThemeSource[type][subtype][option] = value
    },

    /**
     * Set color
     * @param style
     * @param type
     * @param color
     */
    setStyleColor(style, type, color) {
      this.setColor = color;
      this.setStyle = style;
      this.setType = type;
      this.$refs.colorDlg.show()
    },

    /**
     * Create default styles
     */
    async addTheme(title) {

      // Create default styles
      return await AppStyle.remote().save({
        app_id: this.app_id,
        module_id: this.module_id,
        title,
        unique_id: nanoid(10),
        source: {colors: {}, textStyles: {}}
      })
    },

    /**
     * Method to add a new color to the module settings.
     * It first shows a dialog to the user to enter the color name.
     * Once the user confirms, it generates a unique identifier for the color using nanoid.
     * It then creates a deep copy of the current module settings.
     * If the settings object does not have a styles property, it adds one.
     * It then adds the new color to the colors array in the styles property.
     * Finally, it saves the updated settings to the AppModule.
     *
     * @returns {Promise<void>} - A Promise that resolves when the settings have been saved.
     */
    addNewColor() {
      // Show dialog
      this.$q.dialog({
        title: 'Add new color',
        message: 'Enter color name',
        prompt: {
          model: '',
          type: 'text'
        },
        cancel: true,
        persistent: true
      }).onOk(async (label) => {
        const color = {
          label,
          value: nanoid(10),
        };

        const settings = JSON.parse(JSON.stringify(this.moduleSettings));

        if (!settings.styles) {
          settings.styles = {};
        }

        settings.styles.colors = [
          ...this.moduleColors,
          color,
        ];

        await AppModule.remote().save({
          id: this.module_id,
          settings,
        });

        await this.saveColor('colors', color.value, 'background', '#000');
        await this.saveColor('colors', color.value, 'foreground', '#fff');
      });
    },

    /**
     * Checks and updates the default schema for the module settings.
     *
     * This method first creates a deep copy of the current module settings.
     * It then maps the existing colors in the settings to their values.
     * It filters the global colors to find the ones that are not included in the existing colors.
     * If there are no such colors, it returns.
     * If the settings object does not have a styles property, it adds one.
     * It then adds the existing module colors to the styles property.
     * For each of the needed colors, it adds the color to the styles property and saves the color with a background and a foreground.
     * Finally, it saves the updated settings to the AppModule.
     *
     * @async
     */
    async checkDefaultSchema() {
      // Create a deep copy of the current module settings
      const settings = JSON.parse(JSON.stringify(this.moduleSettings));

      // Map existing colors to their values
      const existsColors = (settings?.styles?.colors || []).map((v) => v.value);

      // Filter global colors to find the ones that are not included in the existing colors
      const needColors = this.globals.options.colors.filter((v) => v.value && !existsColors.includes(v.value));

      // If there are no needed colors, return
      if (!needColors.length) {
        return;
      }

      // If the settings object does not have a styles property, add one
      if (!settings.styles) {
        settings.styles = {};
      }

      // Add existing module colors to the styles property
      settings.styles.colors = [
        ...this.moduleColors,
      ];

      // Add needed colors to the styles property and save the color with a background and a foreground
      for (const needColor of needColors) {
        settings.styles.colors.push({
          label: needColor.label,
          value: needColor.value,
        });

        await this.saveColor('colors', needColor.value, 'background', needColor.background);
        await this.saveColor('colors', needColor.value, 'foreground', needColor.foreground);
      }

      // Save the updated settings to the AppModule
      await AppModule.remote().save({
        id: this.module_id,
        settings,
      });
    },

    /**
     * Delete color
     * @param {String} value
     * @return {Promise<void>}
     */
    async deleteColor(value) {
      const label = this.colorLabels[value] || value;

      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete "${label}"?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        // Delete color
        await AppModule.remote().call("app", "deleteCustomColor", this.module_id, value);

        // show toast
        this.$q.notify({
          message: `Color ${label} deleted`,
          color: 'positive',
          icon: 'check',
          position: 'top'
        });
      })
    },

    /**
     * Asynchronously applies the selected styles library to the current module.
     * This method shows a confirmation dialog to the user. If the user confirms, it displays a loading indicator,
     * updates the module settings with the selected styles library colors, and saves the updated settings to the AppModule.
     * It then updates the current theme source with the selected styles library colors and shows a notification indicating success.
     * If an error occurs during the process, it shows an error notification and logs the error to the console.
     * The loading indicator is hidden at the end of the process, regardless of the outcome.
     */
    async applyStylesLibrary() {
      // Show dialog
      this.$q.dialog({
        title: 'Apply styles library',
        message: 'Are you sure you want to apply selected styles library?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        this.$q.loading.show();

        try {
          // Create a deep copy of the current module settings
          const settings = JSON.parse(JSON.stringify(this.moduleSettings));

          if (!settings.styles) {
            settings.styles = {};
          }

          // Update the module settings with the selected styles library colors
          settings.styles.colors = this.selectedStylesLibraryColors.map(([name]) => ({
            label: this.colorLabels[name] || name,
            value: name,
          }));

          // Save the updated settings to the AppModule
          await AppModule.remote().save({
            id: this.module_id,
            settings,
          });

          // Update the current theme source with the selected styles library colors
          this.currentThemeSource.colors = Object.fromEntries(this.selectedStylesLibraryColors);

          this.$q.notify({
            message: 'Styles library applied',
            color: 'positive',
            icon: 'check',
            position: 'top'
          });
        } catch (e) {
          this.$q.notify({
            message: 'Failed to apply styles library',
            color: 'negative',
            icon: 'report_problem',
            position: 'top'
          });

          console.error('Failed to apply styles library', e);
        } finally {
          this.$refs.stylesLibrary.hide();

          this.$q.loading.hide();
        }
      });
    },

    /**
     * Downloads a CSV example for the styles library.
     * This method creates a CSV string with predefined theme and color values, encodes it, and creates a downloadable link.
     * When the link is clicked, the CSV file is downloaded to the user's device.
     */
    downloadStylesLibraryCsvExample() {
      const csv = 'data:text/csv;charset=utf-8,' + encodeURIComponent(
        'theme,primary,secondary,tertiary,info,success,danger,warning,dark,light,validation-error\n' +
        'Theme 1,#fff / #333,#6c757d / #444,#d0d0d0 / #555,#17a2b8 / #17a2b8,#28a745 / #00aa00,#dc3545 / #aa0000,#ffc107 / #ffc107,#343a40 / #000000,#f8f9fa / #ffffff,#ffffff / #c10015'
      );

      const link = document.createElement('a');
      link.setAttribute('href', csv);
      link.setAttribute('download', 'styles-library.csv');
      link.click();
    },
  }
}

</script>

<style lang="scss">

.colors-list {
  .color-item {
    width: 150px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid #888;
    margin: 10px;
    box-shadow: #666 0px 0px 10px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.texts-list {
  .text-item {

    .preview {
      border:1px solid #ddd;
    }

    &__field {
      min-width: 150px;
    }
  }
}


</style>
