import {constants} from "../constants/constants.js";
import {AccessModel} from './AccessModel.js';

/**
 * AppStyle model
 */
export class AppStyle extends AccessModel {
    static entity = 'app_styles'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        module_id: "int",
        title: "string",
        alias: "string",
        mode: "string",
        source: "json",
        version: "int",
        unique_id: "string"
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'module-styles': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => AppStyle.getList(module_id),
            }
        }
    }

    /**
     * Get list
     */
    static async getList(module_id) {
        return this.query().where({module_id}).get()
    }

    /**
     * Get style color
     * @param id
     */
    getPanelStylesById(id) {
        return Object.values(this.source?.panelStyles).find(p => p.properties?.id === id)?.properties
    }


    /**
     * Get style color
     * @param name
     * @param type
     */
    getColor(name, type) {
        return this.source?.colors?.[name]?.[type]
    }

    /**
     * Get styles list
     */

    getStyles() {

        // Get styles from current theme style
        const stylesSource = this.source || {}

        // Apply default styles if not set
        if(!stylesSource.colors) stylesSource.colors = {}
        if(!stylesSource.textStyles) stylesSource.textStyles = {}
        if(!stylesSource.panelStyles) stylesSource.panelStyles = {}

        // Apply default styles if not set
        for(const t of constants.options.colors.filter(s => s.value)) {
            if(!stylesSource.colors[t.value]) stylesSource.colors[t.value] = {
                foreground: t.foreground,
                background: t.background
            }
        }

        // Apply default styles if not set
        for(const t of constants.options.textStyles.filter(s => s.value)) {
            if(!stylesSource.textStyles[t.value]) stylesSource.textStyles[t.value] = {
                fontSize: t.fontSize,
                fontWeight: t.fontWeight,
                fontFamily: t.fontFamily,
            }
        }

        // Styles string
        let tsStr = "";

        // Add colors
        for(const [key, value] of Object.entries(stylesSource?.colors || {})) {
            tsStr += `.dg-background-${key} {background-color:${value.background}; stroke: ${value.background}}\n`
            tsStr += `.dg-foreground-${key} {color:${value.foreground}; stroke: ${value.background}}\n`
            tsStr += `:root { --foreground-color-${key}: ${value.foreground}; }\n`
            tsStr += `:root { --background-color-${key}: ${value.background}; }\n`
        }

        // Add text styles
        for(const [key, value] of Object.entries(stylesSource?.textStyles || {})) {
            tsStr += `.dg-text-${key} {`
            if(value.fontSize) tsStr += `font-size:${value.fontSize}em;`
            if(value.fontWeight) tsStr += `font-weight:${value.fontWeight};`
            if(value.fontFamily) tsStr += `font-family:"${value.fontFamily.replaceAll('+', ' ')}";`

            tsStr += `}\n`
        }

        // Return styles
        return tsStr
    }

    /**
     * This method is used to get the selected fonts from the source.
     * It first checks if the source exists, if not, it initializes an empty object.
     * Then it iterates over the text styles in the source.
     * For each text style, it checks if the font family exists, if not, it defaults to 'Roboto'.
     * It then checks if the font weight exists, if not, it defaults to '400'.
     * It adds the font weight to the list of font weights for the font family, ensuring there are no duplicates.
     * Finally, it returns an object where the keys are the font families and the values are arrays of font weights.
     *
     * @returns {Object} An object where the keys are the font families and the values are arrays of font weights.
     */
    getSelectedFonts() {
        const stylesSource = this.source || {}

        return Object.entries(stylesSource?.textStyles || {}).reduce((res, [, styles]) => {
            res[styles.fontFamily || 'Roboto'] = [
                ...res[styles.fontFamily] || [],
                (!isNaN(parseInt(styles.fontWeight)) ? styles.fontWeight : false) || '400',
            ].filter((v, idx, array) => array.indexOf(v) === idx);

            return res;
        }, {});
    }
}
