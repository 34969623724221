<template>
  <div v-if="node" class="q-pa-md">

    <div class="text-h6 bg-grey-2">Node settings</div>
    <q-form @submit="saveNode" class="q-gutter-sm">
      <q-input
          v-model.trim="node.name"
          label="Node name *"
          hint="Name of node for navigation"
          lazy-rules
          :rules="[ val => (val && val.length) || 'Please type something']"
          :disable="readonly"
          @keydown="preventSpace"
      />

      <div class="row">
        <q-checkbox label="Is array" v-model="node.is_array" :true-value="1" :false-value="0" :disable="readonly"/>
        <q-checkbox v-if="mode==='storage'" label="Is argument" v-model="node.is_argument" :true-value="1" :false-value="0" :disable="readonly"/>
        <q-checkbox v-if="node.is_argument || mode === 'arguments'" label="Is reference" v-model="node.is_reference" :true-value="1" :false-value="0" :disable="readonly"/>
        <q-checkbox label="Update state" v-model="node.update_state" :true-value="1" :false-value="0" :disable="readonly"/>
      </div>

      <q-input
          v-model="node.title"
          label="Node title *"
          hint="Title of variable in storage"
          :disable="readonly"
      />

      <q-select class="col"
                v-model="node.type"
                label="Node type *"
                hint="Type of node value"
                lazy-rules
                :rules="[ val => (val && val.length) || 'Please type something']"
                map-options
                emit-value
                :options="dataTypes"
                :disable="readonly"
      />

      <q-select v-if="node.type==='db-record'"
                class="col"
                v-model="node.db_table"
                label="Db table *"
                hint="Database table"
                lazy-rules
                :rules="[ val => (val) || 'Please type something']"
                map-options
                emit-value
                :options="tablesList"
                :disable="readonly"
      />


      <div v-if="mode==='storage'">
        <div class="text-h6 bg-grey-2">Node value</div>

        <q-checkbox v-model="node.is_test_value" :true-value="1" :false-value="0" label="Is test value" :disable="readonly" />

        <storage-node-value-editor
          :node="node"
          :app-id="appId"
          :module-id="moduleId"
          v-model="node.value"
          v-model:locale-alias="node.locale_alias"
          v-model:is-localizable="node.is_localizable"
          :disable="readonly"
        />
      </div>


      <div>
        <q-btn v-if="!readonly" label="Save" type="submit" color="primary"/>
      </div>
    </q-form>

  </div>
</template>

<script>

import {StorageNode} from "@/../../common/db/StorageNode.js";
import {DbModule} from "@/../../common/db/DbModule.js";
import {DbTable} from "@/../../common/db/DbTable.js";
import StorageNodeValueEditor from "@/pages/workspace/storage/StorageNodeValueEditor.vue";

export default {
  components: {StorageNodeValueEditor},
  props: {
    nodeId: {},
    moduleId: {},
    mode: {
      default: "storage"
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  name: "StorageNodeValue",
  data: () => ({
    node: false
  }),
  async created() {
    this.node = await StorageNode.find(this.nodeId)
  },

  methods: {
    /**
     * Save node
     * @return {Promise<void>}
     */
    async saveNode() {
      console.log('saveNode', this.node)
      await StorageNode.remote().save(this.node)
    },

    /**
     * Edit object
     * @param nodeId
     */
    editObject(nodeId) {
      console.log('editObject', nodeId)
    },

    /**
     * Prevents the default action of adding a space character when the spacebar is pressed.
     *
     * @param {KeyboardEvent} event - The keyboard event triggered by pressing a key.
     */
    preventSpace(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
  },

  computed: {

    /**
     * Get app id from route
     * @return {*}
     */
    appId() {
      return this.$route.params.app_id
    },

    /**
     * Project tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {

        // Tables object list
        const tables = []

        // Load database tables
        for(const db of await DbModule.query().where("app_id", this.appId).get()) {
          for(const table of await DbTable.query().where("db_id", db.id).get()) {
            tables.push({
              value: table.id,
              label: `${db.name}.${table.name}`
            })
          }
        }

        // Return tables
        return tables
      }, []);
    },

    /**
     * Data types
     * @return {*[]}
     */
    dataTypes() {

      return [...(this.globals.options.data_types), ...[
        {value: "db-record", label: "DB record"},
      ]]
    }
  }
}

</script>
