<template>
  <ui-prop-value-selector :block="block" name="currentProgress" title="Progress" only-var-type="int" :parent-diagram-id="diagram_id"/>
  <ui-prop-select name="backColor" title="Back color" :block="block" :options="moduleColors"/>
  <ui-prop-bg-color name="trackBackground" title="Track color" :block="block" exclude-types="image" />
  <ui-prop-checkbox name="trackRounded" title="Track rounded" :true-value="1" :false-value="0" :block="block" :default-value="0"/>
  <ui-prop-string name="strokeWidth" title="Stroke width" :block="block" default-value="5"/>
  <ui-prop-string name="size" title="Size" :block="block" default-value="50"/>
  <ui-dynamic-props :block="block"/>
</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropBgColor from '@/components/DiagramDesigner/Editor/properties/UiPropBgColor.vue';
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';

export default {
  mixins: [propertiesMixins],
  name: "CircularProgressPropsCmp",
  props: ['block'],
  components: {UiPropCheckbox, UiPropBgColor, UiPropString, UiPropValueSelector, UiDynamicProps, UiPropSelect}
}

</script>

