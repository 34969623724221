<template>

  <!--media-picker v-model="background.image" :product_id="canvas.product_id" :media-type="background.type"/!-->
  <ui-prop-value-selector :block="block" name="video" title="Video file" only-var-type="video" :parent-diagram-id="diagram_id"/>
  <ui-prop-select :block="block" name="video_fitting" title="Video fitting" :options="globals.options.videoFitting"/>

  <ui-data-props name="items" title="Subtitles and audio tracks" :default-schema="defaultSchema" :block="block" />

  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiDataProps from '@/components/DiagramDesigner/Editor/properties/UiDataProps.vue';

export default {
  mixins: [propertiesMixins],
  name: "VideoPropsCmp",
  props: ['block'],
  components: {UiDataProps, UiPropValueSelector, UiPropSelect, UiDynamicProps},

  data() {
    return {};
  },

  computed: {
    /**
     * Returns a list of languages that are available in the current media designer.
     *
     * This method performs the following steps:
     * - It retrieves the list of languages from the global options.
     * - It filters the list of languages to only include those that are included in the `appLocales` of the current media designer.
     * - If `appLocales` is not defined, it defaults to an empty array.
     *
     * @returns {Array} An array of languages that are available in the current media designer.
     */
    languages() {
      return this.globals.options.languages.filter(
        (v) => (this.app.currentMediaDesigner?.appLocales || []).includes(v.value),
      );
    },

    // Default schema
    defaultSchema() {
      return [
        {
          name: "language",
          title: 'Language',
          type: "select",
          options: this.languages,
        },
        {
          name: 'audio',
          title: 'Audio track (mp3)',
          type: 'sound',
        },
        {
          name: 'subtitles',
          title: 'Subtitles (vtt)',
          type: 'subtitles',
        },
      ];
    },
  },
}

</script>

