<template>

  <div>
    <ui-prop-dynamic-string :multiple="true" :block="block" title="Prompt" name="prompt" untranslatable />

    <ui-prop-value-selector :block="block" name="face" title="Face" only-var-type="image" :parent-diagram-id="diagram_id"/>

    <ui-prop-select name="service" title="AI Service" default-value="piapi" :block="block" :options="AIServices"/>

    <ui-prop-string name="width" title="Image width" :block="block" :default-value="512" hint="Integer"/>
    <ui-prop-string name="height" title="Image height" :block="block" :default-value="512" hint="Integer"/>

    <ui-prop-string name="seed" title="Seed" :block="block" :default-value="1234" hint="Integer"/>

    <ui-prop-checkbox class="q-mt-sm" title="Synchronous response return" name="syncResponse" :block="block"/>

    <template v-if="!syncResponse">
      <ui-prop-value-selector
        :block="block"
        title="Variable to save job ID"
        name="varToSaveJobId"
        value-type="setter"
        :parent-diagram-id="diagram_id"
      />

      <div class="q-pa-sm q-mt-md rounded-borders bg-grey-2">
        <div>WebSocket channel: <a class="text-bold cursor-pointer text-primary" @click="copyToClipboard('tools:ai:image-generator')">tools:ai:image-generator</a></div>

        <div class="q-mt-md">The data structure received in the socket connection is as follows:</div>
        <pre>{{ socketVarsJson }}</pre>
      </div>
    </template>
    <template v-else>
      <ui-prop-value-selector
        :block="block"
        title="Variable to save image URL"
        name="varToSaveImageUrl"
        value-type="setter"
        :parent-diagram-id="diagram_id"
      />
    </template>
  </div>

</template>

<script>
import {copyToClipboard} from 'quasar';
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
  components: {UiPropDynamicString, UiPropSelect, UiPropValueSelector, UiPropCheckbox, UiPropString},
  mixins: [propertiesMixins],
  props: ['block'],
  name: "ImageGeneratorPropsCmp",

  data() {
    return {
      aspectRatios: ['square', 'portrait', 'landscape'],
      AIServices: [{value: 'getimg', label: "GetImg"}, {value: 'piapi', label: "PI API"}, {value: 'rendernet', label: "RenderNet"}],

      socketVarsJson: JSON.stringify({
        jobId: 'job ID',
        url: 'Image URL',
      }, null, 2),
    };
  },

  computed: {
    // Get the title property from the block
    syncResponse() {
      return this.block?.properties?.syncResponse === 1;
    },
  },

  methods: {
    /**
     * Copies the specified value to the clipboard.
     * This method attempts to copy the provided value to the user's clipboard using the `copyToClipboard` function.
     * If the operation is successful, a notification with a positive message is displayed to the user.
     * In case of an error (e.g., the browser does not support clipboard operations), the error is logged to the console.
     *
     * @param {string} val - The value to be copied to the clipboard.
     */
    copyToClipboard(val) {
      try {
        copyToClipboard(val);

        this.$q.notify({
          message: 'Copied to clipboard',
          color: 'positive',
        })
      } catch (e) {
        console.error(e);
      }
    },
  },
}

</script>
