<template>

  <ab-flow-base-cmp class="fab-cmp" :block="block" :class="` ${position}`" :style="stylesString">
    <q-btn v-if="!direction" fab :icon="icon" :class="classesString"/>

    <q-fab v-else :active-icon="activeIcon" :icon="icon" :direction="direction" :class="classesString">
      <q-fab-action v-for="(item, index) of itemsList" :key="index" :icon="getIcon(item.icon)" :class="getItemClasses(item)">
        <ab-flow-link-connector :block="block" type="output" :event="item.name"/>
      </q-fab-action>
    </q-fab>

    <ab-flow-link-connector v-if="!direction" :block="block" type="output" event="click"/>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

import {pathHelper} from "@/utils/pathHelper";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  props: ['block'],
  name: "FABEditorCmp",
  computed: {
    /**
     * Color
     * @return {string}
    */
    color() {
      return this.block?.properties?.color || "";
    },
    /**
     * Position
     * @return {string}
    */
    position() {
      return this.block?.properties?.position || "center";
    },
    /**
     * Direction
     * @return {string}
    */
    direction() {
      return this.block?.properties?.direction || "";
    },
    /**
       * Icon
       * @return {string}
    */
    icon() {
      return this.block?.properties?.icon ? `${this.getIcon(this.block.properties.icon)}`: this.getIconByDirection();
    },

    /**
       * Ative Icon
       * @return {string}
    */
    activeIcon() {
      return this.block?.properties?.activeIcon ? `${this.getIcon(this.block.properties.activeIcon)}`: 'close';
    },
    /**
     * Items list
     * @return {*}
     */
      itemsList() {
      return this.block?.properties?.items?.items || [{}];
    },
  },
  methods: {
    /**
     * Get item classes
     * @param item
     */
    getItemClasses(item) {
      const cls = [];
      cls.push(`dg-background-${item.backgroundColor || 'primary'}`)
      cls.push(`dg-foreground-${item.foregroundColor || 'primary'}`)

      // Return classes array
      return cls;
    },

    getIconByDirection() {
      return this.direction ? `keyboard_arrow_${this.direction}` : "add"
    },
    getIcon(icon) {
      return icon ? `img:${pathHelper.assetPath(icon)}` : "add";
    }
  }
}

</script>

<style scoped lang="scss">
  .fab-cmp {
    position: absolute !important;
    margin: 5px;
    transform: translate3d(0, 0, 0);

    &.center {
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      margin: 0;
    }

    &.top-left {
      left: 0;
      top: 0
    }

    &.top-right {
      right: 0;
      top: 0
    }

    &.bottom-left {
      left: 0;
      bottom: 0
    }

    &.bottom-right {
      right: 0;
      bottom: 0
    }
  }
</style>
