<template>

  <ui-prop-value-selector :block="block" name="dataSource" title="Data source" :is-array="true" :only-var-type="['object']" :parent-diagram-id="diagram_id"/>
  <ui-prop-value-selector :block="block" name="field" only-var-type="string" title="Selected rows" :parent-diagram-id="diagram_id"/>
  <ui-data-props v-if="defaultSchema" name="fields" :default-schema="defaultSchema" :block="block"/>
  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps.vue";

export default {
  mixins: [propertiesMixins],
  name: "TableViewPropsCmp",
  props: ['block'],
  components: { UiDataProps, UiPropValueSelector, UiDynamicProps},
  data: () => ({
    defaultSchema: false
  }),

  created() {
    // Init default schema
    this.defaultSchema = [
      {
        name: "name",
        type: "string",
        title: "Field name"
      },
      {
        name: "type",
        type: "select",
        title: "Field type",
        options: this.globals.options.db_data_types
      },
      {
        name: "title",
        type: "string",
        title: "Field title"
      },
    ]
  }
}

</script>

