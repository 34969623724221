<template>

  <ui-prop-select name="position" title="Navigtion position" default-value="up" :block="block" :options="globals.options.expandableDirection"/>
  <ui-prop-select name="viewMode" title="View: Icons/Images" default-value="icon" :block="block" :options="viewModes"/>
  <ui-data-props name="items" :default-schema="defaultSchema" :block="block" :key="viewMode"/>

  <ui-prop-value-selector :block="block" name="dynamicItems" only-var-type="object" title="Dynamic items" :parent-diagram-id="diagram_id"/>

  <ui-prop-value-selector :block="block" name="field" only-var-type="string" title="Tab variable" :parent-diagram-id="diagram_id"/>
  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  mixins: [propertiesMixins],
  name: "NavigationPanelPropsCmp",
  props: ['block'],
  components: {UiPropSelect, UiPropValueSelector, UiDataProps, UiDynamicProps},
  data() {
    return {
      viewModes: [
        {value: "icon", label: "Icon"},
        {value: "image", label: "Image"},
      ],
    };
  },

  computed: {
    /**
     * View mode
     * @return {string}
     */
    viewMode() {
      return this.block?.properties?.viewMode || 'icon';
    },

    /**
     * Default schema
     * @return {({name: string, type: string}|{name: string, type: string})[]}
     */
    defaultSchema() {
      return [
        {
          name: "title",
          type: "localizable"
        },
        ...(this.viewMode === 'icon' ? [
          // If icon mode
          {
            name: "icon",
            type: "icon"
          },
        ] : [
          // If image mode
          {
            name: "image",
            type: "image"
          },
          {
            name: "activeImage",
            type: "image"
          },
        ]),
        {
          name: "value",
          type: "string"
        },
      ];
    },
  },
}

</script>

