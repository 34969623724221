<template>

  <template v-if="!node.is_array && !(node.type === 'db-record' && node.is_test_value)">
    <template v-if="node.type === 'string'">
      <q-input class="col" v-model="editValue" :label="node.name" filled>
        <template #append>
          <q-checkbox
            v-model="isLocalizable"
            :false-value="false"
            :true-value="true"
            color="positive"
            checked-icon="public"
            unchecked-icon="public_off"
            title="Translatable"
          />

          <localization-editor
            v-if="isLocalizable"
            v-model="editValue"
            :alias="localeAlias"
          />
        </template>
      </q-input>
    </template>

    <template v-else-if="node.type === 'int'">
      <q-input class="col" v-model="editValue" :label="node.name"/>
    </template>

    <template v-else-if="node.type === 'float'">
      <q-input class="col" v-model="editValue" :label="node.name"/>
    </template>

    <template v-else-if="node.type === 'bool'">
      <q-select v-model="editValue" emit-value map-options :label="node.name" :options="globals.options.boolValues"/>
    </template>

    <template v-else-if="node.type === 'image'">
      <media-picker media-type="image" v-model="editValue" :label="node.name" :product-id="appId" :module-id="moduleId" />
    </template>

    <template v-else-if="node.type === 'icon'">
      <icon-selector v-model="editValue" :title="node.name"/>
    </template>

    <template v-else-if="node.type === 'lottie'">
      <media-picker media-type="lottie" v-model="editValue" :label="node.name" :product-id="appId" :module-id="moduleId"/>
    </template>

    <template v-else-if="node.type === 'sound'">
      <media-picker media-type="sound" v-model="editValue" :label="node.name" :product-id="appId" :module-id="moduleId"/>
    </template>

    <template v-else-if="node.type === 'color'">
      <q-select v-model="editValue" emit-value map-options option-value="value" label="Color" :options="moduleColors"/>
    </template>

    <q-banner v-else class="bg-grey-3">
      Value is not editable
    </q-banner>

  </template>

  <template v-if="node.is_array || (node.type === 'db-record' && node.is_test_value)">
    <vue3-json-editor title="Object value" v-model="editValue" @json-change="editValue = $event" :expandedOnStart="true"/>
  </template>

</template>

<script>

import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";
import IconSelector from "@/components/IconSelector/IconSelector.vue";
import {StorageNode} from "@/../../common/db/StorageNode.js";
import {Vue3JsonEditor} from "vue3-json-editor";
import LocalizationEditor from '@/components/Localizations/LocalizationEditor.vue';
import {nanoid} from 'nanoid';

export default {
  components: {LocalizationEditor, Vue3JsonEditor, IconSelector, MediaPicker},

  inject: {
    currentModule: {
      default: null
    },
  },

  props: ['node', 'appId', 'modelValue', 'moduleId', 'modelValue:localeAlias', 'modelValue:isLocalizable'],

  emits: ['update:modelValue', 'update:localeAlias', 'update:isLocalizable'],

  name: "StorageNodeValueEditor",
  data() {
    return {
      editValue: false,
      isLocalizable: this.node?.is_localizable === 1,
      localeAlias: this.node?.locale_alias || '',
    };
  },

  /**
   * Save initial node
   */
  created() {

    // Default value
    let defVal = "";
    if(this.node.type === 'object') defVal = {}
    if(this.node.is_array) defVal = []

    // Store initial node
    this.editValue = this.modelValue || defVal;

    // Check model value for supported type
    if(this.node.is_array && !Array.isArray(this.editValue)) this.editValue = []
    if(!this.node.is_array && this.node.type === 'object' && Array.isArray(this.editValue)) this.editValue = {}

    // Watch editValue and update modelValue
    this.$watch("editValue", (val) => {
      this.$emit('update:modelValue', val)
    }, {deep: true})
  },

  computed: {

    /**
     * Objects properties list
     * @return {*}
     */
    objectProperties() {
      return this.wait("objectProperties", async () => {
        return StorageNode.query().where("parent_id", this.node.id).get()
      }, [])
    },
    /**
     * Computed property that returns the colors from the current module's settings styles.
     * It uses optional chaining to safely access nested properties.
     * If any of the properties in the chain is undefined, it will return an empty array.
     *
     * @returns {Array} - An array of colors or an empty array if not defined.
     */
    moduleColors() {
      return [
        {value: "", label: "None"},
        ...(this.currentModule?.currentModule?.settings?.styles?.colors || [])
      ];
    },
  },
  methods: {

    /**
     * Add new item to array editValue
     */
    addArrayItem() {
      this.editValue.push({})
    },

    /**
     * Delete item from array editValue
     */
    deleteArrayItem(key) {
      this.editValue.splice(key, 1)
    },

    /**
     * Edit object
     * @param nodeId
     */
    editObject(nodeId) {
      console.log('editObject', nodeId)
    }
  },

  watch: {
    /**
     * Watcher for the `isLocalizable` data property.
     *
     * This method performs the following actions when `isLocalizable` changes:
     * - It emits an 'update:isLocalizable' event with the new value of `isLocalizable`. The new value is 1 if `isLocalizable` is truthy, and 0 otherwise.
     * - If `isLocalizable` is truthy and `localeAlias` is falsy, it generates a new locale alias using the `nanoid` function and emits an 'update:localeAlias' event with the new locale alias.
     */
    isLocalizable() {
      // Emit isLocalizable event
      this.$emit('update:isLocalizable', this.isLocalizable ? 1 : 0);

      // Generate localeAlias if isLocalizable is true and localeAlias is falsy
      if (this.isLocalizable && !this.localeAlias) {
        this.localeAlias = nanoid(10);
        this.$emit('update:localeAlias', this.localeAlias);
      }
    }
  },
}

</script>
