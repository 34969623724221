<template>
  <div class="col">
    <q-dialog ref="selectDlg">
      <q-card class="full-width">
        <q-card-section class="bg-primary text-white row">
          Select variable
          <q-space/>
          <q-btn icon="close" flat round @click="$refs.selectDlg.hide();"/>
        </q-card-section>
        <q-card-section>
          <q-tree no-selection-unset node-key="id" :nodes="varsTree" v-model:selected="selectedVar"
                  @update:selected="selectVar"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div>
      <label>
        {{ title }}
      </label>
      <div class="row col">

        <div class="row">
          <q-select @update:model-value="resetValue" :options="allowedValueTypes" label="Type"
                    v-model="selectedValue.valueType" emit-value map-options class="q-mr-sm"/>

          <template v-if="['static', 'expression'].includes(selectedValueType) && !returnOptions">
            <q-select class="col" v-if="!varTypeFilter" emit-value map-options v-model="selectedValue.type"
                      :options="varTypeOptions" label="Format"/>

            <q-checkbox
              v-if="['options'].includes(varType) || hasOnlyVarType('options')"
              v-model="isLocalizable"
              :false-value="false"
              true-value="plain"
              color="positive"
              checked-icon="public"
              unchecked-icon="public_off"
              label="Translatable"
            />
          </template>
        </div>

        <q-field label="Value" stack-label class="col" v-if="selectedValueType === 'variable'">
          <template v-slot:append>
            <q-btn icon="list" flat size="sm" @click="$refs.selectDlg.show()"/>
          </template>

          <div class="text-black cursor-pointer overflow-hidden">
            {{ selectedValue?.value }}
          </div>
        </q-field>
      </div>

      <div class="col">
        <template v-if="selectedValueType === 'static'">

          <template v-if="returnOptions">
            <q-select v-model="selectedValue.value" label="Value" map-options emit-value
                      :options="returnOptions" class="col"/>
          </template>
          <template v-else-if="varType === 'bool'">
            <q-select v-model="selectedValue.value" label="Value" map-options emit-value
                      :options="globals.options.boolValues" class="col"/>
          </template>
          <template v-else-if="['int', 'float', 'string'].includes(varType)">
            <div class="row no-wrap">
              <q-input v-model="selectedValue.value" label="Value" class="col"/>

              <template v-if="varType === 'string'">
                <q-checkbox
                  v-model="isLocalizable"
                  :false-value="false"
                  true-value="plain"
                  color="positive"
                  checked-icon="public"
                  unchecked-icon="public_off"
                  title="Translatable"
                />

                <template v-if="isLocalizable">
                  <localization-editor
                    v-model="selectedValue.value"
                    :alias="localeAlias"
                  />
                </template>
              </template>
              </div>
          </template>

          <template v-else-if="['image', 'lottie', 'video', 'sound'].includes(varType)">
            <media-picker v-model="selectedValue.value" :media-type="varType" :product-id="appId" @closed="$emit('updated')" @changed="$emit('updated')"
                          :module-id="moduleId" label="Value" class="col"/>
          </template>

          <template v-else-if="['icon'].includes(varType)">
            <icon-selector v-model="selectedValue.value" label="Value" class="col"/>
          </template>

          <template v-else-if="['datetime'].includes(varType)">
            <date-time-constants v-model="selectedValue.value"/>
          </template>

          <template v-if="['options'].includes(varType) || hasOnlyVarType('options')">
            <options-constants v-model="selectedValue"/>
          </template>

        </template>

        <template v-if="selectedValueType === 'input'">
          <q-input v-model="selectedValue.path" label="Input path" class="col"/>
        </template>

        <template v-if="selectedValueType === 'function'">
          <function-selector v-model="selectedValue.value" :app-id="appId" :module-id="module_id"
                                   :block-id="blockId" :parent-diagram-id="parentDiagramId"/>
        </template>

        <template v-if="selectedValueType === 'expression'">
          <dynamic-string
            v-model="selectedValue.expression"
            :block-id="blockId"
            :parent-diagram-id="parentDiagramId"
            :app-id="appId"
            :module-id="moduleId"
          >
            <template #actions>
              <q-checkbox
                v-model="isLocalizable"
                :false-value="false"
                true-value="plain"
                color="positive"
                checked-icon="public"
                unchecked-icon="public_off"
                title="Translatable"
              />

              <localization-editor
                v-if="isLocalizable"
                v-model="selectedValue.expression.value"
                :alias="localeAlias"
              />
            </template>
          </dynamic-string>
        </template>

        <template v-if="selectedValueType === 'condition'">
          <value-condition v-model="selectedValue.condition" :block-id="blockId" :return-options="returnOptions" :only-var-type="varTypeFilter" :parent-diagram-id="parentDiagramId" :app-id="appId" :module-id="moduleId"/>
        </template>

        <template v-if="selectedValueType === 'db'">
          <q-input type="textarea" v-model="selectedValue.value" label="DB query" class="col"/>
        </template>
      </div>

      <template v-if="selectedValueType === 'operation'">
        <value-selector-operation v-model="selectedValue" :app-id="appId" :module-id="module_id"
                                  :block-id="blockId" :parent-diagram-id="parentDiagramId"/>
      </template>

    </div>

  </div>
</template>

<script>
import {nanoid} from 'nanoid';
import {StorageNode} from "@/../../common/db/StorageNode.js"
import {DbTableField} from "@/../../common/db/DbTableField.js"
import {treeHelper} from "@/../../common/utils/treeHelper.js"
import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";
import IconSelector from "@/components/IconSelector/IconSelector.vue";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import ValueSelectorOperation from "@/components/ValueSelector/ValueSelectorOperation.vue";
import DateTimeConstants from "@/components/ValueSelector/Constants/DateTimeConstants.vue";
import ValueCondition from "@/components/ValueSelector/Conditions/ValueCondition.vue";
import OptionsConstants from "@/components/ValueSelector/Constants/OptionsConstants.vue";
import FunctionSelector from "@/components/FunctionSelector/FunctionSelector.vue";
import DynamicString from "@/components/DynamicString/DynamicString.vue";
import LocalizationEditor from '@/components/Localizations/LocalizationEditor.vue';
import {designerUiComponentsList} from '@/components/DiagramDesigner/Editor/components/UI/designerUiComponentsList';

export default {
  mixins: [renderMixins],
  emits: ['updated', 'changed', 'update:modelValue'],
  inject: {
    canvas: {
      default: false
    }
  },
  components: {
    DynamicString,
    FunctionSelector,
    OptionsConstants,
    ValueCondition,
    DateTimeConstants,
    ValueSelectorOperation,
    IconSelector,
    MediaPicker,
    LocalizationEditor,
  },
  props: {
    modelValue: {
      default: ""
    },
    returnOptions: {
      type: Array,
      default: null
    },
    parentDiagramId: {
      default: null
    },
    blockId: {
      default: null
    },
    isReference: {
      default: false
    },
    appId: {
      default: false
    },
    moduleId: {
      default: false
    },
    title: {
      default: ""
    },
    onlyVarType: {
      default: false
    },
    isArray: {
      default: false
    },
    valueType: {
      default: false
    }
  },
  name: "ValueSelector",
  data: () => ({
    typesConversion: {"autogenerated": "string", "integer": "int"},
    selectVarWindow: false,
    selectedVar: "",
    varTypeFilter: false,
    selectedValue: {
      valueType: false,
    }
  }),
  async created() {

    // Set var type filter
    this.varTypeFilter = this.onlyVarType ? this.onlyVarType?.split(",") : false

    // Replace types according to conversion list
    if(this.varTypeFilter && Array.isArray(this.varTypeFilter))
      this.varTypeFilter = this.varTypeFilter.map(t => this.typesConversion[t] || t)

    // Set value type according to prop
    this.selectedValue.valueType = 'none';//this.valueType === 'setter' ? 'dynamic' : 'static';

    // Set current value
    if (this.modelValue && typeof this.modelValue === "object") this.selectedValue = this.modelValue;

    // Override value type if onlyVarType is set
    if (this.varTypeFilter && this.varTypeFilter?.length) this.selectedValue.type = this.varTypeFilter[0];

    // Add watcher to save selected value to current value on change
    this.$watch('selectedValue', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})


  },
  methods: {

    /**
     * Reset value
     */
    resetValue() {
      this.selectedValue.value = ''

      const keysForClearing = Object.keys(this.selectedValue).filter(
        (k) => !['valueType', 'value', 'type'].includes(k)
      );

      if (!keysForClearing.length) {
        return;
      }

      for (const k of keysForClearing) {
        delete this.selectedValue[k];
      }
    },

    /**
     * Select var
     * @param opt
     */
    selectVar() {
      if (!this.selectedVar.startsWith("_")) {

        // get node
        const selNode = treeHelper.traverseTree({children: this.varsTree}, (node) => {
          if (node.id === this.selectedVar) return node;
        })

        if (selNode) {
          console.log("selNode", selNode)

          // Set value
          this.selectedValue = Object.assign(this.selectedValue, {
            value: selNode.id,
            blockId: selNode.blockId,
            nodeId: selNode.nodeId,
            isArray: selNode.isArray,
            isReference: this.isReference,
            type: selNode.type,
            defaultValue: selNode.defaultValue
          });
        }
      }
    },

    /**
     * Get storage tree
     * @returns {*}
     */
    async getStorageTree(type, path, fromId = 0) {

      // Get app nodes, it is tree structure id, parent_id
      const nodes = await StorageNode.query().where("module_id", this.moduleId).where("block_id", type).get()

      const tree = async (nodes, node_id, path) => {

        // Get current node
        const node = nodes.find(node => node.id === node_id) || {id: 0, name: "", type: "object"}

        // Reset node name for root
        if (node_id === fromId) node.name = ""

        // Add node
        const newNode = {
          id: path + node.name,
          label: `${node.name}`,
          blockId: type,
          nodeId: node.id,
          isArray: node.is_array,
          //isReference: node.is_reference,
          type: node.type,
          defaultValue: node.value,
          children: []
        };

        // Filter by type if set
        if (this.checkAllowedTypes(node)  || ['object', 'db-record'].includes(node.type)) {

          // Check if record is db-record - add to children table fields list
          if (node.type === 'db-record' && node.db_table) {

            // Load fields list
            for (const t of await DbTableField.query().where("table_id", node.db_table).get()) {

              // Skip not allowed types
              if (!this.checkAllowedTypes(t)) continue;

              // Add to final list
              newNode.children.push(
                  {
                    id: path + (node.name ? node.name + "." : "") + t.name,
                    label: t.name,
                    blockId: type,
                    isArray: node.is_array,
                    //isReference: node.is_reference,
                    type: t.type,
                    children: []
                  })
            }
          }/**/

          // Process children
          for (const ch of nodes?.filter(node => node.parent_id === node_id) || []) {
            // Child nodes
            const chNode = await tree(nodes, ch.id, path + (node.name ? node.name + "." : ""))
            if (chNode) newNode.children.push(chNode)
          }

          // Return list
          return newNode;
        }
      }

      // Return tree
      return (await tree(nodes, fromId, path))?.children || []
    },

    /**
     * Check if type is allowed
     * @param node
     * @return {boolean|*}
     */
    checkAllowedTypes(node) {
      return (!this.varTypeFilter || this.compareTypes(this.varTypeFilter, node.type)) &&
          (!this.isArray || node.is_array) // Check for arrays
    },

    /**
     * Compare types
     * @param allowedTypes
     * @param type
     */
    compareTypes(allowedTypes, type) {

      // Init array
      if(!Array.isArray(allowedTypes)) allowedTypes = [allowedTypes]

      // Check if type is allowed
      for(const at of allowedTypes) {
        if (at === type || at === this.typesConversion[type]) return true;
      }

      // Not allowed
      return false;
    },

    /**
     * Get repeaters vars
     * @param blId
     */
    async getParentVars(blId) {

      // Vars list
      const varsList = [];

      // Blocks list
      let blList = [blId];

      // Repeater components types
      const repeaterTypes = designerUiComponentsList.reduce(
        (res, group) => [...res, ...(group.children || []).map(c => c?.isRepeater ? c.type : false).filter(Boolean)], []
      );

      // Unique blocks
      const processedIds = {}

      // Check if it is top level object - get linked object
      blList.push(...(this.canvas.getParentLinkedBlocks(blId) || []));

      // GEt block parents
      for(const inBlId of [...blList]) {
        this.canvas.getNodeParentsById(inBlId)?.find(p => {
          if (p.type === 'Fragment' && p.properties?.fragmentType === 'slot') {
            blList.push(...(this.canvas.getParentLinkedBlocks(p.id) || []));
          }
        })
      }

      // Get repeaters
      for (const blockId of blList) {

        // Get parents of current block
        for (const par of this.canvas.getNodeParentsById(blockId) || []) {

          // Skip if already processed
          if(processedIds[par.id]) continue;

          // Add to processed
          processedIds[par.id] = true;

          // Source name
          const sourceName = par?.title?.toLowerCase()

          // Process products list
          if (['InAppProductsList'].includes(par.type)) {
            varsList.push(this.processProductsList(par, sourceName))
          } else

          // Process native ads
          if (['NativeAds'].includes(par.type)) {
            varsList.push(this.processNativeAds(par, sourceName))
          } else


          // Process static options
          if (par.properties?.dataSource?.valueType === 'static') {
            varsList.push(this.processStaticOptions(par, sourceName))
          } else

              // Add repeaters vars
          if (repeaterTypes.includes(par.type)) {

            // Get children
            const children = await this.getStorageTree(par.properties?.dataSource?.blockId, sourceName + ':item.', par.properties?.dataSource?.nodeId);

            // Check if no children - add "value" to represent the whole object
            if(children.length === 0) children.push({id: `${sourceName}:item.value`, label: "value"})

            // Regular data type
            varsList.push({
              id: "_" + par.title + "-" + par.id,
              label: par.title,
              children: [{
                id: `${sourceName}:item`,
                label: "item",
                children:
                    [...[{
                      id: `${sourceName}:item.number`,
                      label: "Sequence number",
                    }], ...children]
              }]
            })
          } else

          // Add fragments vars
          if (par.type === 'Fragment' && varsList.find(v => v.id === "_fragment-" + par.id) === undefined) {
            varsList.push({
              id: "_fragment-" + par.id,
              label: "Parent fragment",
              children: await this.getStorageTree(par.id, 'fragment:')
            })
          }
        }
      }

      // Return vars list
      return varsList;
    },

    /**
     * Return options list
     */
    processStaticOptions(parent, sourceName) {
      return {
        id: "_" + parent.title + "-" + parent.id,
        label: parent.title,
        children: [{
          id: `${sourceName}:item`,
          label: "item",
          children: [
            {
              id: `${sourceName}:item.title`,
              label: "title",
            },
            {
              id: `${sourceName}:item.value`,
              label: "value",
            }
          ]
        }]
      }
    },

    /**
     * Return products list
     */
    processProductsList(parent, sourceName) {
      return {
        id: "_" + parent.title + "-" + parent.id,
        label: "Products list",
        children: [{
          id: `${sourceName}:item`,
          label: "item",
          children: [
            {
              id: `${sourceName}:item.title`,
              label: "Product title",
            },
            {
              id: `${sourceName}:item.id`,
              label: "Product id",
            },
            {
              id: `${sourceName}:item.priceTitle`,
              label: "Price title",
            },
            {
              id: `${sourceName}:item.priceValue`,
              label: "Price value",
            },
            {
              id: `${sourceName}:item.currency`,
              label: "Price currency",
            },
            {
              id: `${sourceName}:item.billingPeriodTitle`,
              label: "Billing period",
            },
            {
              id: `${sourceName}:item.trialPeriodTitle`,
              label: "Trial period",
            },
            {
              id: `${sourceName}:item.trialDescription`,
              label: "Trial description",
            },
            {
              id: `${sourceName}:item.subscriptionRules`,
              label: "Subscription rules",
            },
          ]
        }]
      }
    },

    /**
     * Return native ads
     */
    processNativeAds(parent, sourceName) {
      return {
        id: "_" + parent.title + "-" + parent.id,
        label: "Native ads",
        children: [
          {
            id: `${sourceName}:id`,
            label: "Native ad id",
          },
          {
            id: `${sourceName}:title`,
            label: "Native ad title",
          },
          {
            id: `${sourceName}:description`,
            label: "Native ad description",
          },
          {
            id: `${sourceName}:image`,
            label: "Native ad image",
          },
          {
            id: `${sourceName}:cta`,
            label: "Native ad cta",
          },
          {
            id: `${sourceName}:click_url`,
            label: "Native ad click url",
          },
        ]
      }
    },

    /**
     * Check if only var type
     * @param type
     * @return {*}
     */
    hasOnlyVarType(type) {
      const types = Array.isArray(this.varTypeFilter) ? this.varTypeFilter : [this.varTypeFilter]
      return types.includes(type)
    }
  },
  computed: {

    /**
     * Return var type options
     * @return {{label: string, value: string}}
     */
    varTypeOptions() {
      return [...this.globals.options.data_types, ...[{value: "options", label: "Options"}]];
    },

    /**
     * Get parent fragment id
     */
    parentFragmentId() {
      return this.canvas.getNodeParentsById(this.blockId)?.find(p => p.type === 'Fragment')?.id
    },

    /**
     * Return allowed value types
     * @return {*}
     */
    allowedValueTypes() {
      return this.globals.options.valueTypes.filter(v => !v.type || !this.valueType || v.type === this.valueType);
    },

    /**
     * Current property link
     */
    currentValue: {
      get: function () {
        return this.selectedValue || ""
      },
      set: async function (newVal) {
        this.$emit('changed', newVal)
        this.$emit('update:modelValue', newVal)
      }
    },

    /**
     * Return all storage nodes
     * @return {*}
     */
    varsTree() {
      // Load app storage nodes
      return this.wait("varsTree", async () => {

        // Result vars tree
        const vTree = [];

        // Get app vars
        vTree.push({
          id: "_app-storage",
          label: "App storage",
          children: await this.getStorageTree('app-storage', "app:")
        });

        // Diagram vars
        vTree.push({
          id: "_diagram-storage",
          label: "Diagram storage",
          children: this.parentDiagramId ? await this.getStorageTree('diagram-' + this.parentDiagramId, "diagram:") : false
        });

        // Repeater vars
        vTree.push(...await this.getParentVars(this.blockId))

        // Return vars list
        return vTree.filter(e => e.children.length > 0)
      }, []);
    },

    /**
     * Get value type
     */
    selectedValueType() {
      return this.selectedValue.valueType || 'none'
    },

    /**
     * Get var type
     */
    varType() {
      return this.selectedValue.type
    },

    /**
     * A computed property that gets and sets the 'isLocalizable' property of the selected value.
     * The 'isLocalizable' property determines whether the selected value is localizable or not.
     *
     * @property {Boolean} isLocalizable - The 'isLocalizable' property of the selected value.
     * @property {Function} get - The getter method for the 'isLocalizable' property. It checks if the 'isLocalizable' property exists in the 'expression' property of the selected value. If it does not exist, it checks if the 'isLocalizable' property exists in the selected value itself. If it still does not exist, it defaults to 'false'.
     * @property {Function} set - The setter method for the 'isLocalizable' property. It sets the 'isLocalizable' property in the 'expression' property of the selected value if it exists. If it does not exist, it sets the 'isLocalizable' property in the selected value itself.
     */
    isLocalizable: {
      get() {
        return this.selectedValue?.expression?.isLocalizable || this.selectedValue?.isLocalizable || false
      },
      set(value) {
        if (this.selectedValue?.expression) {
          this.selectedValue.expression.isLocalizable = value;
        } else {
          this.selectedValue.isLocalizable = value;
        }
      }
    },

    /**
     * A computed property that gets and sets the 'localeAlias' property of the selected value.
     * The 'localeAlias' property is used to identify the localization of the selected value.
     *
     * @property {String} localeAlias - The 'localeAlias' property of the selected value.
     * @property {Function} get - The getter method for the 'localeAlias' property. It checks if the 'localeAlias' property exists in the 'expression' property of the selected value. If it does not exist, it checks if the 'localeAlias' property exists in the selected value itself. If it still does not exist, it defaults to 'false'.
     * @property {Function} set - The setter method for the 'localeAlias' property. It sets the 'localeAlias' property in the 'expression' property of the selected value if it exists. If it does not exist, it sets the 'localeAlias' property in the selected value itself.
     */
    localeAlias: {
      get() {
        return this.selectedValue?.expression?.localeAlias || this.selectedValue?.localeAlias || null;
      },
      set(value) {
        if (this.selectedValue?.expression) {
          this.selectedValue.expression.localeAlias = value;
        } else {
          this.selectedValue.localeAlias = value;
        }
      }
    },
  },

  watch: {
    /**
     * Watcher for the `isLocalizable` property.
     * If `isLocalizable` is true and `localeAlias` does not exist in the `selectedValue` object,
     * it generates a new `localeAlias` using the `nanoid` function and assigns it to `selectedValue`.
     *
     * @name isLocalizable
     * @watch
     */
    isLocalizable() {
      if (!this.isLocalizable) {
        return;
      }

      if (this.isLocalizable && !this.localeAlias) {
        this.localeAlias = nanoid(10);
      }
    },
  },
}

</script>
