<template>

  <div>
  </div>

</template>

<script>

export default {
  components: {},
  props: ['block'],
  name: "ForEachPropsCmp",
  computed: {
  }
}

</script>
