<template>

  <ab-flow-base-cmp class="upload-editor-cmp" :block="block" :transparent="true" :class="classesString" :style="stylesString">
    <q-file clearable v-model="test" :hint="inputHint" :label="inputLabel" class="full-width no-pointer-events">
      <template v-slot:append>
        <q-btn icon="upload" round size="sm" outline/>
      </template>
    </q-file>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "FileUploaderEditorCmp",
  data: () => ({
    test: []
  }),
  computed: {

    /**
     * input label
     * @return {*|string}
     */
    inputLabel() {
      return this.block.properties?.label || undefined
    },

    /**
     * input hint
     * @return {*|string}
     */
    inputHint() {
      return this.block.properties?.hint || undefined
    },
  }
}

</script>

<style lang="scss">

.upload-editor-cmp {
  position: relative;
  .links-wrapper {
    right: 0;
    left: auto;
  }

  .q-field {
    .q-field__messages {
      color: var(--control-text-color);
    }

    .q-field__control {
      color: var(--control-focus-color);
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }
    }
  }
}


</style>
