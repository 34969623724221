import {AccessModel} from './AccessModel.js';

/**
 * Media gallery
 */

export class MediaGallery extends AccessModel {
    static entity = 'media_gallery'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        title: "string",
        source_url: "string",
        psd_url: "string",
        type: "string",
        app_id: "int",
        module_id: "int",
        unique_id: "string",
        temporary: "int",
        size: "int",
    }
}
