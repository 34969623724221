/**
 * App model
 */
import _ from "lodash";
import {nanoid} from "nanoid";
import {Localization} from './Localization.js';
import {LocalizationMessage} from './LocalizationMessage.js';
import {AccessModel} from './AccessModel.js';

export class StorageNode extends AccessModel {
    static entity = 'storage_nodes'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        parent_id: "int",
        title: "string",
        name: "string",
        type: "string",
        is_array: "int",
        update_state: "int",
        is_argument: "int",
        is_reference: "int",
        value: "json",
        app_id: "int",
        db_table: "string",
        module_id: "int",
        block_id: "string",
        unique_id: "string",
        is_test_value: "int",
        is_localizable: "int",
        locale_alias: "string",
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'app-storage': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => StorageNode.getList(app_id),
            }
        }
    }

    /**
     * Get list
     */
    static getList(app_id) {
        return StorageNode.query().where({app_id}).get()
    }

    /**
     * Duplicate storage node
     * @param {number} from_module_id
     * @param {number} to_app_id
     * @param {number} to_module_id
     * @param {number} block_id
     * @param {number} to_block_id
     * @return {Promise<void>}
     */
    static async duplicate(from_module_id, to_app_id, to_module_id, block_id, to_block_id) {

        // Nodes
        const nodes = await this.query().where({module_id: from_module_id, block_id}).get()

        // Save nodes with new block id
        for(const node of nodes) {
            let locale_alias = '';
            let is_localizable = 0;

            // Duplicate localization
            if (node.locale_alias && node.is_localizable) {
                const locale = await Localization.query().where({
                    module_id: from_module_id,
                    alias: node.locale_alias,
                }).first();

                // If locale exists
                if (locale) {
                    // Set localizable flag
                    is_localizable = 1;

                    // Duplicate locale
                    const newLocale = await Localization.remote().save({
                        ...locale,
                        id: null,
                        module_id: to_module_id,
                        alias: nanoid(10),
                    });

                    // Set new locale alias
                    locale_alias = newLocale.alias;

                    // Duplicate messages
                    const messages = await LocalizationMessage.query().where({
                        localization_id: locale.id,
                    }).get();

                    for (const message of messages) {
                        await LocalizationMessage.remote().save({
                            ...message,
                            localization_id: newLocale.id,
                            id: null,
                        });
                    }
                }
            }

            await StorageNode.remote().save({
                ...node,
                app_id: to_app_id,
                block_id: to_block_id,
                module_id: to_module_id,
                locale_alias,
                is_localizable,
                id: undefined,
            })
        }
    }

    /**
     * Get argument nodes from tree
     * @param module_id
     * @param block_id
     * @param filter
     * @return {Promise<void>}
     */
    static async getArguments(module_id, block_id, filter) {

        // Results list
        const resList = []

        // Nodes
        const nodes = await this.query().where({module_id, block_id}).get()

        // Store nodes recursively as tree with key, label, children
        const tree = (node, path) => {

            // Set value
            if(path && (node.is_argument || (filter && filter(node)) )) resList.push({
                title: node.title,
                name: path,
                nodeId: node.id,
                type: node.type,
                is_array: node.is_array,
                is_reference: node.is_reference,
            });

            // Get node children
            for(const ch of nodes.filter(n => n.parent_id === node.id)) {
                tree(ch, (path ? path + "." : "") + ch.name)
            }
        };

        // Return tree
        tree({id: 0}, "")

        // Return result data
        return resList;
    }

    /**
     * Get tree
     * @returns {Promise<void>}
     */
    static async getTree(module_id, block_id, convertResourceValue) {

        // Result data
        const resData = {};

        // Load nodes
        const nodes = await this.query().where({module_id, block_id}).get()

        // Store nodes recursively as tree with key, label, children
        const tree = (node, path) => {

            // Set value
            if(path) _.set(resData, path, convertResourceValue ? convertResourceValue(node) : node?.value);

            // Get node children
            for(const ch of nodes.filter(n => n.parent_id === node.id)) {
                tree(ch, (path ? path + "." : "") + ch.name)
            }
        };

        // Return tree
        tree({id: 0}, "")

        // Return result data
        return resData;
    }

    /**
     * Add child node
     * @param name
     * @param title
     * @param type
     * @param value
     * @return {Promise<void>}
     */
    async addChild(name, title, type, value) {
        return StorageNode.remote().save({
            parent_id: this.id,
            title: title,
            name,
            type,
            value,
            app_id: this.app_id,
            module_id: this.module_id,
            block_id: this.block_id,
            unique_id: nanoid(10)
        })
    }

    /**
     * Get parenst list
     * @return {Promise<void>}
     */
    async parents() {

        // Result list
        const resList = []

        // Get parent
        let parent = await StorageNode.find(this.parent_id);

        // Get parent list
        while(parent) {
            resList.push(parent)
            parent = await StorageNode.find(parent.parent_id)
        }

        // Return result
        return resList
    }
}
