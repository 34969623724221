<template>

  <div>

    <ui-prop-string name="title" title="Operation description" :block="block"/>

    <ui-prop-custom-list-props name="variables" title="Variables list" :block="block">
      <template v-slot:item="data">
        <value-selector :app-id="product_id" :module-id="module_id" :block-id="block?.id"  v-model="data.item.variable" value-type="setter" :block="block" :parent-diagram-id="diagram_id"  title="Variable to change"/>
        <q-select v-model="data.item.operation" label="Variable operation" :options="operations(data.item.variable)" map-options emit-value/>

        <template v-if="data.item?.variable?.valueType !== 'input' && ['set', '+', '/', '*', 'toggle'].includes(data.item.operation)">
          <value-selector  :block-id="block?.id" :app-id="product_id" :module-id="module_id" v-model="data.item.setValue" :only-var-type="data.item?.variable?.type" :parent-diagram-id="diagram_id" label="Value to set" />
        </template>
      </template>
    </ui-prop-custom-list-props>

  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import {componentsMixin} from "@/components/DiagramDesigner/Editor/components/componentsMixin";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import {StorageNode} from "../../../../../../../../../../common/db/StorageNode";
import UiPropCustomListProps from "@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  mixins: [componentsMixin, propertiesMixins],
  inject: {
    canvas: {
      default: false
    }
  },
  components: {ValueSelector, UiPropCustomListProps, UiPropString},
  props: ['block'],
  name: "StorageSetValuePropsCmp",

  created() {

    // Check for variables added
    if(!this.block.properties?.variables?.length) {

      // Init variables
      // eslint-disable-next-line
      if(!this.block.properties) this.block.properties = {}
      // eslint-disable-next-line
      this.block.properties.variables = []

      // Check if we have variable in old format
      const operation = this.block.properties?.operation
      const variable = this.block.properties?.variable
      const setValue = this.block.properties?.setValue

      // Check if block name is empty
      if(variable && operation) {
        // eslint-disable-next-line
        this.block.properties.variables.push({
          variable,
          operation,
          setValue
        });
      }
    }

  },

  methods: {

    /**
     * Variable operations
     * @return {*}
     */
    operations(variable) {
      return this.globals.options.variableOperations.filter(o => !o.types || o.types.includes(variable?.type) || (o.array && variable?.isArray) )
    },

  },

  computed: {

    variableParams() {
      return this.wait('variableParams', async () => {
        const nd = await StorageNode.find(this.block?.properties?.nodeId)
        console.log("nd", nd)
        return nd;
      }, {})
    },

    /**
     * Variable operation
     * @return {*}
     */
    operation() {
      return this.block?.properties?.operation || "";
    },

    /**
     * Variable type
     * @return {string | Type | Pair.Type | undefined}
     */
    variableType() {
      return this.block?.properties?.variable?.type || "";
    },

    /**
     * Variable type
     * @return {string | Type | Pair.Type | undefined}
     */
    variableIsArray() {
      return this.block?.properties?.variable?.isArray;
    },
  }
}

</script>
