<template>
  <ui-prop-value-selector :block="block" name="activeFrame" only-var-type="string" title="Active fragment" :parent-diagram-id="diagram_id"/>
  <ui-prop-string name="width" title="Width" :block="block"/>
  <ui-prop-string name="height" title="Height" :block="block"/>
  <ui-dynamic-props :default-value="{}" :block="block"/>
</template>

<script>


import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector, UiPropString, UiDynamicProps},
  props: ['block'],
  name: "TeleportPropsCmp",
  computed: {}
}

</script>
