export const treeHelper = {

    /**
     * Traverse tree with callback
     * @param tree
     * @param cb
     * @param max_level
     */
    traverseTree: (tree, cb, max_level) => {

        // Current level
        let level = 0

        // Searching for node in tree
        const goDeep = (node, level, parents) => {

            // Stop on limited depth
            if(max_level && level > max_level) return

            if (cb(node, parents)) return node
            if (node.children) for (const ch of node.children) {
                const rs = goDeep(ch, level + 1, [...parents, ...[node]])
                if (rs) return rs;
            }
        }

        // Start searching
        return goDeep(tree, level, [])
    },
}
