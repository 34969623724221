<template>
  <q-card>
    <q-card-section class="q-pa-sm">
      <div class="flex no-wrap items-center">
        <div class="text-subtitle2" v-text="details.type" />

        <q-separator spaced vertical/>

        <div>
          <health-check-status :status="details.status" />

          <div class="text-caption" v-text="details.message" />
        </div>
      </div>
    </q-card-section>

    <template v-if="payloadJson">
      <q-separator spaced />

      <q-card-section class="q-pa-sm">
        <div>
          <pre class="text-caption q-ma-none" v-text="payloadJson" />
        </div>
      </q-card-section>
    </template>
  </q-card>
</template>

<script>
import HealthCheckStatus from '@/pages/workspace/health-check/HealthCheckStatus.vue';

export default {
  name: 'CustomCheckingCard',
  components: {HealthCheckStatus},

  props: {
    details: {
      required: true,
      type: Object,
    },
  },

  computed: {
    // If the details object has a payload property, return it, otherwise return null
    payload() {
      return this.details?.payload || null;
    },
    // If the payload property is not null, return the JSON stringified version of the payload, otherwise return null
    payloadJson() {
      return this.payload() ? JSON.stringify(this.payload, null, 2) : null;
    },
  },
}
</script>
