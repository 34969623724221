<template>
  <div class="lottie-animation-wrapper" :style="{width, height}">
    <LottiePlayer
      class="lottie-animation-container"
      :animation-url="animationLink"
      :animation-data="animationData"
      :loop="loop"
    />
    <div class="lottie-animation-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import LottiePlayer from './LottiePlayer.vue';

export default {
  name: "LottieAnimation",
  components: {
    LottiePlayer,
  },
  props: {
    animationData: {
      type: Object,
      default: () => {}
    },
    animationLink: {
      type: String,
      default: ""
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "100%"
    },
    speed: {
      type: Number,
      default: 1
    },
    direction: {
      type: String,
      default: "forward"
    },
    loop: {
      type: [Number, Boolean],
      default: true
    },
    autoPlay: {
      type: Boolean,
      default: true
    },
    delay: {
      type: Number,
      default: 0
    },
    pauseAnimation: {
      type: Boolean,
      default: false
    },
    pauseOnHover: {
      type: Boolean,
      default: false
    },
    playOnHover: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: "transparent"
    },
    renderer: {
      type: String,
      default: "svg"
    },
    rendererSettings: {
      type: Object,
      default: () => {}
    }
  },
}
</script>

<style lang="scss">
  .lottie-animation-wrapper  {
    position: relative;

    .lottie-animation-container {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    .lottie-animation-content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
</style>
