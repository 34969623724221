/**
 * DbModule model
 */
import {AccessModel} from './AccessModel.js';

export class DbModule extends AccessModel {
    static entity = 'app_databases'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        module_id: "int",
        storage_module_id: "int",
        name: "string",
        description: "string",
        app_id: "int",
        unique_id: "string",
        is_auth_db: "int"
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'app-dbs': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => this.getList(app_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(app_id) {
        return this.query().where({app_id}).get()
    }
}
