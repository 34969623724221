<template>
  <ab-flow-base-cmp :block="block" class="image-editor-cmp" :class="classesString" :style="imageStyles">
    <div class="links-wrapper">
      <ab-flow-link-connector v-for="(e, k) of block.properties?.events || []" class="q-mr-xs" :block="block" type="output" :event="e.name" :key="k"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {pathHelper} from "@/utils/pathHelper";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  inject: {
    designer: {
      default: false
    },
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "ImageEditorCmp",
  data: () => ({
    upKey: 0,
    someVar: 1
  }),

  /**
   * Created
   */
  created() {
    this.designer.addListener?.(this.block.id, (data) => {
      this.eventListener(data)
    });
  },

  /**
   * Before unmount
   */
  beforeUnmount() {
    this.designer.removeListener?.(this.block.id);
  },

  methods: {
    /**
     * Event listener
     * @param data
     */
    eventListener() {
      // Update image
      this.upKey++
    }
  },

  computed: {

    /**
     * Get image styles
     */
    imageStyles() {
      const styles = {
        background: `url(${this.image_source}) no-repeat`
      }

      // Apply settings
      styles['background-size'] = this.block.properties?.fitting || 'cover'
      styles['background-position'] = this.block.properties?.position || 'center'

      // Return styles list
      return Object.assign(styles, this.stylesString);
    },

    /**
     * image source
     * @return {boolean|*|boolean}
     */
    image_source() {
      // Get value
      const value = this.getValue(this.block?.properties?.image);

      // Get path
      const path = pathHelper.assetPath(value?.source_url || value);

      // Modify path
      const url = path ? `${path}${path.includes('?') ? '&' : '?'}up=${this.upKey}` : undefined;

      // Return url
      return url ? url : require("@/assets/plugs/default-image.png");

    },
  }
}

</script>

<style lang="scss">

.image-editor-cmp {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

</style>
