/**
 * App model
 */
import {AccessModel} from './AccessModel.js';

export class CodeFunction extends AccessModel {
    static entity = 'code_functions'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        package: "string",
        name: "string",
        description: "string",
        source: "string",
        app_id: "int",
        module_id: "int",
        unique_id: "string",
        type: "string",
        diagram_id: "int",
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'app-functions': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => this.getList(app_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(app_id) {
        return this.query().where({app_id}).get()
    }
}
