<template>

  <q-tabs v-model="tab">
    <q-tab name="props" label="Properties"/>
    <q-tab name="state" label="State"/>
  </q-tabs>

  <q-tab-panels v-model="tab">
    <q-tab-panel name="props">
      <ui-prop-string name="title" title="Fragment title" :block="block"/>
      <ui-prop-select
        title="Fragment type"
        name="fragmentType"
        :block="block"
        :options="globals.options.fragmentTypes"
        default-value="page"
      />

      <!--template v-if="['page', 'sidebar'].includes(block.properties?.fragmentType)">
        <ui-prop-select title="Fragment layout" name="fragmentLayout" :block="block"
                        :options="globals.options.fragmentLayouts"/>
      </template!-->

      <ui-prop-checkbox
        v-if="['page', 'sidebar'].includes(fragmentType)"
        title="Disable safe area insets"
        name="disableSafeAreaInsets"
        :block="block" :true-value="1"
        :false-value="0"
        :default-value="0"
      />

      <ui-modal-props v-if="fragmentType === 'modal'" :block="block" />

      <ui-prop-string name="width" title="Fragment width" :block="block"/>
      <ui-prop-string name="height" title="Fragment height" :block="block"/>

      <ui-prop-arguments :block="block" name="arguments" title="Fragment arguments" :target-block-id="block.id" :diagram-only="true"/>

    </q-tab-panel>

    <q-tab-panel name="state">
      <storage-tree :app-id="product_id" :module-id="module_id" :block_id="block.id" :horizontal="true"/>
    </q-tab-panel>

    <!--q-tab-panel name="description">
      <ui-prop-string :multiple="true" name="description" title="Fragment Description" :block="block"/>
      <ai-text-generator v-model="mutate(block).properties.description"/>
    </q-tab-panel!-->
  </q-tab-panels>

  <!--ui-data-props label="Fragment arguments" name="arguments" :block="block" class="q-mt-sm" :default-schema="[{name: 'name', type: 'string' }, {name: 'type', type: 'select', options: globals.options.data_types }, {name: 'defaultValue', type: 'string' }]"/!-->

  <ui-dynamic-props :default-value="{}" :block="block"/>

</template>

<script>
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import StorageTree from "@/pages/workspace/storage/StorageTree.vue";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiModalProps from '@/components/DiagramDesigner/Editor/properties/UiModalProps.vue';

export default {

  inject: {
    product_id: {
      default: 0
    },
    module_id: {}
  },
  mixins: [propertiesMixins],
  components: {
    UiModalProps, UiDynamicProps, UiPropCheckbox, UiPropArguments, StorageTree, UiPropString, UiPropSelect},
  props: ['block'],
  name: "FragmentPropsCmp",
  data: () => ({
    tab: "props",
    fragmentSource: '',
    generationInProgress: false,
    prompt: 'Add button with text "hello"',
  }),
  created() {
    this.fragmentSource = JSON.stringify(this.block?.children || [])
  },
  methods: {

    /**
     * Call controller method that will generate json code according to prompt
     * and return new json schema to be placed as fragment source
     */
    async generateCode() {

      // Set loading
      this.generationInProgress = true

      // Init code
      let code = ""

      try {
        // Call controller method
        code = await this.app.client.call('ai-designer', 'generateDesign', this.fragmentSource, this.prompt)
      } catch (ex) {
        console.error(ex)
      }

      // Reset loading
      this.generationInProgress = false

      // Reset prompt
      this.prompt = ''

      // Set new source
      if(code) {
        this.fragmentSource = JSON.stringify(code)
        this.saveSource()
      }
    },

    /**
     * Save source
     */
    saveSource() {
      try {
        const cnt = JSON.parse(this.fragmentSource)
        // eslint-disable-next-line vue/no-mutating-props
        this.block.children = Array.isArray(cnt) ? cnt : [cnt]
      } catch (e) {
        console.error(e)
      }
    }
  },
  computed: {

    /**
     * Check if user can change block size
     * @return {boolean}
     */
    ifSizable() {
      return ['page', 'component'].includes(this.fragmentType)
    },

    /**
     * This computed property is used to determine the type of the fragment.
     * The type of the fragment is retrieved from the `fragmentType` property of the block.
     * If the `fragmentType` property is not set, the default value 'page' is returned.
     *
     * @return {string} - Returns the type of the fragment.
     */
    fragmentType() {
      return this.block?.properties?.fragmentType || 'page';
    },
  }
}

</script>
