<template>

  <div>
    <ui-prop-dynamic-string :block="block" title="Message" name="message"/>

    <ui-prop-custom-list-props name="options" title="Message options" :block="block">
      <template v-slot:item="data">
        <q-select
            v-model="data.item.type"
            :options="parameterTypes"
            map-options
            emit-value
            label="Option type"
        />

        <template v-if="data.item.type === 'answer'">
          <q-input v-model="data.item.text" label="Answer"  />
          <q-input v-model="data.item.event" label="Event"  />
        </template>
        <template v-if="data.item.type === 'ui-event'">
          <q-input v-model="data.item.text" label="Answer"  />
          <q-input v-model="data.item.event" label="Event"  />
        </template>
        <template v-else-if="data.item.type === 'url'">
          <q-input v-model="data.item.text" label="Button text"  />
          <q-input v-model="data.item.address" label="URL"  />
          <q-checkbox v-model="data.item.asWebapp" label="Open as webapp" :false-value="0" :true-value="1" />
        </template>

      </template>
    </ui-prop-custom-list-props>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropCustomListProps from '@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropCustomListProps, UiPropDynamicString},
  props: ['block'],
  name: "ChatBotSendMessagePropsCmp",

  data() {
    return {
      parameterTypes: [
        {value: "answer", label: "Answer"},
        {value: "ui-event", label: "UI Event"},
        {value: "url", label: "URL"},
      ],
    }
  },
}

</script>
